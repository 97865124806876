import React from 'react';
import { ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { phoneNumberPrefixOptions } from '@helpers';
import { Card, Form, Input, Select } from 'antd';

const { Option } = Select;

interface Props {
  isCreate?: boolean;
}

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

const eventOptions = ['Internal', 'External'].map((item) => {
  return {
    label: item,
    value: item,
  };
});

const superAdminId = '982f9a63-cf46-4d0c-9a96-01fa33dee7a1';

export function UserForm(props: Props) {
  const provider = usePageProvider();
  const isDisableName = provider?.isUpdate && provider?.detailData?.user_exist_id;
  const isDisableEmail = provider?.isUpdate && provider?.detailData?.user_exist_id;

  const account = authHelper.getAccount();
  const isSuperAdmin = account?.user?.user_category?.uuid === superAdminId;
  const gridColumn = { xxl: 4, xl: 4, lg: 8, md: 8, sm: 12, xs: 24 };
  return (
    <React.Fragment>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue();
          return (
            <Card>
              <FormBuilder
                columns={[
                  {
                    title: 'INDIVIDUAL INFORMATION',
                    fields: [
                      {
                        label: 'Name',
                        name: 'name',
                        fieldType: 'inputText',
                        rules: [{ required: true, message: 'Name is required.' }],
                        inputTextProps: {
                          disabled: isDisableName,
                          allowClear: true,
                        },
                        gridColumn,
                      },
                      {
                        label: 'User Category',
                        name: 'user_category',
                        fieldType: 'selectPaginate',
                        rules: [{ required: true, message: 'User category is required.' }],
                        selectPaginateProps: {
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          dataSourceUrl: ApiUrlData.user_category,
                          baseDataSourceUrl: process.env.REACT_APP_ACCESS_CONTROL_URL,
                          transformOptions(options) {
                            return isSuperAdmin
                              ? [
                                  {
                                    code: 'ADM',
                                    name: 'superadmin',
                                    uuid: '982f9a63-cf46-4d0c-9a96-01fa33dee7a1',
                                    id: '982f9a63-cf46-4d0c-9a96-01fa33dee7a1',
                                  },
                                  ...options,
                                ]
                              : options;
                          },
                          customLabel(row) {
                            return `${row?.name} (${row?.code})`;
                          },
                          keySearch: 'search',
                          customFilterRequest() {
                            return {
                              statuses: ['active'],
                            };
                          },
                        },
                        gridColumn,
                      },
                      {
                        label: 'Event',
                        name: 'event_categories',
                        gridColumn,
                        fieldType: 'selectPaginate',
                        rules: [{ required: true, message: 'Event is required.' }],
                        selectPaginateProps: {
                          // isDisabled: !isSuperAdmin,
                          isMulti: true,
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          dataSourceUrl: ApiUrlData.event_categories,
                          useOptionAllScheme: true,
                          customFilterRequest() {
                            return {
                              statuses: ['active'],
                            };
                          },
                          customLabel(row) {
                            return row?.name;
                          },
                          keySearch: 'search',
                        },
                      },
                      {
                        label: 'Email',
                        name: 'email',
                        fieldType: 'inputText',
                        rules: [{ type: 'email' }, { required: true, message: 'Email is required.' }],
                        inputTextProps: {
                          disabled: isDisableEmail,
                          allowClear: true,
                        },
                        gridColumn,
                      },
                      {
                        gridColumn,

                        // label: 'Password',
                        // name: 'password',
                        // fieldType: 'inputPassword',
                        // rules: [
                        //   { required: true, message: '' },
                        //   ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //       const action = getFieldValue('form_action');
                        //       if (action === 'create' || action === 'duplicate') {
                        //         if (!value) return Promise.reject(new Error('Password is required!'));
                        //         else return Promise.resolve();
                        //       } else return Promise.resolve();
                        //     },
                        //   }),
                        // ],

                        // inputPasswordProps: {
                        //   allowClear: true,
                        //   autoComplete: 'new-password',
                        // },

                        renderField({ getFieldValue }) {
                          const action = getFieldValue('form_action');

                          return (
                            <Form.Item
                              label="Password"
                              name="password"
                              rules={[
                                { required: action === 'create' || action === 'duplicate', message: '' },
                                () => ({
                                  validator(_, value) {
                                    if (action === 'create' || action === 'duplicate') {
                                      if (!value) return Promise.reject(new Error('Password is required!'));
                                      else return Promise.resolve();
                                    } else return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input.Password allowClear autoComplete="new-password" placeholder="Input" />
                            </Form.Item>
                          );
                        },
                      },
                      {
                        gridColumn,

                        // label: 'Confirm Password',
                        // name: 'retype_password',
                        // fieldType: 'inputPassword',
                        // dependencies: ['password'],
                        // hasFeedback: true,
                        // rules: [
                        //   // { required: true },
                        //   ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //       const password = getFieldValue('password');
                        //       if (!password) return Promise.resolve();
                        //       else if (password === value) return Promise.resolve();
                        //       else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                        //       else return Promise.reject(new Error("Passwords doesn't match!"));
                        //     },
                        //   }),
                        // ],
                        // inputPasswordProps: {
                        //   allowClear: true,
                        //   autoComplete: 'new-password',
                        // },

                        renderField({ getFieldValue }) {
                          const password = getFieldValue('password');
                          return (
                            <Form.Item
                              label="Confirm Password"
                              name="retype_password"
                              rules={[
                                { required: !!password, message: '' },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    const password = getFieldValue('password');
                                    if (!password) return Promise.resolve();
                                    else if (password === value) return Promise.resolve();
                                    else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                                    else return Promise.reject(new Error("Passwords doesn't match!"));
                                  },
                                }),
                              ]}
                            >
                              <Input.Password allowClear autoComplete="new-password" placeholder="Input" />
                            </Form.Item>
                          );
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
