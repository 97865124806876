import { ApiUrlData } from '@base-configs';
import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { booleanSelectOptions } from '../select-options';

export const Filter: FormColumnProps[] = [
  {
    fields: [
      {
        label: 'Date',
        name: ['date'],
        gridColumn: { xl: 8 },
        fieldType: 'dateRangePicker',
      },
      {
        label: 'Time',
        name: ['time'],
        gridColumn: { xl: 8 },
        fieldType: 'timeRangePicker',
      },
      {
        label: 'No of Session',
        name: 'no_of_session',
        gridColumn: { xl: 8 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'No of Session',
        },
      },
      {
        label: 'Event Category',
        name: ['event_category'],
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          dataSourceUrl: ApiUrlData.eventCategory,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel(row) {
            return row?.name;
          },
        },
      },
      {
        label: 'Price Type',
        name: 'price_type',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Price Type',
          dataSourceUrl: ApiUrlData.enum_price_configuration,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel: (row) => row,
        },
      },
      {
        label: 'Price From',
        name: 'price_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Price',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Price To',
        name: 'price_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Price',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Registrant From',
        name: 'registrant_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Registrant',
        },
      },
      {
        label: 'Registrant To',
        name: 'registrant_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Registrant',
        },
      },
      {
        label: 'Participant From',
        name: 'total_participant_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Participant',
        },
      },
      {
        label: 'Participant To',
        name: 'total_participant_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Participant',
        },
      },
      {
        label: 'Check In Participant From',
        name: 'total_check_in_participant_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Check in Participant',
        },
      },
      {
        label: 'Check In Participant To',
        name: 'total_check_in_participant_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Participant',
        },
      },
      {
        label: 'Not Come Participant From',
        name: 'total_not_come_participant_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Not Come Participant',
        },
      },
      {
        label: 'Not Come Participant To',
        name: 'total_not_come_participant_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Not Come Participant',
        },
      },
      {
        label: 'Invoice From',
        name: 'total_invoice_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Invoice',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Invoice To',
        name: 'total_invoice_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Invoice',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Payment From',
        name: 'total_payment_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Payment',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Payment To',
        name: 'total_payment_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Payment',
          formatter(value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
      {
        label: 'Event Type',
        name: 'event_type',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Event Type',
          dataSourceUrl: ApiUrlData.enum_event_type,
          classNamePrefix: 'react-select-custom-prefix-radius',
          customLabel: (row) => row,
        },
      },
      {
        label: 'Venue',
        name: 'venue_ids',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Price Type',
          dataSourceUrl: ApiUrlData.venues,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel: (row) => row?.name,
        },
      },
      {
        label: 'Agenda',
        name: 'has_agenda',
        gridColumn: { xl: 12 },
        fieldType: 'select',
        selectProps: {
          placeholder: 'has Agenda',
          options: booleanSelectOptions,
          classNamePrefix: 'react-select-custom-prefix-radius',
        },
      },
      {
        label: 'Public Event',
        name: 'public_event',
        gridColumn: { xl: 12 },
        fieldType: 'select',
        selectProps: {
          placeholder: 'Is Public Event',
          options: booleanSelectOptions,
          classNamePrefix: 'react-select-custom-prefix-radius',
        },
      },
      {
        label: 'Age From',
        name: 'age_from',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Age',
        },
      },
      {
        label: 'Age To',
        name: 'age_to',
        gridColumn: { xl: 12 },
        fieldType: 'inputNumber',
        inputNumberProps: {
          placeholder: 'Input Age',
        },
      },
      {
        label: 'Gender Type',
        name: 'gender_type',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Gender',
          dataSourceUrl: ApiUrlData.enum_gender,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel: (row) => row,
        },
      },
      {
        label: 'Speakers',
        name: 'sepaker_ids',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Speakers',
          dataSourceUrl: ApiUrlData.speaker,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel: (row) => row?.name,
        },
      },
      {
        label: 'QR Code',
        name: 'use_qr',
        gridColumn: { xl: 12 },
        fieldType: 'select',
        selectProps: {
          placeholder: 'Use QR Code',
          options: booleanSelectOptions,
          classNamePrefix: 'react-select-custom-prefix-radius',
        },
      },
      {
        label: 'Attendances',
        name: 'attendances',
        gridColumn: { xl: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          placeholder: 'Select Attendance',
          dataSourceUrl: ApiUrlData.enum_attendance_type,
          classNamePrefix: 'react-select-custom-prefix-radius',
          isMulti: true,
          customLabel: (row) => row,
        },
      },
    ],
  },
];
