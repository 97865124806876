import { EventEntity } from '../entities';
import { BaseEntity, BaseTransformer, makeArrayIds } from '@eigen3m/react-base-frontend';
import {
  transformerCreateAttendanceOfficer,
  transformerCreateInformation,
  transformerCreateParticipants,
  transformerCreatePaymentPricings,
  transformerCreateRegistration,
  transformerCreateSchedule,
} from './transformer.post';
import {
  transformerGetDataAttendanceOfficer,
  transformerGetDataInformation,
  transformerGetDataParticipant,
  transformerGetDataPaymentAndPricing,
  transformerGetDataSchedules,
} from './transformer.get';
import moment from 'moment';
import { omit } from 'lodash';

export class EventTransformer<E extends BaseEntity = EventEntity> extends BaseTransformer<E> {
  transformerGetData(payload: E): E {
    return {
      ...payload,
      information: transformerGetDataInformation(payload?.information),
      // participants: transformerGetDataParticipant(payload?.participants),
      // schedules: transformerGetDataSchedules(payload?.schedules),
      // registrations: transformerCreateParticipants(payload?.participants),
      // payment_pricings: transformerGetDataPaymentAndPricing(payload?.payment_pricings),
      // attendance_officers: transformerGetDataAttendanceOfficer(payload?.attendance_officers),
    };
  }

  transformerCreate(payload: any): any {
    const cleanVal = {
      company: payload?.company,
      document_id: payload?.information?.document_id,
      information: transformerCreateInformation(payload?.information),
      schedules: transformerCreateSchedule(payload?.schedules),
      participants: transformerCreateParticipants(payload?.participants),
      payment_pricings: transformerCreatePaymentPricings(
        payload?.payment_pricings,
        payload?.registrations?.registration_config,
        payload?.information?.event_type,
      ),
      attendance_officers: transformerCreateAttendanceOfficer(payload?.attendance_officers),
      registrations: transformerCreateRegistration(payload?.registrations),
    };
    return cleanVal;
  }

  transformerUpdate(payload: E): E {
    const cleanVal = this.transformerCreate(payload);

    //Remove id from registration details
    const registrationDetails = cleanVal?.registrations?.registration_details ?? [];
    const newRegistrationDetails = registrationDetails?.map((item) => {
      const sessions = item?.sessions ?? [];
      const newSessions = sessions?.map((itemSession) => {
        const agendas = itemSession?.agendas ?? [];
        const newAgendas = agendas?.map((itemAgenda) => {
          const workshops = itemAgenda?.workshops ?? [];
          const newWorkshops = workshops?.map((itemWorkshop) => {
            return {
              ...omit(itemWorkshop, ['id']),
            };
          });
          return {
            ...omit(itemAgenda, ['id']),
            workshops: newWorkshops,
          };
        });
        return {
          ...omit(itemSession, ['id']),
          agendas: newAgendas,
        };
      });
      return {
        ...omit(item, ['id']),
        // registration_id: payload?.registrations?.id,
        sessions: newSessions,
      };
    });
    return {
      ...cleanVal,
      information: {
        ...cleanVal?.information,
        department_class_id: payload?.information?.department_class_id,
      },
      id: cleanVal?.id,
      registrations: {
        ...cleanVal?.registrations,
        registration_information: {
          ...omit(cleanVal?.registrations?.registration_information, ['id']),
        },
        registration_details: newRegistrationDetails,
      },
    };
  }

  transformerDuplicate(payload: E): any {
    const newPayload = this.transformerCreate(payload);

    //Remove id from days
    const days = newPayload?.schedules?.days ?? [];
    const newDays = days?.map((item) => {
      const sessions = item?.sessions ?? [];
      const newSessions = sessions?.map((itemSession) => {
        const agendas = itemSession?.agendas ?? [];
        const newAgendas = agendas?.map((itemAgenda) => {
          const workshops = itemAgenda?.workshops ?? [];
          const newWorkshops = workshops?.map((itemWorkshop) => {
            return {
              ...omit(itemWorkshop, ['id']),
            };
          });
          return {
            ...omit(itemAgenda, ['id']),
            workshops: newWorkshops,
          };
        });
        return {
          ...omit(itemSession, ['id']),
          agendas: newAgendas,
        };
      });
      return {
        ...omit(item, ['id']),
        sessions: newSessions,
      };
    });

    //Remove id from registration details
    const registrationDetails = newPayload?.registrations?.registration_details ?? [];
    const newRegistrationDetails = registrationDetails?.map((item) => {
      const sessions = item?.sessions ?? [];
      const newSessions = sessions?.map((itemSession) => {
        const agendas = itemSession?.agendas ?? [];
        const newAgendas = agendas?.map((itemAgenda) => {
          const workshops = itemAgenda?.workshops ?? [];
          const newWorkshops = workshops?.map((itemWorkshop) => {
            return {
              ...omit(itemWorkshop, ['id']),
            };
          });
          return {
            ...omit(itemAgenda, ['id']),
            workshops: newWorkshops,
          };
        });
        return {
          ...omit(itemSession, ['id']),
          agendas: newAgendas,
        };
      });
      return {
        ...omit(item, ['id']),
        sessions: newSessions,
      };
    });

    //Payment and Pricing
    const paymentMainPricings = newPayload?.payment_pricings?.payment_pricing_datas ?? [];
    const paymentSubPricings = newPayload?.payment_pricings?.payment_pricing_subs ?? [];

    const newPaymentMainPricings = paymentMainPricings?.map((item) => {
      return {
        ...omit(item, ['id']),
      };
    });

    const newPaymentSubPricings = paymentSubPricings?.map((item) => {
      return {
        ...omit(item, ['id']),
      };
    });

    const officerDatas = newPayload?.attendance_officers?.officer_datas ?? [];
    const newOfficerDatas = officerDatas?.map((item) => {
      return { ...omit(item, ['id']) };
    });
    const cleanVal = {
      ...omit(newPayload, ['id', 'event_id']),
      information: { ...omit(newPayload?.information, ['id', 'event_id', 'department_class_id']) },
      participants: { ...omit(newPayload?.participants, ['id']) },
      schedules: { ...omit(newPayload?.schedules, ['id']), days: newDays },
      registrations: {
        ...omit(newPayload?.registrations, ['id', 'event_id']),
        registration_information: {
          ...omit(newPayload?.registrations?.registration_information, ['id']),
        },
        registration_details: newRegistrationDetails,
      },
      payment_pricings: {
        ...omit(newPayload?.payment_pricings, ['id']),
        payment_pricing_datas: newPaymentMainPricings,
        payment_pricing_subs: newPaymentSubPricings,
      },
      attendance_officers: { ...omit(newPayload?.attendance_officers, ['id']), officer_datas: newOfficerDatas },
    };

    return cleanVal;
  }
  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    return {
      ...payload,
      category_ids: makeArrayIds({ data: payload?.event_category }),
      event_category: undefined,
      venue_ids: makeArrayIds({ data: payload?.venue }),
      venue: undefined,
      sepaker_ids: makeArrayIds({ data: payload?.speakers }),
      speakers: undefined,
      date_from: payload?.date?.[0] ? moment(payload?.date?.[0]).format('YYYY-MM-DD') : null,
      date_to: payload?.date?.[1] ? moment(payload?.date?.[1]).format('YYYY-MM-DD') : null,
      date: undefined,
      time_from: payload?.time?.[0] ? moment(payload?.date?.[1]).format('HH:mm') : null,
      time_to: payload?.time?.[1] ? moment(payload?.date?.[1]).format('HH:mm') : null,
      time: undefined,
    };
  }
}
