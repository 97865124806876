import { useParams } from 'react-router-dom';
import { WebUrlData } from '@base-configs';
import { RouteEntity } from '@eigen3m/react-base-frontend';

//configuration
import BankAccounts from '@pages/configuration/bank-accounts/presentation/factory';
import EmailTemplate from '@pages/configuration/email-template/presentation/factory';
import EmailSetting from '@pages/configuration/email-settings/presentation/factories';
import UserCategories from '@pages/configuration/user-categories/presentation/factory';
import Users from '@pages/configuration/users/presentation/factories';

//event
import Speaker from '@pages/event/speaker/presentation/factories';
import Venue from '@pages/event/venue/presentation/factories';
import Event from '@pages/event/event-data/presentation/factories';
import EventCategory from '@pages/event/event-category/presentation/factories';
import EventActivity from '@pages/event/event-activity/presentation/factories';
import Report from '@pages/report/presentation/factories';

import PaymentConfirmation from '@pages/event/payment-confirmation/presentation/factory';

export const DefaultPage = (): JSX.Element => {
  const params = useParams();
  return <div>You are in default page {params.action}</div>;
};

export const DefaultPath = WebUrlData.bank_accounts;
export const AuthRoutes: RouteEntity[] = [
  //configuration
  {
    label: 'Bank & Accounts',
    path: WebUrlData.bank_accounts,
    element: BankAccounts,
  },
  {
    label: 'Email Template',
    path: WebUrlData.email_template,
    element: EmailTemplate,
  },
  {
    label: 'Email Setting',
    path: WebUrlData.email_setting,
    element: EmailSetting,
  },
  {
    label: 'User Categories',
    path: WebUrlData.user_categories,
    element: UserCategories,
  },

  {
    label: 'User',
    path: WebUrlData.users,
    element: Users,
  },

  //event

  {
    label: 'Speaker',
    path: WebUrlData.speaker,
    element: Speaker,
  },
  {
    label: 'Venue',
    path: WebUrlData.venue,
    element: Venue,
  },
  {
    label: 'Event',
    path: WebUrlData.event_data,
    element: Event,
  },
  {
    label: 'Event Category',
    path: WebUrlData.even_category,
    element: EventCategory,
  },
  {
    label: 'Event Activity',
    path: WebUrlData.event_activity,
    element: EventActivity,
  },
  {
    label: 'Report',
    path: WebUrlData.report,
    element: Report,
  },
];
