import React, { useEffect, useState } from 'react';
import { DataTable } from '@components/table';
import { columnsDayRegistration, columnsSessionRegistration, columnsWorkshopRegistration } from '../../../../helpers';
import { DrawerScheduleForm } from './drawer-schedule.form';
import './style.less';
import { filterColumnsByEventType } from './helpers';

interface Props {
  onChange?: (value: any) => any;
  value?: any;
  eventType?: string;
  registConfig?: string;
}
export function ScheduleListForm(props: Props) {
  const { value, onChange, eventType, registConfig } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('day');
  const [actionDrawer, setActionDrawer] = useState('create');
  const [dataDrawer, setDataDrawer] = useState(null);
  const [dataSchedule, setDataSchedule] = useState([]);

  useEffect(() => {
    let newValue = value ?? [];
    if (!!newValue?.length) {
      newValue = newValue?.map((item) => {
        const newItem = {
          key: item?.key ?? item?.id ?? item.uuid,
          ...item,
        };
        return newItem;
      });
    }

    setDataSchedule(newValue);
  }, [value]);

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const onValuesChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const onChangeDrawer = (action: string, type: string, payload: any) => {
    let newData = null;
    const newPayload = {
      key: payload?.id ?? payload?.key,
      id: payload?.id,
      no: payload?.no,
      name: payload?.name,
      date: payload?.date,
      time: payload?.time,
      speakers: payload?.speakers,
      schedule: payload?.schedule,
      registration_open: payload?.registration_open,
      registration_close: payload?.registration_close,
      event_link: payload?.event_link,
      event_code: payload?.event_code,
      event_password: payload?.event_password,
      max_registrants: payload?.max_registrants,
    };
    const schedule = payload?.schedule;
    const dayKey = schedule?.day_key;
    const sessionKey = schedule?.session_key;
    const agendaKey = schedule?.agenda_key;
    const workshopKey = schedule?.workshop_key;
    if (type === 'day') {
      if (action === 'create') {
        const maxNumber =
          !!dataSchedule && !!dataSchedule?.length ? Math.max(...dataSchedule?.map((item) => item?.no)) : 0;
        const number = maxNumber + 1;
        newData = [
          ...dataSchedule,
          {
            ...newPayload,
            no: number,
          },
        ];
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key !== newPayload.key) return item;
          return {
            ...newPayload,
            sessions: item?.sessions ?? [],
          };
        });
      }
    } else if (type === 'session') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const maxNumber = !!sessions && !!sessions?.length ? Math.max(...sessions?.map((item) => item?.no)) : 0;
            const number = maxNumber + 1;
            return {
              ...item,
              sessions: [...sessions, { ...newPayload, no: number }],
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === newPayload?.key)
                return {
                  ...itemSession,
                  ...newPayload,
                };
              else return itemSession;
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    } else if (type === 'agenda') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              const agendas = itemSession?.agendas ?? [];
              const maxNumber = !!agendas && !!agendas?.length ? Math.max(...agendas?.map((item) => item?.no)) : 0;
              const number = maxNumber + 1;
              if (itemSession?.key === sessionKey) {
                return {
                  ...itemSession,
                  agendas: [...agendas, { ...newPayload, no: number }],
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const agendas = itemSession?.agendas ?? [];
                const newAgendas = agendas?.map((itemAgenda) => {
                  if (itemAgenda?.key === newPayload?.key) {
                    return {
                      ...itemAgenda,
                      ...newPayload,
                    };
                  } else {
                    return itemAgenda;
                  }
                });
                return {
                  ...itemSession,
                  agendas: newAgendas,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    } else if (type === 'workshop') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const agendas = itemSession?.agendas ?? [];
                const newAgendas = agendas?.map((itemAgenda) => {
                  if (itemAgenda?.key === agendaKey) {
                    const workshops = itemAgenda?.workshops ?? [];
                    const maxNumber =
                      !!workshops && !!workshops?.length ? Math.max(...workshops?.map((item) => item?.no)) : 0;
                    const number = maxNumber + 1;
                    return {
                      ...itemAgenda,
                      workshops: [...workshops, { ...newPayload, no: number }],
                    };
                  } else {
                    return itemAgenda;
                  }
                });
                return {
                  ...itemSession,
                  agendas: newAgendas,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        const schedule = payload?.schedule;
        const dayKey = schedule?.day_key;
        const sessionKey = schedule?.session_key;
        const agendaKey = schedule?.agenda_key;
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const agendas = itemSession?.agendas ?? [];
                const newAgendas = agendas?.map((itemAgenda) => {
                  if (itemAgenda?.key === agendaKey) {
                    const workshops = itemAgenda?.workshops ?? [];
                    const newWorkshops = workshops?.map((itemWorkshop) => {
                      if (itemWorkshop?.key === newPayload?.key) {
                        return {
                          ...itemWorkshop,
                          ...newPayload,
                        };
                      } else {
                        return itemWorkshop;
                      }
                    });
                    return {
                      ...itemAgenda,
                      workshops: newWorkshops,
                    };
                  } else {
                    return itemAgenda;
                  }
                });
                return {
                  ...itemSession,
                  agendas: newAgendas,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    } else if (type === 'workshop_agenda') {
      if (action === 'create') {
        newData = dataSchedule?.map((item) => {
          if (dayKey === item?.key) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const agendas = itemSession?.agendas ?? [];
                const newAgendas = agendas?.map((itemAgenda) => {
                  if (itemAgenda?.key === agendaKey) {
                    const workshops = itemAgenda?.workshops ?? [];
                    const newWorkshops = workshops?.map((itemWorkshop) => {
                      if (itemWorkshop?.key === workshopKey) {
                        const workshopAgendas = itemWorkshop?.workshop_agendas ?? [];
                        const maxNumber =
                          !!workshopAgendas && !!workshopAgendas?.length
                            ? Math.max(...workshopAgendas?.map((x) => x?.no))
                            : 0;
                        const number = maxNumber + 1;
                        return {
                          ...itemWorkshop,
                          workshop_agendas: [...workshopAgendas, { ...newPayload, no: number }],
                        };
                      } else {
                        return itemWorkshop;
                      }
                    });
                    return {
                      ...itemAgenda,
                      workshops: newWorkshops,
                    };
                  } else {
                    return itemAgenda;
                  }
                });
                return {
                  ...itemSession,
                  agendas: newAgendas,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      } else if (action === 'update') {
        const schedule = payload?.schedule;
        const dayKey = schedule?.day_key;
        const sessionKey = schedule?.session_key;
        const agendaKey = schedule?.agenda_key;
        newData = dataSchedule?.map((item) => {
          if (item?.key === dayKey) {
            const sessions = item?.sessions ?? [];
            const newSessions = sessions?.map((itemSession) => {
              if (itemSession?.key === sessionKey) {
                const agendas = itemSession?.agendas ?? [];
                const newAgendas = agendas?.map((itemAgenda) => {
                  if (itemAgenda?.key === agendaKey) {
                    const workshops = itemAgenda?.workshops ?? [];
                    const newWorkshops = workshops?.map((itemWorkshop) => {
                      if (itemWorkshop?.key === workshopKey) {
                        const workshopAgendas = itemWorkshop?.workshop_agendas ?? [];
                        const newWorkshopAgendas = workshopAgendas?.map((itemWorkshopAgenda) => {
                          if (itemWorkshopAgenda?.key === newPayload?.key) {
                            return {
                              ...itemWorkshopAgenda,
                              ...newPayload,
                            };
                          } else {
                            return itemWorkshopAgenda;
                          }
                        });
                        return {
                          ...itemWorkshop,
                          workshop_agendas: newWorkshopAgendas,
                        };
                      } else {
                        return itemWorkshop;
                      }
                    });
                    return {
                      ...itemAgenda,
                      workshops: newWorkshops,
                    };
                  } else {
                    return itemAgenda;
                  }
                });
                return {
                  ...itemSession,
                  agendas: newAgendas,
                };
              } else {
                return itemSession;
              }
            });
            return {
              ...item,
              sessions: newSessions,
            };
          } else {
            return item;
          }
        });
      }
    }
    if (!!newData?.length) onValuesChange(newData);
  };

  const handleClickUpdate = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('day');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickUpdateSession = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('session');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  const handleClickUpdateWorkshop = (row: any) => {
    setVisibleDrawer(true);
    setTypeDrawer('workshop');
    setActionDrawer('update');
    setDataDrawer(row);
  };

  // console.log({ registConfig });
  const hideUpdate = registConfig === 'registration per event';
  let actionIgnored: any[] = ['detail', 'create', 'delete'];
  if (hideUpdate) actionIgnored = [...actionIgnored, 'update'];

  return (
    <React.Fragment>
      <DrawerScheduleForm
        visible={visibleDrawer}
        action={actionDrawer}
        data={dataDrawer}
        type={typeDrawer}
        onClose={onCloseDrawer}
        onChangeDrawer={onChangeDrawer}
        eventType={eventType?.toLowerCase()}
        registConfig={registConfig}
      />

      <DataTable
        className="antd-table-day"
        buttonActionProps={{
          // actionIgnored: ['detail', 'create', 'delete'],
          showAction: [
            'registration per event',
            'registration per each session',
            'registration manually by admin',
          ].includes(registConfig)
            ? false
            : true,
          actionIgnored: actionIgnored,
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
        }}
        columns={filterColumnsByEventType({ columns: columnsDayRegistration, type: eventType })}
        dataSource={dataSchedule}
        expandable={{
          rowExpandable: (record) => (record?.sessions?.length >= 1 ? true : false),
          expandedRowRender: (record) => {
            const sessions = record?.sessions ?? [];
            return (
              <DataTable
                className="antd-table-session"
                buttonActionProps={{
                  actionIgnored: ['detail', 'create', 'delete'],
                  showAction: [
                    'registration per event',
                    'registration per each day',
                    'registration manually by admin',
                  ].includes(registConfig)
                    ? false
                    : true,
                  handleClickUpdate(row) {
                    handleClickUpdateSession(row);
                  },
                }}
                columns={filterColumnsByEventType({ columns: columnsSessionRegistration, type: eventType })}
                dataSource={sessions}
                pagination={false}
                expandable={{
                  rowExpandable: (record) => {
                    const agenda = !!record?.agendas && !!record?.agendas.length ? record?.agendas[0] : null;
                    const workshops = agenda?.workshops ?? [];
                    return workshops?.length >= 1 ? true : false;
                  },
                  expandedRowRender: (recordSession) => {
                    const agenda =
                      !!recordSession?.agendas && !!recordSession?.agendas.length ? recordSession?.agendas[0] : null;
                    const workshops = agenda?.workshops ?? [];
                    return (
                      <DataTable
                        className="antd-table-workshop"
                        columns={filterColumnsByEventType({ columns: columnsWorkshopRegistration, type: eventType })}
                        buttonActionProps={{
                          actionIgnored: ['detail', 'create', 'delete'],
                          showAction: [
                            'registration per event',
                            'registration per each day',
                            'registration manually by admin',
                          ].includes(registConfig)
                            ? false
                            : true,
                          handleClickUpdate(row) {
                            handleClickUpdateWorkshop(row);
                          },
                        }}
                        dataSource={workshops}
                        pagination={false}
                        style={{ marginLeft: '50px' }}
                      />
                    );
                  },
                }}
              />
            );
          },
        }}
        pagination={false}
        style={{ borderColor: 'black' }}
      />
    </React.Fragment>
  );
}
