import { Button, Form } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ApiUrlData } from '@base-configs';
import { FormBuilder, makeArrayIds, Select, SelectPaginate } from '@eigen3m/react-base-frontend';
import { makeLabelValue } from '../helpers';
import { FC } from 'react';

type FormBuilderOnsiteProps = {
  optionsDay?: any[];
  isPublicEvent?: boolean;
};

export const FormBuilderOnsite: FC<FormBuilderOnsiteProps> = ({ isPublicEvent, optionsDay }) => {
  return (
    <FormBuilder
      className="mt-0"
      columns={[
        {
          fields: [
            {
              label: 'Price Name',
              name: 'name',
              fieldType: 'inputText',
              gridColumn: { xl: 12, lg: 12, md: 24 },
              rules: [{ required: true, message: 'Price Name is required' }],
            },
            {
              label: 'Price',
              name: 'price',
              fieldType: 'inputNumber',
              gridColumn: { xl: 12, lg: 12, md: 24 },
              rules: [{ required: true, message: 'Price  is required' }],
              inputNumberProps: {
                formatter(value) {
                  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
              },
            },
            {
              gridColumn: { span: 24 },
              renderField(form) {
                return (
                  <Form.List
                    name="day_session_workshops"
                    initialValue={[
                      {
                        day: null,
                        session: null,
                        workshop: null,
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => {
                      return fields.map((field, index) => {
                        const newOptionsDay = makeLabelValue({
                          values: optionsDay,
                          keyLabel: 'no',
                          typeLabel: 'Day',
                        });
                        const day = form.getFieldValue(['day_session_workshops', index, 'day']);
                        const optionsSession = day?.sessions ?? [];
                        const newOptionsSession = makeLabelValue({
                          values: optionsSession,
                          keyLabel: 'no',
                          typeLabel: 'Session',
                        });

                        const isDisabledSession = !!optionsSession?.length ? false : true;

                        const session = form.getFieldValue(['day_session_workshops', index, 'session']);
                        const optionsWorkshop =
                          !!session?.agendas && !!session?.agendas?.length ? session?.agendas[0]?.workshops : [];
                        const newOptionsWorkshop = optionsWorkshop?.map((item) => {
                          return {
                            label: item?.name,
                            value: item,
                          };
                        });

                        const isDisabledWorkshop = !!optionsWorkshop && !!optionsWorkshop?.length ? false : true;

                        //Reset Form
                        if (!day) form.setFieldValue(['day_session_workshops', index, 'session'], null);
                        if (!session) form.setFieldValue(['day_session_workshops', index, 'workshop'], null);
                        return (
                          <div
                            key={field.key}
                            className="item-day-session"
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: fields.length > 1 ? 'calc(100% - 80px)' : 'calc(100% - 45px)',
                              }}
                            >
                              <Form.Item
                                label="Day"
                                name={[field.name, 'day']}
                                style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                              >
                                <Select
                                  classNamePrefix="react-select-custom-prefix-radius"
                                  options={newOptionsDay}
                                  customLabel={(value) => {
                                    const noDay = value?.no;
                                    return `Day ${noDay}`;
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Session"
                                name={[field.name, 'session']}
                                style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                              >
                                <Select
                                  classNamePrefix="react-select-custom-prefix-radius"
                                  options={newOptionsSession}
                                  isDisabled={isDisabledSession}
                                  customLabel={(value) => {
                                    const noSession = value?.no;
                                    return `Session ${noSession}`;
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label="Workshop"
                                name={[field.name, 'workshop']}
                                style={{ width: 'calc(50% - 5px)' }}
                              >
                                <Select
                                  classNamePrefix="react-select-custom-prefix-radius"
                                  options={newOptionsWorkshop}
                                  isDisabled={isDisabledWorkshop}
                                  customLabel={(value) => {
                                    return value?.name;
                                  }}
                                  isMulti
                                />
                              </Form.Item>
                            </div>
                            <div style={{ display: 'flex', width: fields.length > 1 ? '70px' : '35px' }}>
                              <Form.Item label="&#32;">
                                {fields.length > 1 && (
                                  <Button
                                    icon={<MinusOutlined />}
                                    onClick={() => remove(field.name)}
                                    style={{ width: '30px', marginRight: fields.length > 1 ? '10px' : '0px' }}
                                  />
                                )}
                                {fields.length - 1 === index && (
                                  <Button icon={<PlusOutlined />} onClick={() => add()} style={{ width: '30px' }} />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        );
                      });
                    }}
                  </Form.List>
                );
              },
              // customHidden() {
              //   const isHidden =
              //     !!registType &&
              //     (registType?.toLowerCase().includes('day') || registType?.toLowerCase().includes('session'))
              //       ? false
              //       : true;
              //   return isHidden;
              // },
            },
            {
              gridColumn: { xl: 12, lg: 12, md: 24 },
              renderField() {
                return (
                  <Form.Item label="Department" name="departments">
                    <SelectPaginate
                      classNamePrefix="react-select-custom-prefix-radius"
                      dataSourceUrl={ApiUrlData.enum_department}
                      isMulti
                      isClearable
                      customLabel={(value) => value?.name}
                      keySearch="search"
                    />
                  </Form.Item>
                );
              },
            },
            {
              gridColumn: { xl: 12, lg: 12, md: 24 },
              renderField(form) {
                const department = form.getFieldValue(['departments']);
                const departmentIds = makeArrayIds({ data: department, valueWhenNull: 'id' });
                const isDisabledMinistries = department?.length ? false : true;
                return (
                  <Form.Item label="Ministries" name="ministries">
                    <SelectPaginate
                      key={JSON.stringify([departmentIds])}
                      classNamePrefix="react-select-custom-prefix-radius"
                      dataSourceUrl={ApiUrlData.enum_ministry}
                      isMulti
                      isClearable
                      customLabel={(value) => value?.name}
                      keySearch="search"
                      isDisabled={isDisabledMinistries}
                      filterRequest={{
                        department_ids: departmentIds,
                      }}
                    />
                  </Form.Item>
                );
              },
            },
            {
              label: 'Registration Condition',
              name: 'regist_condition',
              fieldType: 'inputNumber',
              gridColumn: { xl: isPublicEvent ? 8 : 12, lg: isPublicEvent ? 8 : 12, md: 24 },
              rules: [{ required: true, message: 'Registration Condition' }],
              tooltip: 'Days Before Registration Close',
              inputNumberProps: {
                min: 0,
              },
            },
            {
              label: 'Need Confirm',
              name: 'need_confirm',
              fieldType: 'checkbox',
              gridColumn: { xl: 4, lg: 12, md: 24 },
              valuePropName: 'checked',
              customHidden(form) {
                const price = form.getFieldValue('price');
                const isHidden = price === 0 ? false : true;
                return isHidden;
              },
            },
          ],
        },
      ]}
    />
  );
};
