import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form, Tabs } from 'antd';
import { FormOfficer } from './form-officer';
import { ApiUrlData } from '@base-configs';
import { OptionsYesOrNo } from '@helpers';
import { getSchedulePricing } from '@pages/event/event-data/presentation/helpers';

interface Props {
  dataSchedule?: any;
  registConfig?: string;
  dataPricing?: any;
}

export function FormAttendanceOfficers(props: Props) {
  const { dataSchedule, registConfig, dataPricing } = props;
  return (
    <React.Fragment>
      <Form.Item name={['attendance_officers', 'id']} hidden noStyle />
      <FormBuilder
        title="Attendance Officers Configuration"
        columns={[
          {
            fields: [
              {
                label: 'How The attendance officers will be taken ?',
                gridColumn: { xxl: 4, xl: 6, lg: 6, md: 6, sm: 12, xs: 24 },
                name: ['attendance_officers', 'attendance_configuration'],
                fieldType: 'selectPaginate',
                rules: [{ required: true, message: 'When the officer will be assign ?' }],
                selectPaginateProps: {
                  key: registConfig,
                  dataSourceUrl: ApiUrlData.enum_attendance_type,
                  isClearable: true,
                  customLabel: (value) => value,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  transformOptions(options) {
                    const registType = !!registConfig ? registConfig?.toLowerCase() : null;
                    if (registType?.includes('event')) {
                      return options?.filter((item) => ['None', 'Once per Event']?.includes(item));
                    } else if (registType?.includes('day')) {
                      return options?.filter((item) => ['None', 'Each Day']?.includes(item));
                    } else if (registType?.includes('session')) {
                      return options?.filter((item) => ['None', 'Each Session']?.includes(item));
                    } else {
                      return [];
                    }
                  },
                },
              },
              {
                label: 'Use QR Code for attendance?',
                gridColumn: { xxl: 4, xl: 4, lg: 4, md: 6, sm: 12, xs: 24 },
                name: ['attendance_officers', 'use_qr'],
                fieldType: 'select',
                rules: [{ required: true, message: 'Use QR Code for attendance? is required.' }],
                selectProps: {
                  isClearable: false,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item?.value === value)?.label,
                },
                customHidden(form) {
                  return true;
                  const attendanceConfig = form?.getFieldValue(['attendance_officers', 'attendance_configuration']);
                  return attendanceConfig?.toLowerCase()?.includes('none') ? true : false;
                },
              },
            ],
          },
        ]}
      />
      <Form.Item dependencies={['attendance_officers', 'attendance_configuration']} noStyle>
        {(form) => {
          const optionsDay = dataSchedule?.days ?? [];
          const optionsDayMain = dataPricing?.payment_pricing_datas?.length
            ? getSchedulePricing(dataPricing?.payment_pricing_datas)
            : [];
          const optionsDaySub = dataPricing?.payment_pricing_subs?.length
            ? getSchedulePricing(dataPricing?.payment_pricing_subs)
            : [];
          const attendanceConfig = form.getFieldValue(['attendance_officers', 'attendance_configuration']);
          const isHidden = attendanceConfig?.toLowerCase()?.includes('none') ? true : false;
          return !isHidden ? (
            <Tabs>
              <Tabs.TabPane tab="Officer" key="1" forceRender active={true}>
                <Form.Item name={['attendance_officers', 'officer_datas']} noStyle>
                  <FormOfficer
                    optionsDayMain={optionsDayMain}
                    optionsDaySub={optionsDaySub}
                    attendanceConfig={attendanceConfig}
                  />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <></>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
