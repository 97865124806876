export const AuthWebUrl = {
  template: '/template',
  users: '/users',
  report: '/report',
  qr: '/qr-code',
  attendance: '/attendances',

  //configuration
  bank_accounts: '/configuration/bank-accounts',
  email_setting: '/configuration/email-setting',
  email_template: '/configuration/email-template',
  user_categories: '/configuration/user-categories',

  //event
  speaker: '/event/speaker',
  venue: '/event/venue',
  event_data: '/event/event-data',
  even_category: '/event/event-category',
  event_activity: '/event/event-activity',
  user_category: '/event/user-category',
  setting: '/event/setting',
};
