import { Button, Form } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ApiUrlData } from '@base-configs';
import { FormBuilder, makeArrayIds, Select, SelectPaginate } from '@eigen3m/react-base-frontend';
import { makeLabelValue } from '../helpers';
import { FC } from 'react';

type FormBuilderOnsiteProps = {
  optionsDay?: any[];
  isPublicEvent?: boolean;
};

export const FormBuilderOnline: FC<FormBuilderOnsiteProps> = ({ isPublicEvent, optionsDay }) => {
  return (
    <FormBuilder
      className="mt-0"
      columns={[
        {
          fields: [
            {
              label: 'Event Link',
              gridColumn: { xl: 8, lg: 12, md: 24 },
              name: ['event_link'],
              rules: [{ required: true, message: 'Event Link is required' }],
              fieldType: 'inputText',
              // customHidden() {
              //   return !!eventType && eventType?.toLowerCase() === 'online' ? false : true;
              // },
            },
            {
              label: 'Event Code',
              gridColumn: { xl: 8, lg: 12, md: 24 },
              name: ['event_code'],
              rules: [{ required: true, message: 'Event Code is required' }],
              fieldType: 'inputText',
              // customHidden() {
              //   return !!eventType && eventType?.toLowerCase() === 'online' ? false : true;
              // },
            },
            {
              label: 'Event Password',
              name: ['event_password'],
              rules: [{ required: true, message: 'Event Password is required' }],
              gridColumn: { xl: 8, lg: 12, md: 24 },
              fieldType: 'inputText',
              // customHidden() {
              //   return !!eventType && eventType?.toLowerCase() === 'online' ? false : true;
              // },
            },
          ],
        },
      ]}
    />
  );
};
