import { useRef, useState } from 'react';
import {
  FormBuilder,
  FormPageWrapper,
  notificationFailed,
  notificationSuccess,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import * as _ from 'lodash';
import { Tabs } from 'antd';

import { EmailEditorPage } from '../components/email-editor';
import { Modal, Form, Card } from 'antd';
import { mockCompanies } from '@states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editorState } from '../state';
import PreviewEmailTemplate from '../components/preview-email-template';
import { v4 as uuidV4 } from 'uuid';

const { TabPane } = Tabs;
export default function FormPage() {
  const { isCreate, isUpdate, isDuplicate, navigation, dataSource, transformer, id } = usePageProvider();
  const { transformerCreate, transformerUpdate, transformerDuplicate, transformerGetData } = transformer;

  const emailEditorRef: any = useRef(null);
  const [form, setForm] = useState(undefined);
  const [activeKey, setActiveKey] = useState('1');
  const [emailBody, setEmailBody] = useState('');
  const [uuid, setUuid] = useState(uuidV4());

  const [companies] = useRecoilState(mockCompanies);
  const isReady = useRecoilValue(editorState);

  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';
  async function create(payload: any): Promise<void> {
    await dataSource.handleCreate(payload, {
      onSuccess: ({ response }: any) => {
        // if (handleAfterCreate) handleAfterCreate(response);
        navigation.goToDetail({ id: response.id });
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function handleConfirmProcess(payload): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: () => {
        console.log('success');
      },
      onFailed: () => {
        console.log('failed');
      },
    });
  }

  async function update(payload: any): Promise<void> {
    await dataSource.handleUpdate(id, payload, {
      onSuccess: async ({ response }: any) => {
        // if (handleAfterUpdate) handleAfterUpdate(response);
        await handleConfirmProcess(response);
        navigation.goToIndex();
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function handleSave(): Promise<void> {
    try {
      const values = await form.validateFields();
      emailEditorRef?.current?.editor.exportHtml(async (data: any) => {
        const { design, html } = data;
        let payload = { ...values, company: companies[0], email_body: html, email_design: design };
        if (isUpdate) payload = transformerUpdate(payload);
        else if (isCreate) payload = transformerCreate(payload);
        else if (isDuplicate) payload = transformerDuplicate(payload);

        const cleanVal = _.omit(payload, ['id', 'form_action']);
        if (isUpdate) await update(cleanVal);
        else await create(cleanVal);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleClickSave(): Promise<void> {
    try {
      if (form) await form.validateFields();
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: 'Save Confirmation',
        content: 'Are you sure you want to save this data?',
        onOk: () => handleSave(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  const onChange = (key: string) => {
    if (key) {
      exportHtml();
      setActiveKey(key);
      setUuid(uuidV4());
    }
  };

  async function exportHtml(): Promise<string> {
    try {
      await emailEditorRef?.current?.editor.exportHtml(async (data: any) => {
        if (data?.html) {
          setEmailBody(data?.html);
          return data?.html;
        } else return '';
      });
    } catch (error) {
      return '';
    }
  }

  return (
    <FormPageWrapper
      // autoConfirmAfterAction={true}
      pageToolbarProps={{
        pageTitle: `${title} Email Template`,
        handleClickSave,
        //  showBack: false
      }}
      getFormInstance={setForm}
    >
      <Card style={{ marginBottom: 30 }}>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  name: 'email_type',
                  label: 'Email Type',
                  fieldType: 'inputText',
                  inputTextProps: {
                    placeholder: 'Email Type',
                    disabled: true,
                  },
                },
                {
                  name: 'email_subject',
                  label: 'Email Subject',
                  style: { width: '30vw' },
                  fieldType: 'inputText',
                  inputTextProps: {
                    placeholder: 'Enter email subject',
                    disabled: !isReady,
                  },
                },
              ],
            },
          ]}
        />
        <div>
          <div style={{ display: 'flex' }}>
            <Form.Item label="Variable List" style={{ marginRight: 10 }}>
              <div style={{ marginBottom: 10 }}></div>
              <div>
                <div>{'Registrant Title: {{registran.title}}'}</div>
                <div>{'Registrant Name: {{registran.name}}'}</div>
                <div>{'Event category: {{{event_category}}} (API Activity)'}</div>
                <div>{'Event Banner: {{{event_banner}}}'}</div>
                <div>{'Event Name:  {{{event_name}}}'}</div>
                <div>{'Event Description:  {{{event_description}}}'}</div>
                <div>{'Event Type:  {{{event_type}}}'}</div>
                <div>{'Event Venue: {{{event_venue}}}.'}</div>
                <div>{'Event Term of condition:  {{{event_term_and_condition}}}'}</div>
              </div>
            </Form.Item>

            <Form.Item label="Variable List Template" style={{ marginRight: 10 }}>
              <div style={{ marginBottom: 10 }}></div>
              <div>{'Schedule Participant: {{{schedules}}}'}</div>
              <div>{'Schedule Participant Online: {{{schedule_online}}}'}</div>
              <div>{'List Participant: {{{participants}}}'}</div>
              <div>{'List Participant with QRCode: {{{participant_attandances}}}'}</div>
              <div>{'Invoice: {{{invoice}}}'}</div>
              <div>{'List Officer Name: {{{officer}}}'}</div>
              <div>{'List Officer with QR: {{{officer_attandance}}}'}</div>
              <div>{'List Payment Method: {{{payment_pricings}}}'}</div>
            </Form.Item>

            <Form.Item label="Button" style={{ marginRight: 10 }}>
              <div style={{ marginBottom: 10 }}></div>
              <div>{'Link Payment Confirmation: {{payment_confirmation}}'}</div>
              <div>{'Link Payment Cancellation: {{payment_cancellation}}'}</div>
            </Form.Item>
          </div>
        </div>
      </Card>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          return (
            <Card bodyStyle={{ paddingTop: '10px', overflow: 'hidden' }}>
              <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
                <TabPane tab="Form" key="1" destroyInactiveTabPane>
                  <Form.Item name="email_body" noStyle />
                  <Form.Item name="email_design">
                    <EmailEditorPage emailEditorRef={emailEditorRef} />
                  </Form.Item>
                </TabPane>
                <TabPane tab="Full Preview" key="2" destroyInactiveTabPane>
                  <PreviewEmailTemplate value={emailBody} key={uuid} />
                </TabPane>
              </Tabs>
            </Card>
          );
        }}
      </Form.Item>
    </FormPageWrapper>
  );
}
