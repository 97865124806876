// export function generateUserCategorySubModule(data) {

import { authHelper } from '@eigen3m/react-base-frontend';

// };

const superAdminKey = '982f9a63-cf46-4d0c-9a96-01fa33dee7a1';

export function accessRightGenerator(data, isAdmin?: boolean) {
  if (data?.access_right?.length > 0) {
    const newData = {};
    data?.access_right?.forEach((item) => {
      if (isAdmin) {
        Object.assign(newData, { [item?.name]: true });
      } else {
        Object.assign(newData, { [item?.name]: item?.allowed === false ? false : true });
      }
    });
    return newData;
  } else {
    return null;
  }
}

export function userCategoryTransformer(data) {
  if (data?.access_right?.length > 0) {
    const newData = {};
    const isAdmin = data?.id === superAdminKey;
    data?.access_right?.forEach((access) => {
      Object.assign(newData, accessRightGenerator(access, isAdmin));
      if (access?.sub_modules?.length > 0) {
        access?.sub_modules?.forEach((subModule) => {
          Object.assign(newData, { [subModule?.name]: accessRightGenerator(subModule, isAdmin) });
          if (subModule?.activities?.length > 0) {
            subModule?.activities?.forEach((activity) => {
              const subModuleData = newData[subModule?.name];
              Object.assign(subModuleData, { [activity?.name]: accessRightGenerator(activity, isAdmin) });
            });
          }
        });
      }
    });
    return newData;
  }
}

interface AccessRight {
  [key: string]: any;
  cancel: boolean;
  change_status: boolean;
  confirm_process: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
  export: boolean;
  import: boolean;
  print: boolean;
  reverse: boolean;
  view: boolean;
  view_log: boolean;
  view_notes: boolean;
}

export function getAccessRight(moduleKey: string, subModuleKey?: string): AccessRight {
  const account = authHelper?.getAccount();
  const accessRight = account?.access_right;
  const module = moduleKey?.replace(/-/g, ' ');
  const subModule = subModuleKey?.replace(/-/g, ' ');
  if (accessRight) {
    if (subModule) return accessRight[module][subModule];
    else return accessRight[module];
  } else {
    return null;
  }
}

const example = {
  event: {
    users: {
      cancel: true,
    },
  },
};
