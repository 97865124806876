import { useState } from 'react';
import { Form } from 'antd';
import {
  ActionProps,
  IndexPageWrapper,
  notificationFailed,
  notificationSuccess,
  SelectPaginate,
  SinglePage,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { participantColumns, statusesParticipantOptions } from '@pages/event/event-activity/presentation/helpers';
import DrawerForm from './drawer-form';
import { RegistrantSummary } from './card-registrant';
import { ApiUrlData, subModuleKey } from '@base-configs';
import { BiX } from 'react-icons/bi';
import { OptionsYesOrNo } from '@helpers';
import { useParams } from 'react-router-dom';

export default function IndexTable(): JSX.Element {
  const { dataSource, id: eventId } = usePageProvider();
  const params = useParams();
  const eventActivitiesID = params?.id;
  const [visibleDrawerForm, setVisibleDrawerForm] = useState<boolean>(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  async function handleCancel(id: string): Promise<void> {
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'PATCH',
        url: process.env.REACT_APP_BASE_URL + ApiUrlData.participant + `/${id}/confirm-cancel`,
      },
      onSuccess: () => {
        notificationSuccess(['Successfully cancel data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  return (
    <IndexPageWrapper
      key="participant"
      padding={[]}
      showPageHeader={false}
      showPageHeaderSimple={true}
      drawerFilterProp={{
        width: 600,
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  label: 'Day',
                  name: 'day',
                  gridColumn: { xl: 8 },
                  fieldType: 'selectPaginate',
                  selectPaginateProps: {
                    dataSourceUrl: ApiUrlData.eventData + `/${eventId}/schedules/day`,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (row) => `Day ${row?.no_day}`,
                    isClearable: true,
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const day = form.getFieldValue('day');
                    const dayId = day?.id;
                    const hasSession = day?.has_session;
                    return (
                      <Form.Item name="session" label="Session">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${dayId}/schedules/session`}
                          customLabel={(row) => `Session ${row?.no_session}`}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasSession}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const session = form.getFieldValue('session');
                    const sessionId = session?.id;
                    const hasWorkshop = session?.has_workshop;
                    return (
                      <Form.Item name="workshop" label="Workshop">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${sessionId}/schedules/workshop`}
                          customLabel={(row) => row?.name}
                          filterRequest={{ event_id: eventId }}
                          isDisabled={!hasWorkshop}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  label: 'Member',
                  name: 'member',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    options: OptionsYesOrNo,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                    isClearable: true,
                  },
                },
                // {
                //   label: 'Check In',
                //   name: 'check_in',
                //   gridColumn: { xl: 8 },
                //   fieldType: 'select',
                //   selectProps: {
                //     options: OptionsYesOrNo,
                //     classNamePrefix: 'react-select-custom-prefix-radius',
                //     customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                //     isClearable: true,
                //   },
                // },
                {
                  label: 'Statuses',
                  name: 'statuses',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    isMulti: true,
                    options: statusesParticipantOptions,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => statusesParticipantOptions?.find((item) => item.value === value).label,
                    isClearable: true,
                  },
                },
              ],
            },
          ],
        },
      }}
      pageToolbarProps={{
        showConfirmProcess: false,
        showImport: true,
        showPrint: true,
        showBack: false,
        handleClickNewData() {
          onShowDrawerForm('create');
        },
      }}
      drawerImportProps={{
        customModuleKey: 'participants',
        defaultValueFilter: {
          event_ids: !!eventId ? [eventId] : [],
        },
      }}
    >
      <div className="no-print">
        <RegistrantSummary />
      </div>
      <Form.Item shouldUpdate noStyle>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const values = getFieldsValue();
            const id = values?.id ?? eventActivitiesID;
            const participants = values?.participants;

            return (
              <SinglePage
                drawerFormProps={{
                  action: actionForm,
                  visible: visibleDrawerForm,
                  onClose: onCloseDrawerForm,
                  onShow: onShowDrawerForm,
                  children: <DrawerForm id={id} participants={participants} />,
                  width: 600,
                }}
                dataTableProps={{
                  rowActionProps: {
                    actionIgnored: ['detail', 'duplicate'],
                    customActionIgnored(row: any): any[] {
                      if (['cancel', 'waiting', 'closed']?.includes(row?.status?.toLowerCase())) {
                        return ['activate', 'deactivate', 'delete', 'cancel'];
                      }
                    },
                    additionalActions(row) {
                      if (['waiting', 'pending', 'active', 'draft']?.includes(row?.status.toLowerCase())) {
                        return [
                          {
                            key: 'Cancel',
                            icon: <BiX />,
                            label: 'Cancel',
                            onClick(e) {
                              e.domEvent.stopPropagation();
                              handleCancel(row?.id);
                            },
                          },
                        ];
                      } else {
                        return [];
                      }
                    },
                  },
                  columns: participantColumns,
                  onRow: null,
                }}
                autoConfirmAfterAction={actionForm === 'update' ? true : false}
              />
            );
          }}
        </Form.Item>
      </Form.Item>
    </IndexPageWrapper>
  );
}
