import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

import { UserCategoriesEntity } from '../../domain/entities';
import { makeUserCategoriesTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeUserCategoriesTransformer<UserCategoriesEntity>();
  const InitialValue: InitialValuePageProviderEntity<UserCategoriesEntity> = {
    transformer,
    moduleKey: 'user-categories',
    webUrl: WebUrlData.user_categories,
    apiUrl: ApiUrlData.user_category,
    baseApiUrl: process.env.REACT_APP_ACCESS_CONTROL_URL,
    // apiUrl: 'v1/user-category',
    // baseApiUrl: 'https://api.testing.erp.eigen.co.id/api/',
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
