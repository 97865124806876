import React, { useEffect, useState } from 'react';
import { Descriptions, Image, InputNumber, Form, DatePicker, Input } from 'antd';
import {
  dateFormatter,
  makeCommonDataSource,
  RenderCurrency,
  Select,
  stringFormatter,
} from '@eigen3m/react-base-frontend';
import { renderRegisteredSchedule } from '@helpers';
import { UploadImageProof } from '@pages/event/event-activity/presentation/components/form-tabs/tab-payments/components/drawer-form/upload-image-proof';
import { ApiUrlData } from '@base-configs';
import { useSearchParams } from 'react-router-dom';

const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;

export function InvoiceDetail({ data, type = 'detail' }: { data: any; type?: 'detail' | 'form' }): JSX.Element {
  console.log(data);
  const registrans = data?.registrans ?? [];
  const imageUrl = data?.image_url ?? [];

  // const eventId = data?.registrans?.[0]?.event_id;
  const [paramsRoute] = useSearchParams();
  const eventId = paramsRoute.get('id');

  const [bankOptions, setBankOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);

  const dataSourceEvent = makeCommonDataSource({
    apiUrl: ApiUrlData.event,
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
  });

  async function getDataEvent(eventId) {
    try {
      await dataSourceEvent.handleCustomRequest({
        paramRequest: {
          baseURL: `${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.event}/${eventId}/payments`,
        },
        onSuccess({ response }: any) {
          const bankOptions = response?.banks?.map((item) => {
            return {
              label: item?.name,
              value: item,
            };
          });
          setBankOptions(bankOptions);
          const accountOptions = response?.accounts?.map((item) => {
            return {
              label: item?.account_no,
              value: item,
            };
          });
          setAccountOptions(accountOptions);
        },
        onFailed(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataEvent(eventId);
  }, [eventId]);

  return (
    <React.Fragment>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue();
          const onChangeDestinationBank = () => {
            setFieldsValue({
              bank_account: null,
            });
          };
          const bankId = values?.destination_bank?.id;
          return (
            <>
              <h2>Invoice Detail</h2>
              <Descriptions bordered size="small" style={{ marginBottom: '20px' }}>
                <Descriptions.Item label="Invoice ID" span={24}>
                  {data?.code ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date" span={24}>
                  {data?.registrans?.[0]?.registration_date
                    ? dateFormatter(data?.registrans?.[0]?.registration_date).parseEpoch('DD MMM YYYY, HH:mm')
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Participant's Email" span={24}>
                  {data?.registrans?.[0]?.email ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Confirmation Expired" span={24}>
                  {data?.expired_date ? dateFormatter(data?.expired_date).parseEpoch('DD MMM YYYY, HH:mm') : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={24}>
                  {data?.status
                    ? stringFormatter.capitalizeEachWord(data?.status?.split('_')?.join(' ')?.split('-')?.join(' '))
                    : '-'}
                </Descriptions.Item>
              </Descriptions>
              <h2 style={{ marginTop: '20px' }}>Payment Proof</h2>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Payment Date" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="payment_date" noStyle>
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  ) : (
                    <div>
                      {data?.payment_date ? dateFormatter(data?.payment_date).parseEpoch('DD MMM YYYY, HH:mm') : '-'}
                    </div>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Destination Bank" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="destination_bank" noStyle>
                      <Select
                        options={bankOptions}
                        keyLabel="name"
                        onChange={onChangeDestinationBank}
                        classNamePrefix="react-select-custom-prefix-radius"
                      />
                    </Form.Item>
                  ) : (
                    data?.destination_bank ?? '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Account No" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="bank_account" noStyle>
                      <Select
                        options={accountOptions?.filter((item) => item?.value?.bank_id === bankId)}
                        isDisabled={values?.destination_bank ? false : true}
                        keyLabel="account_no"
                        classNamePrefix="react-select-custom-prefix-radius"
                      />
                    </Form.Item>
                  ) : (
                    data?.bank_account ?? '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Bank" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="payment_type" noStyle>
                      <Input placeholder="Input" />
                    </Form.Item>
                  ) : (
                    data?.payment_type ?? '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Account Name" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="payer_name" noStyle>
                      <Input placeholder="Input" />
                    </Form.Item>
                  ) : (
                    data?.payer_name ?? '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Account No" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="no_payer_account" noStyle>
                      <Input placeholder="Input" />
                    </Form.Item>
                  ) : (
                    data?.no_payer_account ?? '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Proof" span={24}>
                  {type === 'form' ? (
                    <Form.Item name="image_url" noStyle>
                      <UploadImageProof />
                    </Form.Item>
                  ) : (
                    <div>
                      {' '}
                      {!!imageUrl && !!imageUrl?.length
                        ? imageUrl?.map((item, index) => {
                            const sourceImage = item?.image_url;
                            const url = assetUrl + '/' + sourceImage;
                            return <Image key={index} src={url} height={60} />;
                          })
                        : '-'}
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <h2 style={{ marginTop: '20px' }}>Payment Summary</h2>
              <table style={{ width: '100%' }}>
                {registrans?.map((item, index) => {
                  const participantName = item?.name;
                  const schedules = item?.participant ?? [];
                  return (
                    <>
                      <tr key={index}>
                        <td colSpan={2} key={index}>
                          <strong>{participantName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ paddingBottom: '20px' }}>
                          {schedules?.map((x, i) => {
                            const registeredSchedule = x?.registered_schedule
                              ? renderRegisteredSchedule({ value: x?.registered_schedule })
                              : null;
                            const price = x?.registered_schedule?.price?.price ?? 0;
                            const priceName = x?.registered_schedule?.price?.name;
                            const labelPriceName = priceName ? `(${priceName})` : null;
                            const labelDescription =
                              !!registeredSchedule && !!labelPriceName
                                ? `${registeredSchedule} ${labelPriceName}`
                                : registeredSchedule;
                            return (
                              <table key={i} style={{ width: '100%' }}>
                                <tr>
                                  <td>{labelDescription}</td>
                                  <td style={{ textAlign: 'right' }}>
                                    <div style={{ display: 'flex', float: 'right' }}>
                                      Rp
                                      {price
                                        ? RenderCurrency({
                                            value: price,
                                            currencyOptions: {
                                              minimumFractionDigits: 0,
                                            },
                                          })
                                        : 0}
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            );
                          })}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
              <table style={{ width: '100%', borderTop: '1px solid #f0f0f0' }}>
                <tr>
                  <td style={{ paddingTop: '20px' }}>
                    <strong>Total Invoice</strong>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <div style={{ display: 'flex', float: 'right' }}>
                      Rp
                      {data?.total_invoice
                        ? RenderCurrency({
                            value: data?.total_invoice,
                            currencyOptions: {
                              minimumFractionDigits: 0,
                            },
                          })
                        : 0}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total Payment</strong>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {type === 'form' ? (
                      <Form.Item name="total_payment" style={{ display: 'inline-flex' }}>
                        <InputNumber
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="Input"
                          style={{ width: '100%', textAlign: 'right' }}
                          // bordered={false}
                          min={1}
                          disabled={data?.status === 'done'}
                          controls={false}
                          // bordered={false}
                        />
                      </Form.Item>
                    ) : (
                      <div style={{ display: 'flex', float: 'right' }}>
                        Rp
                        {data?.total_payment
                          ? RenderCurrency({
                              value: data?.total_payment,
                              currencyOptions: {
                                minimumFractionDigits: 0,
                              },
                            })
                          : 0}
                      </div>
                    )}
                  </td>
                </tr>
              </table>
            </>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
