import { ApiUrlData, subModuleKey, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity, PageProvider, usePageProvider } from '@eigen3m/react-base-frontend';
import { PaymentEntity } from '@pages/event/event-activity/domain/entities';
import { makePaymentTransformer } from '@pages/event/event-activity/domain/factories/payment-factory';
import { useParams } from 'react-router-dom';
import IndexTable from './components/index-table';

export const TabPayment = (): JSX.Element => {
  const { id } = usePageProvider();
  const params = useParams();
  const eventID = id ?? params?.id;
  const defaultFiler = { event_ids: eventID ? [eventID] : [], statuses: undefined };
  const transformer = makePaymentTransformer<PaymentEntity>(defaultFiler);
  const InitialValue: InitialValuePageProviderEntity<PaymentEntity> = {
    transformer,
    moduleKey: subModuleKey.event_activity.PAYMENT,
    webUrl: WebUrlData.event_activity,
    apiUrl: ApiUrlData.payment,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <IndexTable />
    </PageProvider>
  );
};
