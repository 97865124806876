import React, { useEffect, useState } from 'react';
import { Button, Layout, Skeleton } from 'antd';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import './banner.style.less';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { makeAssetUrl } from '@helpers';
import { reduceText } from '@components/post-card/helpers';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const BgElement = Element.BgElement;

type paramCarouselRequest = {
  banner_in_homepage?: boolean;
  banner_in_event_category?: boolean;
  page?: number;
  limit?: number;
};

interface Props {
  type?: 'homepage' | 'category';
}

export function Banner(props: Props): JSX.Element {
  const { type } = props;
  const [banners, setBanners] = useState([]);

  const [loading, setLoading] = useState(false);
  const { dataSource } = usePageProvider();

  const navigate = useNavigate();

  useEffect(() => {
    handleGetDataCarousel({
      page: 1,
      limit: 5,
      banner_in_homepage: type === 'homepage' ? true : undefined,
      banner_in_event_category: type === 'category' ? true : undefined,
    });
  }, []);

  const handleGetDataCarousel = async ({
    page,
    limit,
    banner_in_homepage,
    banner_in_event_category,
  }: paramCarouselRequest) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + '/menu',
        params: {
          page: page ?? 1,
          banner_in_homepage: banner_in_homepage,
          banner_in_event_category: banner_in_event_category,
          limit: limit ?? 3,
          statuses: ['active', 'in_process'],
        },
      },
      onSuccess(response: any) {
        const data = response?.response.items;
        setBanners([...data]);
      },
      onFailed(response: any) {
        console.log(response);
      },
    });
    setLoading(false);
  };

  if (!banners || banners.length === 0) return <div></div>;

  return (
    <Layout>
      {loading ? (
        <Skeleton.Image style={{ width: '100%', maxWidth: '1900px', height: '300px' }} active />
      ) : banners?.length >= 1 ? (
        <div className="main-banner">
          <BannerAnim prefixCls="banner-user" autoPlay>
            {banners.map((item, index) => {
              return (
                <Element prefixCls="banner-user-elem" key={index}>
                  <BgElement
                    title={item?.information?.name}
                    onClick={() => navigate(`/event/${item.id}`)}
                    key="bg"
                    className="bg background-image"
                    style={{
                      backgroundImage: `url(${makeAssetUrl(item?.information?.banner_url)})`,
                      cursor: 'pointer',
                    }}
                  />

                  {/* <div className="banner-content">
                <div className="content-description">
                  <div className="banner-user-title">{item?.information?.name}</div>
                  
                  <div style={{ marginBottom: 20 }}></div>
                  <Button
                    size="large"
                    type="default"
                    onClick={() => navigate(`/event/${item.id}`)}
                    style={{ width: 200, background: '#1360ef', color: '#fff' }}
                  >
                    {`Register Now`}
                  </Button>
                </div>
              </div> */}
                </Element>
              );
            })}
          </BannerAnim>
        </div>
      ) : (
        <div></div>
      )}
    </Layout>
  );
}
