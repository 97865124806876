import React, { useState } from 'react';
import { Button, Card, Image, Space } from 'antd';

const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;
export default function BankAccountList({ value }: { value: any[] }) {
  const [visible, setVisible] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  const data = !!value && !!value?.length ? value : [];
  return (
    <>
      <Space size={[8, 16]} wrap>
        {data?.map((item, index) => {
          const bank = item?.bank;
          const account = item?.account;
          const imageUrl = bank?.document_url ? assetUrl + bank?.document_url : 'error';
          const accountNo = account?.account_no;
          const accountName = account?.on_behalf_of;
          const qrCodeUrlAccount = account?.document_url ? assetUrl + account?.document_url : 'error';
          return (
            <Card
              key={index}
              // title={`${accountNo} (${accountName})`}
              title={
                <div style={{ margin: 0, padding: 0 }}>
                  <div>{accountNo}</div>
                  <div>{accountName}</div>
                </div>
              }
              style={{ width: 180, padding: 0 }}
              headStyle={{ textAlign: 'center', padding: 0, margin: 0, overflow: 'hidden' }}
              bodyStyle={{ padding: 0, overflow: 'hidden' }}
              size="small"
            >
              <Image
                src={imageUrl}
                preview={false}
                width={'100%'}
                height={90}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <div style={{ borderTop: '1px solid #f0f0f0' }}>
                <Button
                  block
                  style={{ border: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  disabled={qrCodeUrlAccount !== 'error' ? false : true}
                  onClick={() => {
                    setQrCodeUrl(qrCodeUrlAccount);
                    setVisible(true);
                  }}
                >
                  Show QR
                </Button>
              </div>
            </Card>
          );
        })}
      </Space>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={qrCodeUrl}
        preview={{
          visible,
          src: qrCodeUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
}
