import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity, PageProvider } from '@eigen3m/react-base-frontend';
import { BankAccountEntity } from '@pages/configuration/bank-accounts/domain/entities';
import { makeBankAccountTransformer } from '@pages/configuration/bank-accounts/domain/factories';

import IndexTable from './index-table';
export default function index({
  bankId,
  status,
  triggerRequest,
}: {
  bankId: string;
  status: any;
  triggerRequest: string;
}) {
  const transformer = makeBankAccountTransformer<BankAccountEntity>();
  const InitialValue: InitialValuePageProviderEntity<BankAccountEntity> = {
    transformer,
    // moduleKey: 'data-bank-account-page' + bankId,
    moduleKey: moduleKey.BANK_ACCOUNT + bankId,
    webUrl: WebUrlData.bank_accounts,
    apiUrl: ApiUrlData.accounts,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <IndexTable bankId={bankId} status={status} triggerRequest={triggerRequest} />
    </PageProvider>
  );
}
