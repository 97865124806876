import React, { useEffect, useState } from 'react';
import { DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Form, notification, Spin, Table, Tabs } from 'antd';
import { columnsDaySessionWorkshop, columnsOfficer } from '../../../../helpers';
import { ApiUrlData } from '@base-configs';
import { transformerGetDataAttendanceOfficer } from '@pages/event/event-data/domain/transformer/transformer.get';

interface Props {
  value?: any;
  onChange?: (value?: any) => void;
  dataSchedule?: any;
}
export function DetailAttendanceOfficers(props: Props) {
  const [form] = Form.useForm();
  const { id, dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);

  const handleGetDataOfficerAttendance = async (id: string) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/attendances`,
      },
      onSuccess(response: any) {
        const dataAttendance = response?.response ? transformerGetDataAttendanceOfficer(response?.response) : null;
        form.setFieldsValue({ ...dataAttendance });
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data attendance officer error',
          description: response,
        });
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleGetDataOfficerAttendance(id);
    }
  }, [id]);
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form form={form}>
          <DetailBuilder
            title="Attendance Officers Configuration"
            columns={[
              {
                fields: [
                  {
                    gridColumn: { xl: 6, lg: 6, md: 12, sm: 24 },
                    label: 'When the officer will be assign ?',
                    name: ['attendance_configuration'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { xl: 6, lg: 6, md: 12, sm: 24 },
                    label: 'Use QR Code for attendance ?',
                    name: ['use_qr'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value === 'true' ? 'Yes' : 'No';
                      },
                      defaultRender: () => '-',
                    },
                    customHidden: () => {
                      return true;
                    },
                  },
                ],
              },
            ]}
          />
          <Tabs>
            <Tabs.TabPane tab="Officers">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const officers = getFieldValue(['officer_datas']);
                  return (
                    <Table
                      dataSource={officers}
                      columns={columnsOfficer}
                      size="small"
                      bordered
                      pagination={false}
                      expandable={{
                        rowExpandable: (record) =>
                          record?.day_session_workshops?.length >= 1 || record?.sub_day_session_workshops?.length >= 1
                            ? true
                            : false,
                        expandedRowRender: (record) => {
                          const daySessionWorkshop = record?.day_session_workshops ?? [];
                          const subDaySessionWorkshop = record?.sub_day_session_workshops ?? [];
                          const allDaySessionWorkshop = [...daySessionWorkshop, ...subDaySessionWorkshop];
                          return (
                            <Table
                              columns={columnsDaySessionWorkshop}
                              dataSource={allDaySessionWorkshop}
                              pagination={false}
                              size="small"
                              bordered
                            />
                          );
                        },
                      }}
                    />
                  );
                }}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Spin>
    </React.Fragment>
  );
}
