import React from 'react';
import { Menu, Layout } from 'antd';
import type { MenuProps } from 'antd';
import { ScheduleOutlined, SettingOutlined } from '@ant-design/icons';
import { LaptopOutlined, NotificationOutlined, UserOutlined, BarChartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { moduleKey, WebUrlData } from '@base-configs';
const { Sider } = Layout;
import Logo from '@assets/images/navbar-logo.png';
// import EigenLogo from '@assets/images/e-logo.jpeg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { collapseMenu } from './atom';
import { Footer } from 'antd/lib/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined, FundOutlined } from '@ant-design/icons';
import { FiExternalLink } from 'react-icons/fi';
import { authHelper } from '@eigen3m/react-base-frontend';
import { getAccessRight, userCategoryTransformer } from 'shared/helpers/user-category-transformer';

import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import { BsCalendar4Range, BsCurrencyDollar, BsMap } from 'react-icons/bs';
import { CgTemplate } from 'react-icons/cg';
import { RiListSettingsLine } from 'react-icons/ri';
import { GrDocumentConfig } from 'react-icons/gr';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaUserGraduate } from 'react-icons/fa';
import { RiShieldUserLine } from 'react-icons/ri';
import { BiUser } from 'react-icons/bi';
import { BsPinMap } from 'react-icons/bs';
import { MdOutlineCategory } from 'react-icons/md';
import { BsCalendar3 } from 'react-icons/bs';
import { CustomScrollbars } from '@components/custom-scrollbars';

const superAdminKey = '982f9a63-cf46-4d0c-9a96-01fa33dee7a1';

export function Sidebar() {
  const [collapsed, setCollapsed] = useRecoilState(collapseMenu);

  const navigate = useNavigate();
  const location = window.location.pathname ?? '';

  const account = authHelper.getAccount();
  const isAdmin = account?.user?.user_category?.id === superAdminKey;

  const Menus = [
    // {
    //   key: `/dashboard`,
    //   icon: React.createElement(BarChartOutlined, { style: { fontSize: 16 } }),
    //   label: 'Dashboard',
    // },
    {
      key: `/configuration`,
      label: 'CONFIGURATION',
      type: 'group',
      children: [
        {
          icon: <BsCurrencyDollar style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.bank_accounts,
          moduleKey: moduleKey.BANK_ACCOUNT,
          label: 'Bank & Account',
        },
        {
          icon: <CgTemplate style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.email_template,
          moduleKey: moduleKey.EMAIL_TEMPLATE,
          label: 'Email Template',
        },
        {
          icon: <AiOutlineSetting style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.email_setting,
          moduleKey: moduleKey.EMAIL_SETTING,
          label: 'Email Setting',
        },
        {
          icon: <RiShieldUserLine style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.user_categories,
          moduleKey: moduleKey.USER_CATEGORY,
          label: 'User Category',
        },
        {
          icon: <BiUser style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.users,
          moduleKey: moduleKey.USER,
          // icon: React.createElement(UserOutlined),
          label: 'User',
        },
      ],
    },
    {
      key: `/event`,
      icon: React.createElement(ScheduleOutlined, { style: { fontSize: collapsed ? 18 : 14 } }),
      label: 'EVENT',
      type: 'group',
      children: [
        {
          icon: <FaUserGraduate style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.speaker,
          moduleKey: moduleKey.SPEAKER,
          label: 'Speaker',
        },
        {
          icon: <BsPinMap style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.venue,
          moduleKey: moduleKey.VENUE,
          label: 'Venue',
        },
        {
          icon: <MdOutlineCategory style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.even_category,
          moduleKey: moduleKey.EVENT_CATEGORY,
          label: 'Event Category',
        },
        {
          icon: <BsCalendar3 style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.event_data,
          moduleKey: moduleKey.EVENT_DATA,
          label: 'Event Data',
        },
        {
          icon: <BsCalendar4Range style={{ fontSize: collapsed ? 18 : 14 }} />,
          key: WebUrlData.event_activity,
          moduleKey: moduleKey.EVENT_ACTIVITY,
          label: 'Event Activity',
        },
      ],
    },
    {
      key: WebUrlData.report,
      moduleKey: moduleKey.REPORT,
      icon: React.createElement(FundOutlined, { style: { fontSize: collapsed ? 18 : 14 } }),
      label: 'Report',
      // children: [],
    },
    {
      label: 'Visit Site',
      key: WebUrlData.homepage,
      icon: React.createElement(FiExternalLink, { style: { fontSize: collapsed ? 18 : 14 } }),
    },
  ];

  const filterMenu = Menus.map((item) => {
    if (item?.moduleKey) {
      const access = getAccessRight(item?.moduleKey);
      if (!access?.view) return null;
    }
    return {
      ...item,
      children: item?.children
        ?.map((menu) => {
          if (isAdmin) return menu;
          const access = getAccessRight(menu?.moduleKey);
          if (access?.view) return menu;
          return null;
        })
        .filter(Boolean),
    };
  }).filter(Boolean);

  return (
    <Sider width={250} theme="dark" className="main-app-sidebar" collapsed={collapsed}>
      <div className="logo">
        <img src={Logo} height={collapsed ? 20 : 30} />
      </div>
      <div style={{ overflow: 'auto', maxHeight: '80%' }}>
        <Menu
          theme="dark"
          defaultSelectedKeys={[location]}
          defaultOpenKeys={[location.split('/').filter(Boolean)[0]]}
          mode="inline"
          style={{ borderRight: 0 }}
          items={filterMenu}
          onClick={(e) => navigate(e.key)}
        />
      </div>
    </Sider>
  );
}
