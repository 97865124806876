import React from 'react';
import { Button, ButtonProps } from 'antd';
import { AntdIconWrapper } from '@eigen3m/react-base-frontend';
import { Plus } from 'phosphor-react';

export function ButtonNewData(props: ButtonProps) {
  return (
    <Button
      icon={
        <AntdIconWrapper>
          <Plus />
        </AntdIconWrapper>
      }
      {...props}
    >
      New Data
    </Button>
  );
}
