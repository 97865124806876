import { DetailBuilder } from '@eigen3m/react-base-frontend';
import { Card, Form, Image } from 'antd';
import { typeOf } from 'mathjs';
import moment from 'moment';

export const DetailPayment = () => {
  const ASSET_URL = process.env.REACT_APP_BASE_ASSET_URL;
  return (
    <Card>
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                label: 'Destination Bank',
                name: ['detail_data', 'destination_bank'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  renderValue(value) {
                    return typeOf(value) === 'object' ? value?.name : value;
                  },
                  defaultRender: () => '-',
                },
              },
              {
                label: 'Account No',
                name: ['detail_data', 'bank_account'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                label: 'Payment Date',
                name: ['detail_data', 'payment_date'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  defaultRender: () => '-',
                  renderValue(value) {
                    return moment.unix(value).format('DD-MM-YYYY, HH:mm');
                  },
                },
              },
              {
                label: 'Payer Bank',
                name: ['detail_data', 'payment_type'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                label: 'Payer Account Name',
                name: ['detail_data', 'payer_name'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                label: 'Payer Account No.',
                name: ['detail_data', 'no_payer_account'],
                fieldType: 'text',
                gridColumn: { xl: 12 },
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                gridColumn: { xl: 12 },
                style: {
                  display: 'flex',
                },
                renderField(form) {
                  const imageUrl = form.getFieldValue(['detail_data', 'image_url']);
                  return (
                    <Image.PreviewGroup>
                      <Form.Item label="Proof Payment">
                        {imageUrl?.map((item, index) => {
                          const newImageUrl = !!item?.image_url ? ASSET_URL + item?.image_url : 'error';
                          return <Image key={index} width={100} src={newImageUrl} />;
                        })}
                      </Form.Item>
                    </Image.PreviewGroup>
                  );
                },
              },
            ],
          },
        ]}
      />
    </Card>
  );
};
