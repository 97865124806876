import React, { useEffect, useState } from 'react';
import { Form, notification, Spin, Tabs } from 'antd';
import FormEventInformation from './form-information';
import { FormSchedules } from './form-schedules';
import { FormParticipants } from './form-participants';
import { FormAttendanceOfficers } from './form-attendance-officers';
import { FormPaymentAndPricing } from './form-payment-and-pricing';
import { FormRegistration } from './form-registration';
import { DataTabKey } from '../../../helpers';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import {
  transformerGetDataAttendanceOfficer,
  transformerGetDataParticipant,
  transformerGetDataPaymentAndPricing,
  transformerGetDataRegistration,
  transformerGetDataSchedules,
} from '@pages/event/event-data/domain/transformer/transformer.get';
import { ApiUrlData } from '@base-configs';
const { TabPane } = Tabs;

interface Props {
  setFieldValue: any;
  getFieldValue: any;
}
export function FormTabs(props: Props) {
  const { setFieldValue } = props;
  const { id, dataSource, isUpdate, isDuplicate } = usePageProvider();
  const [activeKey, setActiveKey] = useState('1');
  const [forceRender, setForceRender] = useState(false);

  const onChange = (key: string) => {
    if (key) setActiveKey(key);
  };

  useEffect(() => {
    if (id) {
      handleGetDataDetailEventData(id);
    }
  }, [id]);

  const handleGetDataDetailEventData = async (id: string) => {
    setForceRender(false);
    //Get data participants
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/participants`,
      },
      onSuccess({ response }: any) {
        const participants = response ? transformerGetDataParticipant(response) : null;
        if (participants) {
          setFieldValue(DataTabKey.participants, participants);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data participant error',
          description: message,
        });
      },
    });

    //Get data schedules
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/schedules`,
      },
      onSuccess({ response }: any) {
        const schedules = response ? transformerGetDataSchedules(response) : null;
        if (schedules) {
          setFieldValue(DataTabKey.schedules, schedules);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data schedules error',
          description: message,
        });
      },
    });

    //Get data registrations
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/registrations`,
      },
      onSuccess({ response }: any) {
        const registrations = response ? transformerGetDataRegistration(response) : null;
        if (registrations) {
          setFieldValue(DataTabKey.registrations, registrations);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data registration error',
          description: message,
        });
      },
    });

    //Get data payment and pricings
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/payments`,
        params: {
          is_speaker: true,
        },
      },
      onSuccess({ response }: any) {
        const paymentPricings = response ? transformerGetDataPaymentAndPricing(response) : null;
        if (paymentPricings) {
          setFieldValue(DataTabKey.paymentPricings, paymentPricings);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data payment and pricings error',
          description: message,
        });
      },
    });

    //Get data attendance officer
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/attendances`,
      },
      onSuccess({ response }: any) {
        const attendancesOfficer = response ? transformerGetDataAttendanceOfficer(response) : null;
        if (attendancesOfficer) {
          setFieldValue(DataTabKey.attendanceOfficers, attendancesOfficer);
        }
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Get data attendance officer error',
          description: message,
        });
      },
    });
    setForceRender(true);
  };
  return (
    <React.Fragment>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const dataInformation = getFieldValue('information');
          const dataParticipant = getFieldValue('participants');
          const dataSchedule = getFieldValue('schedules');
          const dataRegistration = getFieldValue('registrations');
          const dataPricing = getFieldValue('payment_pricings');
          const registConfig = dataRegistration?.registration_config;

          return (
            <Spin spinning={isUpdate || isDuplicate ? !forceRender : false}>
              <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
                <TabPane tab="Event Information" key="1" forceRender>
                  <FormEventInformation />
                </TabPane>

                <TabPane tab="Participants" key="2" forceRender={forceRender}>
                  <FormParticipants />
                </TabPane>

                <TabPane tab="Schedules" key="3" forceRender={forceRender}>
                  <FormSchedules />
                </TabPane>

                <TabPane tab="Registration" key="4" forceRender={forceRender}>
                  <FormRegistration
                    dataInformation={dataInformation}
                    dataParticipant={dataParticipant}
                    dataSchedule={dataSchedule}
                    dataRegistration={dataRegistration}
                    setFieldValue={setFieldValue}
                  />
                </TabPane>

                <TabPane tab="Payment & Pricing" key="5" forceRender={forceRender}>
                  <FormPaymentAndPricing
                    dataRegistration={dataRegistration}
                    dataSchedule={dataSchedule}
                    dataParticipant={dataParticipant}
                    dataInformation={dataInformation}
                  />
                </TabPane>

                <TabPane tab="Attendance Officers" key="6" forceRender={forceRender}>
                  <FormAttendanceOfficers
                    dataSchedule={dataSchedule}
                    registConfig={registConfig}
                    dataPricing={dataPricing}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
