import { ApiUrlData } from '@base-configs';
import { FormBuilder, makeCommonDataSource, usePageProvider } from '@eigen3m/react-base-frontend';
import { Button, Card, Form, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import UploadImage from './upload-image';

interface Props {
  onFinish: (values: any) => void;
  form: any;
  dataGeneral?: any;
}

const ASSET_URL = process.env.REACT_APP_BASE_ASSET_URL;

export default function FormPayment(props: Props) {
  const { onFinish, form, dataGeneral } = props;
  const eventId = dataGeneral?.registrans?.[0]?.event_id;
  const [bankOptions, setBankOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const documentID = '12345';
  const { webUrl } = usePageProvider();
  const splitWebUrl = webUrl.split('/');
  const type = splitWebUrl[2];

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const dataSourceEvent = makeCommonDataSource({
    apiUrl: ApiUrlData.event,
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
  });

  async function getDataEvent(eventId) {
    try {
      await dataSourceEvent.handleCustomRequest({
        paramRequest: {
          baseURL: `${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.event}/${eventId}/payments`,
        },
        onSuccess({ response }: any) {
          const bankOptions = response?.banks?.map((item) => {
            return {
              label: item?.name,
              value: item,
            };
          });
          setBankOptions(bankOptions);
          const accountOptions = response?.accounts?.map((item) => {
            return {
              label: item?.account_no,
              value: item,
            };
          });
          setAccountOptions(accountOptions);
        },
        onFailed(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataEvent(eventId);
  }, [eventId]);

  const actions = [
    <div style={{ width: '100%', textAlign: 'right', paddingRight: '10px' }} key="submit">
      <Popover
        content={
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              size="small"
              danger={type === 'payment-confirmation'}
              onClick={hide}
              style={{ marginRight: '10px' }}
              disabled={!open}
            >
              Cancel
            </Button>
            <Button
              disabled={!open}
              size="small"
              danger={type === 'payment-cancellation'}
              ghost={type === 'payment-cancellation'}
              onClick={() => {
                form.submit();
                hide();
              }}
            >
              Yes
            </Button>
          </div>
        }
        title="Are you sure?"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button
          type={type === 'payment-confirmation' ? 'primary' : 'default'}
          danger={type === 'payment-cancellation'}
          key="submit"
          onClick={() => handleOpenChange(true)}
        >
          {type === 'payment-confirmation' ? 'Submit' : 'Cancel'}
        </Button>
      </Popover>
    </div>,
  ];
  return (
    <Card actions={actions}>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue();
          const imageUrl = values?.image_url;
          const onChangeDestinationBank = (value) => {
            setFieldsValue({
              bank_account: null,
            });
          };
          // console.log(values);
          const bankId = values?.destination_bank?.id;
          const hideForm = type !== 'payment-confirmation';
          return (
            <React.Fragment>
              <FormBuilder
                columns={[
                  {
                    fields: [
                      {
                        label: 'Destination Bank',
                        name: 'destination_bank',
                        gridColumn: { xs: 24, sm: 12, md: 8 },
                        fieldType: 'select',
                        rules: [
                          { required: type === 'payment-confirmation', message: 'Destination Bank is required.' },
                        ],
                        selectProps: {
                          options: bankOptions,
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          keyLabel: 'name',
                          onChange: onChangeDestinationBank,
                        },
                        // selectPaginateProps: {
                        //   dataSourceUrl: ApiUrlData.accounts,
                        //   baseDataSourceUrl: process.env.REACT_APP_BASE_SERVICE_URL,
                        //   classNamePrefix: 'react-select-custom-prefix-radius',
                        //   onChange: onChangeDestinationBank,
                        //   customLabel(row) {
                        //     return row?.bank?.name;
                        //   },
                        // },
                        hidden: hideForm,
                      },
                      {
                        label: 'Account No',
                        name: 'bank_account',
                        gridColumn: { xs: 24, sm: 12, md: 16 },
                        fieldType: 'select',
                        selectProps: {
                          options: accountOptions?.filter((item) => item?.value?.bank_id === bankId),
                          classNamePrefix: 'react-select-custom-prefix-radius',
                          keyLabel: 'account_no',
                        },
                        // renderField(form) {
                        //   const values = form.getFieldsValue();
                        //   const accountNumber = values?.bank_account;
                        //   return (
                        //     <Form.Item label="Account No." name="bank_account" hidden={hideForm}>
                        //       <span>{accountNumber ?? '-'}</span>
                        //     </Form.Item>
                        //   );
                        // },
                        hidden: hideForm,
                      },
                      {
                        label: 'Payment Date',
                        name: 'payment_date',
                        gridColumn: { xs: 24, sm: 12, md: 8 },
                        fieldType: 'datePicker',
                        rules: [{ required: type === 'payment-confirmation', message: 'Payment Date is required.' }],
                        datePickerProps: {
                          style: { width: '100%' },
                        },
                        hidden: hideForm,
                      },
                      {
                        label: 'Payer Bank',
                        name: 'payment_type',
                        gridColumn: { xs: 24, sm: 12, md: 16 },
                        fieldType: 'inputText',
                        rules: [{ required: type === 'payment-confirmation', message: 'Payment Bank is required.' }],
                        inputTextProps: {
                          placeholder: 'Input Payer Bank',
                        },
                        hidden: hideForm,
                      },
                      {
                        label: 'Payer Account Name',
                        name: 'payer_name',
                        gridColumn: { xs: 24, sm: 12, md: 8 },
                        fieldType: 'inputText',
                        rules: [
                          { required: type === 'payment-confirmation', message: 'Payer Account Name is required.' },
                        ],
                        inputTextProps: {
                          placeholder: 'Input Payer Name',
                        },
                        hidden: hideForm,
                      },
                      {
                        label: 'Payer Account No.',
                        name: 'no_payer_account',
                        gridColumn: { xs: 24, sm: 12, md: 16 },
                        fieldType: 'inputText',
                        rules: [
                          { required: type === 'payment-confirmation', message: 'Payer Account No. is required.' },
                        ],
                        inputTextProps: {
                          placeholder: 'Input Payer Account No.',
                        },
                        hidden: hideForm,
                      },
                    ],
                  },
                ]}
              />
              <Form.Item
                name="image_url"
                label="Upload Proof Payment"
                rules={[{ required: type === 'payment-confirmation', message: 'Upload Proof Payment is required.' }]}
                hidden={type !== 'payment-confirmation'}
              >
                <UploadImage />
              </Form.Item>
              <p
                style={{ width: '100%', textAlign: 'center', padding: '20px', fontSize: '20px' }}
                hidden={type !== 'payment-cancellation'}
              >
                ARE YOU SURE TO CANCEL YOUR REGISTRATION? WE LOVE TO MEET YOU
              </p>
            </React.Fragment>
          );
        }}
      </Form.Item>
    </Card>
  );
}
