import React, { useEffect, useState } from 'react';
import { DetailBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { Form, notification, Spin, Table, Tabs } from 'antd';
import { columnsDaySessionWorkshop, columnsPricing } from '../../../../helpers';
import AccountNumbersTable from './account-numbers.table';
import { transformerGetDataPaymentAndPricing } from '@pages/event/event-data/domain/transformer/transformer.get';
import { ApiUrlData } from '@base-configs';

interface Props {
  value?: any;
  onChange?: (value?: any) => void;
  dataSchedule?: any;
}
export function DetailPaymentAndPricing(props: Props) {
  const [form] = Form.useForm();
  const { id, dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);

  const handleGetDataPaymentAndPricing = async (id: string) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/payments`,
      },
      onSuccess(response: any) {
        const dataPaymentAndPricing = response?.response
          ? transformerGetDataPaymentAndPricing(response?.response)
          : null;
        form.setFieldsValue({ ...dataPaymentAndPricing });
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data payment and pricing error',
          description: response,
        });
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleGetDataPaymentAndPricing(id);
    }
  }, [id]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form form={form}>
          <DetailBuilder
            title="Payment & Pricing Configuration"
            columns={[
              {
                fields: [
                  {
                    gridColumn: { span: 24 },
                    label: 'Price Configuration',
                    name: ['price_configuration'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { span: 24 },
                    label: 'Payment Information',
                    name: ['payment_information'],
                    fieldType: 'text',
                    renderTextProps: {
                      renderValue(value) {
                        return value;
                      },
                      defaultRender: () => '-',
                    },
                  },
                  {
                    gridColumn: { xl: 10, lg: 10, md: 24 },
                    renderField(form) {
                      const accountNumbers = form.getFieldValue(['account_numbers'])?.length ?? 0;
                      return (
                        <Form.Item label="Account Numbers" name={['account_numbers']}>
                          {accountNumbers >= 1 ? <AccountNumbersTable /> : '-'}
                        </Form.Item>
                      );
                    },
                  },
                ],
              },
            ]}
          />

          <Form.Item shouldUpdate noStyle>
            {(form) => {
              const priceConfiguration = form.getFieldValue(['price_configuration'])?.toLowerCase();
              const isFreeEvent =
                !!priceConfiguration && priceConfiguration?.toLowerCase() === 'free event' ? true : false;
              const paymentMainPricings = form.getFieldValue('payment_pricing_datas') ?? [];
              const paymentSubPricings = form.getFieldValue('payment_pricing_subs') ?? [];
              const pricingSubRequired = form.getFieldValue('pricing_sub_required');
              return (
                !isFreeEvent && (
                  <Tabs>
                    <Tabs.TabPane tab="Main Pricing" key={1} forceRender>
                      <Table
                        dataSource={paymentMainPricings}
                        columns={columnsPricing}
                        size="small"
                        bordered
                        pagination={false}
                        expandable={{
                          rowExpandable: (record) => {
                            const daySessionWorkshop = record?.day_session_workshops ?? [];
                            return daySessionWorkshop?.length >= 1 ? true : false;
                          },
                          expandedRowRender: (record) => {
                            const daySessionWorkshop = record?.day_session_workshops ?? [];
                            return (
                              <Table
                                columns={columnsDaySessionWorkshop}
                                dataSource={daySessionWorkshop}
                                pagination={false}
                                size="small"
                                bordered
                              />
                            );
                          },
                        }}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Sub Pricing" key={2} forceRender>
                      <div className="mb-2">
                        <strong>Sub Pricing Required : </strong> {pricingSubRequired === 'true' ? 'Yes' : 'No'}
                      </div>
                      <Table
                        dataSource={paymentSubPricings}
                        columns={columnsPricing}
                        size="small"
                        bordered
                        pagination={false}
                        expandable={{
                          rowExpandable: (record) => {
                            const daySessionWorkshop = record?.day_session_workshops ?? [];
                            return daySessionWorkshop?.length >= 1 ? true : false;
                          },
                          expandedRowRender: (record) => {
                            const daySessionWorkshop = record?.day_session_workshops ?? [];
                            return (
                              <Table
                                columns={columnsDaySessionWorkshop}
                                dataSource={daySessionWorkshop}
                                pagination={false}
                                size="small"
                                bordered
                              />
                            );
                          },
                        }}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                )
              );
            }}
          </Form.Item>
        </Form>
      </Spin>
    </React.Fragment>
  );
}
