import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { EventCategoryEntity } from '../../domain/entities';
import { makeEventCategoryTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeEventCategoryTransformer<EventCategoryEntity>();
  const InitialValue: InitialValuePageProviderEntity<EventCategoryEntity> = {
    transformer,
    moduleKey: 'event-categories',
    webUrl: WebUrlData.even_category,
    apiUrl: ApiUrlData.eventCategory,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
