import { ActionProps, IndexPageWrapper, SinglePage, usePageProvider } from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import { columnsEventCategory } from '../helpers';
import { mockCompanies } from '@states';
import { useRecoilState } from 'recoil';
import { EventCategoryForm } from '../components/event-category.form';
import EventCategoryDetail from '../components/event-category.detail';
import { Card } from 'antd';
import { useAccessRight } from 'shared/hooks';

export default function IndexPage() {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const { dataSource } = usePageProvider();
  const [companies] = useRecoilState(mockCompanies);

  const { moduleKey } = usePageProvider();
  const { accessCreate, accessEdit, accessImport, accessChangeStatus, accessDelete, accessView, accessPrint } =
    useAccessRight({
      moduleKey,
    });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  async function handleAfterSave(response: any, configs) {
    await handleConfirmProcess(response, configs);
  }

  async function handleConfirmProcess(payload, configs): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: () => {
        if (configs?.onReloadTable) configs?.onReloadTable();
        if (!configs?.isCreateAnother) if (configs.onClose) configs.onClose(undefined);
        if (configs?.form) configs.form.resetFields();
      },
      onFailed: () => {
        console.log('failed');
      },
    });
  }

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Event Category',
        handleClickNewData() {
          onShowDrawerForm('create');
        },
        showFilter: false,
        showBack: false,
        showConfirmProcess: false,

        disablePrint: !accessPrint,
        disableImport: !accessImport,
        disableNewData: !accessCreate,

        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
      }}
    >
      <Card>
        <SinglePage
          autoConfirmAfterAction={true}
          drawerFormProps={{
            title: 'Event Category',
            action: actionForm,
            visible: visibleDrawerForm,
            onClose: onCloseDrawerForm,
            forceRequest: true,
            children: <EventCategoryForm />,
            onShow: onShowDrawerForm,
            handleAfterSave: handleAfterSave,
            initialValues: { company: !!companies?.length ? companies[0] : null },
            showDataTypeSelector: false,
          }}
          dataTableProps={{
            columns: columnsEventCategory,
            rowActionProps: {
              actionIgnored: actionIgnored,
              customActionIgnored: () => ['confirm_process'],
            },
          }}
          drawerDetailProps={{
            title: 'Event Category',
            visible: visibleDrawerDetail,
            onClose: onCloseDrawerDetail,
            // forceRequest: true,
            children: <EventCategoryDetail />,
            onShow: onShowDrawerDetail,
            showDataTypeSelector: false,
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
