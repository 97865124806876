import { omit } from 'lodash';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import { ParticipantAttendanceEntity } from '../entities';
import moment from 'moment';
import { convertEpochToMoment } from '@pages/event/event-data/domain/transformer/helpers';

export class ParticipantAttendanceTransformer<
  E extends BaseEntity = ParticipantAttendanceEntity,
> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = {
      ...omit(payload, ['form_action']),
      check_in_date: payload?.check_in_date ? moment(payload?.check_in_date)?.valueOf() : null,
    };
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    const cleanVal = {
      ...payload,
      check_in_date: payload?.check_in_date ? convertEpochToMoment({ date: payload?.check_in_date }) : moment(),
    };
    return cleanVal;
  }

  transformerGetIndex(payload: E[]): E[] {
    return payload;
  }
}
