import { ApiUrlData } from '@base-configs';
import { Card, Col, Row } from 'antd';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { useDataIndex } from '@pages/event/event-activity/presentation/hooks/useDataIndex';

export function RegistrantSummary(): JSX.Element {
  const { id } = usePageProvider();

  const { data } = useDataIndex(id, ApiUrlData.eventActivities + '/summary');

  const grid = { xxl: 4, xl: 4, lg: 4, md: 6, sm: 12, xs: 24 };

  return (
    <Card style={{ marginBottom: 20 }}>
      {data ? (
        data?.map((registran) => (
          <Row key={registran?.id}>
            <Col {...grid}>
              <h3># Registrants</h3>
              <span>{registran?.total_registran ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Pending</h3>
              <span>{registran?.total_pending_participant ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Active</h3>
              <span>{registran?.total_active_participant ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Cancel</h3>
              <span>{registran?.total_cancel_participant ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Waiting</h3>
              <span>{registran?.total_waiting_participant ?? '0'}</span>
            </Col>
            <Col {...grid}>
              <h3># Closed</h3>
              <span>{registran?.total_close_participant ?? '0'}</span>
            </Col>
          </Row>
        ))
      ) : (
        <h2 style={{ textAlign: 'center' }}>No Data</h2>
      )}
    </Card>
  );
}
