import { AntdIconWrapper, FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Button, Card, Form, Modal, Popconfirm, Tabs, Tooltip } from 'antd';
import { useState } from 'react';
import { RiMailSendLine } from 'react-icons/ri';
import { AiOutlineFieldTime } from 'react-icons/ai';

import {
  TabInformation,
  TabOfficerAttendance,
  TabParticipant,
  TabParticipantAttendance,
  TabPayment,
} from '../components/form-tabs';
import { ApiUrlData, subModuleKey } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';

const defaultButtonStyle = {
  marginLeft: '12px',
  marginRight: '12px',
  fontSize: '12px',
};

export default function FormPage(): React.ReactNode {
  const { isCreate, isUpdate, isDuplicate, dataSource, id, moduleKey } = usePageProvider();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';

  const [, setTabs] = useState('');

  function onChangeTab(key: string) {
    setTabs(key);
  }

  async function blastEmail(): Promise<void> {
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.send_promo_email + `/${id}`,
      },
      onSuccess: ({ response }: any) => {
        console.log(response);
        Modal.success({
          mask: true,
          title: 'Sending Success',
          content: response,
        });
      },
      onFailed: ({ message }: any) => {
        console.log(message);
        Modal.error({
          mask: true,
          title: 'Error when sending',
          content: message,
        });
      },
    });
  }

  async function reminderEmail(): Promise<void> {
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.reminder_email,
        data: {
          event_ids: id ? [id] : [],
        },
      },
      onSuccess: ({ response }: any) => {
        Modal.success({
          mask: true,
          title: 'Success',
          content: response,
        });
      },
      onFailed: ({ message }: any) => {
        Modal.error({
          mask: true,
          title: 'Error',
          content: message,
        });
      },
    });
  }

  function AdditionalToolbar(): JSX.Element {
    return (
      <>
        <Tooltip placement="bottomRight" title="Reminder Email">
          <Popconfirm placement="topLeft" title="Reminder Email" onConfirm={reminderEmail}>
            <Button
              type="text"
              shape="circle"
              style={defaultButtonStyle}
              icon={
                <AntdIconWrapper>
                  <AiOutlineFieldTime />
                </AntdIconWrapper>
              }
            ></Button>
          </Popconfirm>
        </Tooltip>

        <Tooltip placement="bottomRight" title="Blast Email">
          <Popconfirm placement="topLeft" title="Blast Email" onConfirm={blastEmail}>
            <Button
              type="text"
              shape="circle"
              style={defaultButtonStyle}
              icon={
                <AntdIconWrapper>
                  <RiMailSendLine />
                </AntdIconWrapper>
              }
            ></Button>
          </Popconfirm>
        </Tooltip>
      </>
    );
  }

  const accessRight = getAccessRight(moduleKey);
  const showPayment = accessRight[subModuleKey.event_activity.PAYMENT]?.view;

  return (
    <FormPageWrapper
      pageToolbarProps={{
        showDataTypeSelector: false,
        pageTitle: `${title} Event Activity`,
        // showTagStatus: false,
        showSave: false,
        buttonRightContent: <AdditionalToolbar />,
        // showBack: false,
      }}
    >
      <Form.Item name="id" noStyle />
      <Form.Item name="information" noStyle />
      <Form.Item name="participants" noStyle />
      <Form.Item name="schedules" noStyle />
      <Form.Item name="registrations" noStyle />
      <Form.Item name="payment_pricings" noStyle />
      <Form.Item name="attendance_pricings" noStyle />
      <Form.Item shouldUpdate noStyle>
        {() => {
          return (
            <Card>
              <Tabs onChange={onChangeTab} defaultActiveKey="information" destroyInactiveTabPane>
                <Tabs.TabPane tab="Information" key="information">
                  <TabInformation />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Participants" key="participants" destroyInactiveTabPane>
                  <TabParticipant />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Payment" key="payment" disabled={!showPayment} destroyInactiveTabPane>
                  <TabPayment />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Participant Attendance" key="participant_attendance" destroyInactiveTabPane>
                  <TabParticipantAttendance />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Officer Attendance" key="officer_attendance" destroyInactiveTabPane>
                  <TabOfficerAttendance />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          );
        }}
      </Form.Item>
    </FormPageWrapper>
  );
}
