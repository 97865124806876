import React, { useState } from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { Checkbox, Form, FormInstance } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { Maps } from '@components/maps';

export default function FormEventInformation() {
  const [documentId, setDocumentId] = useState(uuidv4());
  const [imageUrl, setImageUrl] = useState(null);

  return (
    <React.Fragment>
      <Form.Item name={['information', 'id']} hidden noStyle />
      <Form.Item name={['information', 'department_class_id']} hidden noStyle />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldValue, getFieldValue }) => {
          const values = getFieldsValue();
          const disableEventCategory = values?.event_category && !values?.event_category?.show_to_home;
          const mainBannerOptions = [
            { label: 'Homepage', value: 'Homepage' },
            { label: 'Event Category', value: 'Event Category', disabled: disableEventCategory },
          ];
          return (
            <FormBuilder
              columns={[
                {
                  fields: [
                    {
                      gridColumn: { span: 24 },
                      customHidden: () => true,
                      renderField(form) {
                        const currentDocumentId = form.getFieldValue(['information', 'document_id']);
                        if (currentDocumentId) setDocumentId(currentDocumentId);
                        return <Form.Item name={['information', 'document_id']} noStyle hidden />;
                      },
                    },
                    {
                      label: 'Event Category',
                      gridColumn: { xl: 6, lg: 6, md: 8, sm: 24, xs: 24 },
                      name: ['information', 'event_category'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.eventCategory,
                        customLabel(row) {
                          return row?.name;
                        },
                        customFilterRequest() {
                          return { statuses: ['active'] };
                        },
                        keySearch: 'name',
                      },
                    },
                    {
                      gridColumn: { xl: 18, lg: 18, md: 8, sm: 24, xs: 24 },
                      renderField() {
                        return (
                          <Form.Item label="Main Banner" name={['information', 'main_banner']}>
                            <Checkbox.Group options={mainBannerOptions} />
                          </Form.Item>
                        );
                      },
                    },
                    {
                      label: 'Event Name',
                      gridColumn: { xl: 10, lg: 10, md: 24, sm: 24, xs: 24 },
                      name: ['information', 'name'],
                      fieldType: 'inputText',
                      rules: [{ required: true, message: 'Event Name is required.' }],
                    },
                    {
                      label: 'Banner',
                      gridColumn: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                      name: ['information', 'banner_url'],
                      rules: [
                        {
                          validator(_, value) {
                            const currentDocumentId = getFieldValue(['information', 'document_id']);
                            const newDocumentId = currentDocumentId ?? documentId;
                            if (value && newDocumentId) {
                              setFieldValue(['information', 'document_id'], newDocumentId);
                              const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;
                              const imageUrl = assetUrl && value ? assetUrl + value : null;
                              setImageUrl(imageUrl);
                            } else {
                              setImageUrl(null);
                            }
                            return Promise.resolve();
                          },
                        },
                        { required: true, message: 'Banner is required' },
                      ],
                      fieldType: 'uploadImage',
                      valuePropName: 'imageUrl',
                      uploadImageProps: {
                        className: 'custom-upload-antd',
                        requestUrl: ApiUrlData.eventDataUpload,
                        imageSize: 0.7,
                        errorSizeMessage: 'Maximum size is 700kB!',
                        requestFileKey: 'document_url',
                        imageType: ['image/jpeg', 'image/png'],
                        transformPayloadRequest(payload) {
                          const formData = new FormData();
                          formData.append('document_url', payload);
                          formData.append('document_id', documentId);
                          return formData;
                        },
                        imageUrl: imageUrl,
                        style: { width: 700 },
                      },
                    },
                    {
                      label: 'Event Type',
                      gridColumn: { xl: 4, lg: 4, md: 6, sm: 24, xs: 24 },
                      name: ['information', 'event_type'],
                      fieldType: 'selectPaginate',
                      rules: [{ required: true, message: 'Event Type is required.' }],
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.enum_event_type,
                        customLabel(row) {
                          return row;
                        },
                      },
                    },
                    {
                      label: 'Venue Name',
                      gridColumn: { xl: 6, lg: 6, md: 8, sm: 24, xs: 24 },
                      name: ['information', 'venue'],
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        classNamePrefix: 'react-select-custom-prefix-radius',
                        dataSourceUrl: ApiUrlData.venues,
                        customLabel(row) {
                          return row?.name;
                        },
                        keySearch: 'name',
                        customFilterRequest() {
                          return { statuses: ['active'] };
                        },
                      },
                      rules: [
                        ({ getFieldValue }) => ({
                          required:
                            getFieldValue(['information', 'event_type'])?.toLowerCase() === 'on site' ? true : false,
                          message: 'Venue Name is required',
                        }),
                      ],
                      customHidden(form: FormInstance) {
                        const eventType = form.getFieldValue(['information', 'event_type']);
                        if (!!eventType && eventType.toLowerCase() === 'on site') {
                          return false;
                        } else {
                          return true;
                        }
                      },
                    },
                    {
                      gridColumn: { span: 24 },
                      customHidden(form: FormInstance) {
                        const eventType = form.getFieldValue(['information', 'event_type']);
                        const venue = form.getFieldValue(['information', 'venue']);
                        if (!!eventType && eventType.toLowerCase() === 'on site' && !!venue) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                      renderField(form) {
                        const address = form.getFieldValue(['information', 'venue', 'address']);
                        const addressDescription = form.getFieldValue(['information', 'venue', 'address_description']);
                        return <Maps value={address} addressDescription={addressDescription} />;
                      },
                    },
                    {
                      label: 'Event Description',
                      gridColumn: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                      name: ['information', 'description'],
                      fieldType: 'textEditorDraftWysiwyg',
                      textEditorDraftWysiwygProps: {
                        ignoreToolbarOptionsInline: ['strikethrough', 'subscript', 'superscript', 'monospace'],
                        ignoreToolbarOptions: [
                          'image',
                          'fontFamily',
                          'fontSize',
                          'colorPicker',
                          'colorPicker',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                          'link',
                          'remove',
                        ],
                      },
                    },
                    {
                      label: 'Terms & Conditions',
                      gridColumn: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                      name: ['information', 'term_and_conditions'],
                      fieldType: 'textEditorDraftWysiwyg',
                      textEditorDraftWysiwygProps: {
                        ignoreToolbarOptionsInline: ['strikethrough', 'subscript', 'superscript', 'monospace'],
                        ignoreToolbarOptions: [
                          'image',
                          'fontFamily',
                          'fontSize',
                          'colorPicker',
                          'colorPicker',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                          'link',
                          'remove',
                        ],
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>
    </React.Fragment>
  );
}
