import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

import { EventActivityEntity } from '../../domain/entities';
import { makeEventActivityTransformer } from '../../domain/factories';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeEventActivityTransformer<EventActivityEntity>();
  const InitialValue: InitialValuePageProviderEntity<EventActivityEntity> = {
    transformer,
    moduleKey: moduleKey.EVENT_ACTIVITY,
    webUrl: WebUrlData.event_activity,
    apiUrl: ApiUrlData.eventActivities,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageForm}
        // PageCreate={PageForm}
        PageUpdate={PageForm}
        // PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
