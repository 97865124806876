import { EventActivitiesDetail } from '@components/event-activities-detail';
import { Col, Form, Row } from 'antd';
import RegistrationForm from '@components/registration-form';
import { transformerGetDataRegistration } from '@pages/event/event-data/domain/transformer/transformer.get';
import { usePageProvider } from '@eigen3m/react-base-frontend';

export const TabInformation = (): JSX.Element => {
  const { id } = usePageProvider();

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldsValue }) => {
        const values = getFieldsValue();
        const payment_pricings = values?.payment_pricings;
        const participants = values?.participants;

        return (
          <Row gutter={24}>
            <Col xl={12} lg={12} md={24} sm={24} style={{ borderRight: '1px solid #fafafa' }}>
              <EventActivitiesDetail value={values} showBanner showName />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <RegistrationForm
                payments={payment_pricings}
                participants={participants}
                registration={transformerGetDataRegistration(values?.registrations)}
                id={id}
                withOnlyAdmin={false}
              />
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};
