import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { SpeakerEntity } from '../../domain/entities';
import { makeSpeakerTransformer } from '../../domain/factories';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeSpeakerTransformer<SpeakerEntity>();
  const InitialValue: InitialValuePageProviderEntity<SpeakerEntity> = {
    transformer,
    moduleKey: moduleKey.SPEAKER,
    webUrl: WebUrlData.speaker,
    apiUrl: ApiUrlData.speaker,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
