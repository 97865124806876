import React from 'react';
import { Card, Tag as AntdTag } from 'antd';
import { AntdIconWrapper } from '@eigen3m/react-base-frontend';
import './event-card-list.less';
import { makeAssetUrl, parseEpochToMoment } from '@helpers';

import { currencyFormat, reduceText } from '@components/post-card/helpers';
import { Calendar, MapPin, Tag } from 'phosphor-react';
import parse from 'html-react-parser';

export function EventCardList({ event, onClick }) {
  const startDate = event?.schedules?.date_start;
  const endDate = event?.schedules?.date_end;

  const realStartDate = parseEpochToMoment(startDate)?.format('ll');
  const realEndData = parseEpochToMoment(endDate)?.format('ll');

  const title = event?.information?.name;
  const imageUrl = event?.information?.banner_url;
  const eventType = event?.information?.event_type;
  const description = event?.information?.description;

  const venueName = event?.information?.venue?.name;
  const startPrice = event?.payment_pricings?.lowest_price ?? event?.payment_pricings?.highest_price;
  const category = event?.information?.event_category?.name;
  const ageMax = event?.participants?.age_max;
  const ageMin = event?.participants?.age_min;

  function makeStringDate(startDate, endDate) {
    if (!startDate) return '-';
    else if (startDate === endDate) return startDate;
    return `${startDate} - ${endDate}`;
  }

  return (
    <Card style={{ marginBottom: '20px' }} onClick={onClick} hoverable className="event-card-list">
      <div className="imgCardZoom event-card-image">
        <img
          alt={event?.information?.name ?? '-'}
          title={event?.information?.name ?? '-'}
          style={{
            minHeight: '250px',
            height: '250px',
            width: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
          src={makeAssetUrl(imageUrl)}
        />
      </div>
      <div className="event-card-content">
        <div className="event-name">{title} </div>
        <div className="event-item-wrapper">
          <div>
            <AntdIconWrapper>
              <Calendar style={{ fontSize: 16, marginRight: 5 }} />
            </AntdIconWrapper>
          </div>
          <div className="event-items">{makeStringDate(realStartDate, realEndData)}</div>
        </div>
        <div style={{ marginBottom: 10 }}></div>
        <div className="event-item-wrapper">
          <AntdIconWrapper>
            <MapPin style={{ fontSize: 16, marginRight: 5 }} />
          </AntdIconWrapper>
          <strong>{eventType ?? '-'}</strong>
        </div>
        <div className="event-item-wrapper">
          {!!eventType && eventType?.toLowerCase() === 'on site' && (
            <div style={{ marginLeft: '18px' }}>{venueName ? reduceText(venueName) : '-'}</div>
          )}
        </div>
        <div style={{ marginBottom: 10 }}></div>
        <div className="event-item-wrapper">
          <AntdIconWrapper>
            <Tag style={{ fontSize: 16, marginRight: 5 }} />
          </AntdIconWrapper>
          <strong>{!!startPrice && startPrice !== 0 ? `Start from ${currencyFormat(startPrice)}` : 'Free'}</strong>
        </div>
        <div style={{ marginBottom: 10 }}></div>
        {description && (
          <div className="event-item-wrapper">
            <p style={{ color: 'black' }}>{description ? parse(reduceText(description, 250)) : '-'}</p>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flex: 'flex-wrap',
            flexDirection: 'row',
          }}
        >
          {!!category && <AntdTag style={{ padding: '4px 8px', marginRight: '10px' }}>{category}</AntdTag>}
          {!!ageMax && !!ageMin ? (
            <AntdTag style={{ padding: '4px 8px', marginRight: '10px' }}>
              {ageMin} - {ageMax} years old
            </AntdTag>
          ) : (
            (ageMax && (
              <AntdTag style={{ padding: '4px 8px', marginRight: '10px' }}>below {ageMax} years old</AntdTag>
            )) ??
            (ageMin && <AntdTag style={{ padding: '4px 8px', marginRight: '10px' }}>above {ageMin} years old</AntdTag>)
          )}
        </div>
      </div>
    </Card>
  );
}
