import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';

import { BankEntity } from '../../domain/entities';
import { makeBankTransformer } from '../../domain/factories';

import PageIndex from '../pages';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeBankTransformer<BankEntity>();
  const InitialValue: InitialValuePageProviderEntity<BankEntity> = {
    transformer,
    moduleKey: moduleKey.BANK_ACCOUNT,
    webUrl: WebUrlData.bank_accounts,
    apiUrl: ApiUrlData.banks,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;
  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
