import { ApiUrlData, subModuleKey, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity, PageProvider, usePageProvider } from '@eigen3m/react-base-frontend';
import { ParticipantAttendanceEntity } from '@pages/event/event-activity/domain/entities';
import { makeParticipantAttendanceTransformer } from '@pages/event/event-activity/domain/factories/participant-attendance-factory';
import IndexTable from './components/index-table';

export const TabParticipantAttendance = (): JSX.Element => {
  const { id } = usePageProvider();
  const defaultFiler = { event_ids: id ? [id] : [], statuses: undefined };
  const transformer = makeParticipantAttendanceTransformer<any>(defaultFiler);
  const InitialValue: InitialValuePageProviderEntity<ParticipantAttendanceEntity> = {
    transformer: transformer,
    moduleKey: subModuleKey.event_activity.PARTICIPANT_ATTENDANCE,
    webUrl: WebUrlData.event_activity,
    apiUrl: ApiUrlData.participant_attandance,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <IndexTable />
    </PageProvider>
  );
};
