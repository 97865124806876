import { ApiUrlData, WebUrlData } from '@base-configs';
import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import { PaymentConfirmationEntity } from '../../domain/entities';

import PageIndex from '../pages';

export default function FactoryPage() {
  const pathName = window.location.pathname;
  const splitModuleUrl = pathName.split('/');
  const moduleUrl = splitModuleUrl[2];
  const InitialValue: InitialValuePageProviderEntity<PaymentConfirmationEntity> = {
    moduleKey: 'data-event-activity-page',
    webUrl: moduleUrl === 'payment-cancellation' ? WebUrlData.payment_cancellation : WebUrlData.payment_confirmation,
    apiUrl: ApiUrlData.payment,
    baseApiUrl: process.env.REACT_APP_BASE_SERVICE_URL,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
