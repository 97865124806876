import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Card, Input, Pagination, Skeleton, Button, Layout, Typography, Empty } from 'antd';
import { makeCommonDataSource, notificationFailed, usePageProvider } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { MagnifyingGlass } from 'phosphor-react';
import { CarouselEvent } from '@components/carousel';
import { PostCard } from '@components/post-card';
import { useNavigate } from 'react-router-dom';
import { Banner } from '@components/banner';
import { EventCard } from '@components/event-card';
import { EventCardList } from '@components/event-card-list';
import { CalendarOutlined } from '@ant-design/icons';
import { SkeletonEventCard } from '@components/skeleton-event-card';

const { Title } = Typography;
const { useBreakpoint } = Grid;

export default function IndexPage() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const screen = useBreakpoint();
  const isDesktop = !!screen.xxl || !!screen.xl || !!screen.lg ? true : false;

  const navigate = useNavigate();

  const eventDataSource = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.eventActivities + '/menu',
  });

  async function handleGetDataEvent({ page, search }) {
    setLoading(true);
    await eventDataSource.handleGetIndex({
      params: {
        page,
        search,
        limit: 15,
        statuses: ['active', 'in_process'],
        public_event: true,
      },
      onSuccess({ response }: any) {
        const items = response?.items;
        const totalPage = response?.meta?.totalPages;
        const currentPage = response?.meta?.currentPage;
        setTotalPage(totalPage);
        setCurrentPage(currentPage);
        if (page === 1) setData(items);
        else {
          setData((prev) => {
            return [...prev, ...items];
          });
        }
        setLoading(false);
      },
      onFailed(params) {
        setLoading(false);
        console.log({ params });
      },
    });
  }

  useEffect(() => {
    handleGetDataEvent({ page: 1, search });
  }, []);

  function SkeletonCard(): any {
    return Array.from({ length: 7 }, (_, index) => {
      return <SkeletonEventCard key={index} />;
    });
  }

  return (
    <React.Fragment>
      <Banner type="homepage" />
      <Layout>
        {loading ? (
          <>
            <div style={{ marginBottom: 50 }}></div>
            <div className="wrapper-landing-page">
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 15,
                  justifyContent: 'center',
                  overflow: 'auto',
                  padding: `20px 10px 20px 10px`,
                }}
              >
                <SkeletonCard />
              </div>
            </div>
            <div style={{ marginBottom: 50 }}></div>
          </>
        ) : data?.length >= 1 ? (
          <>
            <div style={{ marginBottom: 50 }}></div>
            <div className="wrapper-landing-page">
              <div
                style={
                  data?.length === 1 && isDesktop
                    ? {
                        width: '100%',
                        display: 'block',
                        overflow: 'auto',
                        padding: 0,
                      }
                    : {
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 15,
                        justifyContent: 'center',
                        overflow: 'auto',
                        padding: `20px 10px 20px 10px`,
                      }
                }
              >
                {data?.length === 1 && isDesktop
                  ? data?.map((item, index) => {
                      return <EventCardList onClick={() => navigate(`/event/${item.id}`)} event={item} key={index} />;
                    })
                  : data?.map((item, index) => {
                      return <EventCard onClick={() => navigate(`/event/${item.id}`)} event={item} key={index} />;
                    })}
              </div>
              <div style={{ height: 30 }}></div>
              {currentPage < totalPage && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    size="large"
                    // type="dashed"
                    loading={loading}
                    style={{ width: 350 }}
                    onClick={() => handleGetDataEvent({ page: currentPage + 1, search })}
                  >
                    Load More
                  </Button>
                </div>
              )}
            </div>
            <div style={{ marginBottom: 50 }}></div>
          </>
        ) : (
          <Empty
            description={
              <>
                <Title level={4}>No Event</Title>
                <p>No events found. Wait for a fun event from us.</p>
              </>
            }
            image={<CalendarOutlined style={{ fontSize: 90, color: '#DCE0E6' }} />}
            style={{ marginTop: '20px' }}
          />
        )}
      </Layout>
    </React.Fragment>
  );
}
