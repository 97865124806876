import React from 'react';
import { dateFormatter, RenderCurrency } from '@eigen3m/react-base-frontend';
import { renderRegisteredSchedule } from '@helpers';
import { Button, Card, Col, Descriptions, Form, Row } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { typeOf } from 'mathjs';

export default function InvoiceInformation() {
  const dateNow = moment().unix();
  const navigate = useNavigate();

  return (
    <Card>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          const data = values?.detail_data;
          return (
            <React.Fragment>
              <Descriptions title={`Invoice ID: ${data?.code ?? '-'}`} layout="horizontal" size="small">
                <Descriptions.Item label="Registration Date" span={24}>
                  {data?.registrans?.[0]?.registration_date
                    ? dateFormatter(data?.registrans?.[0]?.registration_date).parseEpoch('DD MMM YYYY, HH:mm')
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Participant's Email" span={24}>
                  {data?.registrans?.[0]?.email ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Confirmation Expired" span={24}>
                  {data?.expired_date ? dateFormatter(data?.expired_date).parseEpoch('DD MMM YYYY, HH:mm') : '-'}
                  <span style={{ color: 'red' }}>{data?.status === 'closed' && '(Expired)'}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Payment Status" span={24}>
                  {typeOf(data?.status) === 'string' ? data?.status?.toUpperCase() : '-'}
                </Descriptions.Item>
              </Descriptions>
              {data?.registrans?.map((detail, index) => {
                return (
                  <div key={`detail-${index}`} style={{ marginBottom: '10px' }}>
                    <Descriptions
                      title={index === 0 ? `Invoice Detail` : ''}
                      layout="horizontal"
                      key={index}
                      colon={true}
                    >
                      <Descriptions.Item
                        label={`Participant ${index + 1}`}
                        span={24}
                        labelStyle={{ fontWeight: 'bold' }}
                      >
                        <span style={{ fontWeight: 'bold' }}>{detail?.name ?? '-'}</span>
                      </Descriptions.Item>
                    </Descriptions>
                    {detail?.participant?.map((day, index) => {
                      const information = day?.registered_schedule;
                      const dayInfo = information?.day;
                      const session =
                        typeof information?.session === 'string' || typeof information?.session === 'number'
                          ? information?.session
                          : '-';
                      const workshop = information?.workshop;
                      return (
                        <Row key={`day-${index}`}>
                          <Col lg={12} md={24} sm={12} xs={24}>
                            {/* {information?.session?.name ?? ''} */}
                            {renderRegisteredSchedule({ value: information })}
                            {/* Day {dayInfo ?? '-'}, Session {session ?? '-'}, {workshop ?? '-'} */}
                          </Col>
                          <Col
                            lg={12}
                            md={24}
                            sm={12}
                            xs={24}
                            style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end' }}
                          >
                            Rp. {information?.price?.price ? RenderCurrency({ value: information?.price?.price }) : 0}
                            {information?.price?.name ? ` (${information?.price?.name})` : ''}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              })}
              <hr />
              <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end' }}>
                Rp. {data?.total_invoice ? RenderCurrency({ value: data?.total_invoice }) : 0}
              </div>
              {data?.expired_date > dateNow && (
                <div style={{ marginTop: 30 }}>
                  <Button type="primary" onClick={() => navigate('/')}>
                    Back to Home
                  </Button>
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Form.Item>
    </Card>
  );
}
