import { ApiUrlData } from '@base-configs';
import { Card, Col, Row } from 'antd';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { useDataIndex } from '@pages/event/event-activity/presentation/hooks/useDataIndex';

export function OfficerSummary(): JSX.Element {
  const { id } = usePageProvider();

  const { data } = useDataIndex(id, ApiUrlData.eventActivities + '/summary');

  return (
    <Card style={{ marginBottom: 30 }}>
      {data ? (
        data?.map((officer) => (
          <Row key={officer?.id}>
            {/* <Col span={5}>
              <h3># Active Officer</h3>
              <span>{officer?.total_active_officer ?? '-'}</span>
            </Col> */}
            <Col span={8}>
              <h3># Checked In</h3>
              <span>{officer?.total_check_in_officer ?? '-'}</span>
            </Col>
            <Col span={8}>
              <h3># Waiting</h3>
              <span>{officer?.total_waiting_officer ?? '-'}</span>
            </Col>
            <Col span={8}>
              <h3># Not Come</h3>
              <span>{officer?.total_not_come_officer ?? '-'}</span>
            </Col>
          </Row>
        ))
      ) : (
        <h2 style={{ textAlign: 'center' }}>No Data</h2>
      )}
    </Card>
  );
}
