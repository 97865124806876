// import { columnsDaySessionWorkshop, columnsPricing } from '@pages/event/event-data/presentation/helpers';
// import { Col, Image, Row, Table } from 'antd';
import { PricingCard } from '..';
import BankAccountList from './bank-account-list';
import { PricingList } from './pricing-lists';
// import PricingTable from './pricing-table';

// const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;

export default function PaymentAndPricings({ value }: any) {
  const description = value?.description;
  const mainPricings = value?.payment_pricing_datas ?? [];
  const subPricings = value?.payment_pricing_subs ?? [];
  const accountNumbers = value?.account_numbers ?? [];

  return (
    <>
      {!!description && <p>{description}</p>}
      {!!mainPricings?.length && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit,minmax(200px, 1fr))',
            gap: 15,
            overflow: 'auto',
          }}
        >
          {mainPricings?.map((item, key) => {
            const { price, regist_condition: condition, name: type, day_session = [] } = item;
            const pricingLists = [];
            const schedules = day_session?.length >= 1 ? day_session : [];

            if (schedules?.length >= 1) {
              schedules?.forEach((x) => {
                const { day, session } = x;
                const workshops = x?.workshop ?? [];
                if (workshops?.length >= 1) {
                  workshops?.forEach((y) => {
                    const schedule = `Day ${day}, Session ${session}, ${y}`;
                    pricingLists?.push(schedule);
                  });
                } else {
                  const schedule = !!session ? `Day ${day}, Session ${session}` : `Day ${day}`;
                  pricingLists.push(schedule);
                }
              });
            } else {
              pricingLists.push('All Event');
            }
            return <PricingCard key={key} condition={condition} price={price} pricingList={pricingLists} type={type} />;
          })}
        </div>
      )}
      {subPricings?.length >= 1 && (
        <>
          <h3 style={{ marginTop: '20px' }}>Sub Event</h3>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit,minmax(200px, 1fr))',
              gap: 15,
              overflow: 'auto',
            }}
          >
            {subPricings?.map((item, key) => {
              const { price, regist_condition: condition, name: type, day_session = [] } = item;
              const pricingLists = [];
              const schedules = day_session?.length >= 1 ? day_session : [];

              if (schedules?.length >= 1) {
                schedules?.forEach((x) => {
                  const { day, session } = x;
                  const workshops = x?.workshop ?? [];
                  if (workshops?.length >= 1) {
                    workshops?.forEach((y) => {
                      const schedule = `Day ${day}, Session ${session}, ${y}`;
                      pricingLists?.push(schedule);
                    });
                  } else {
                    const schedule = `Day ${day}, Session ${session}`;
                    pricingLists.push(schedule);
                  }
                });
              } else {
                pricingLists.push('All Event');
              }
              return (
                <PricingCard key={key} condition={condition} price={price} pricingList={pricingLists} type={type} />
              );
            })}
          </div>
        </>
      )}
      {accountNumbers?.length >= 1 && (
        <>
          <h3 style={{ marginTop: '20px' }}>Payment Method</h3>
          <BankAccountList value={accountNumbers} />
        </>
      )}
    </>
  );
}
