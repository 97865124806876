import { number, typeOf } from 'mathjs';
import moment, { Moment } from 'moment';

export const registrationDate = ({
  registConfig,
  registDetails,
  registInformation,
}: {
  registConfig: string;
  registDetails: any[];
  registInformation: any;
}) => {
  let registOpen = null;
  let registClose = null;
  if (registConfig?.toLowerCase().includes('event')) {
    registOpen = registInformation?.registration_open;
    registClose = registInformation?.registration_close;
  } else if (registConfig?.toLowerCase().includes('day')) {
    const minMoments = registDetails?.map((item) => item?.registration_open);
    const maxMoments = registDetails?.map((item) => item?.registration_close);
    registOpen = moment.min(minMoments);
    registClose = moment.max(maxMoments);
  } else if (registConfig?.toLowerCase().includes('session')) {
    const sessions = [];
    registDetails?.forEach((item) => {
      if (item?.sessions?.length) sessions?.push(...item?.sessions);
    });
    const minMoments = sessions?.map((item) => item?.registration_open);
    const maxMoments = sessions?.map((item) => item?.registration_close);
    registOpen = moment.min(minMoments);
    registClose = moment.max(maxMoments);
  }
  return {
    registOpen,
    registClose,
  };
};

interface RegisteredSchedule {
  registration_config?: string;
  day?: number;
  session?: number;
  workshop?: string;
  is_sub_event?: boolean;
}
export const renderRegisteredSchedule = ({ value }: { value?: RegisteredSchedule }) => {
  if (!value) return 'All Event';
  const { day, session, workshop, registration_config: registConfig, is_sub_event } = value;
  if (
    (!!registConfig &&
      registConfig?.toLowerCase()?.includes('event') &&
      !is_sub_event &&
      !day &&
      !session &&
      !workshop) ||
    (!day && !session && !workshop)
  ) {
    return 'All Event';
  } else if (!!day && !!session && !!workshop && typeOf(workshop) === 'string') {
    return `Day ${day}, Session ${session}, Workshop: ${workshop}`;
  } else if (!!day && !!session) {
    return `Day ${day}, Session ${session}`;
  } else if (!!day) {
    return `Day ${day}`;
  } else {
    return '-';
  }
};

export const disabledDateOfBirth = ({
  current,
  ageMin,
  ageMax,
}: {
  current: Moment;
  ageMin?: number;
  ageMax?: number;
}) => {
  const startDate = ageMax ? moment().subtract(ageMax, 'years') : null;
  const endDate = ageMin ? moment().subtract(ageMin, 'years') : null;
  if (!!current && !!startDate && !!endDate) {
    return current > endDate || current < startDate;
  } else if (!!startDate) {
    return current < startDate;
  } else if (!!endDate) {
    return current > endDate;
  } else {
    return false;
  }
};

export function calculateAge(date) {
  if (date) {
    const diff = moment(date).diff(moment(), 'year', true);
    const age = Math.abs(diff);
    return Math.floor(age);
  }
  return null;
}

export function generateAge(date) {
  if (date) {
    const diff = moment(date)?.diff(moment(), 'years', true);
    const age = Math.abs(diff);
    return Math.floor(age);
  }
  return null;
}
