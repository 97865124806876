import React, { useEffect, useState } from 'react';
import { Input, Layout, Image, Button, Drawer, Menu, Avatar, Dropdown } from 'antd';
import './header.less';
import { AntdIconWrapper, authHelper, makeCommonDataSource } from '@eigen3m/react-base-frontend';
import { BiMenuAltLeft } from 'react-icons/bi';
import { VscAccount } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '@assets/images/navbar-logo.png';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';
import LogoBlue from '@assets/images/ifgf-logo-blue.png';
import { SignOut, SignIn, IdentificationBadge } from 'phosphor-react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { getAccessRight } from 'shared/helpers/user-category-transformer';

const { Header } = Layout;

export function HeaderPage() {
  const account = authHelper.getAccount();
  const username = account?.user?.username;

  const [MenuItems, setMenuItems] = useState([]);
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string[]>();

  const navigate = useNavigate();
  const dataSource = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.eventCategory + '/home',
  });

  function makeSlugEventCategory(name) {
    return name.replace(/\s+/g, '-')?.toLowerCase();
  }

  async function getEventCategories(): Promise<void> {
    await dataSource.handleGetIndex({
      params: {
        limit: 100,
        statuses: ['active'],
        show_to_home: true,
      },
      onSuccess({ response }: any) {
        const items = response?.items ?? [];
        setMenuItems(
          items.map((item) => {
            return {
              key: item.id,
              label: <Link to={`/event/category/${makeSlugEventCategory(item.name)}`}>{item.name}</Link>,
            };
          }),
        );
      },
    });
  }

  useEffect(() => {
    getEventCategories();
  }, []);

  async function handleLogout() {
    await authHelper.clearStorageLogout();
    navigate(WebUrlData.login);
  }

  const navigateByUserAccess = () => {
    const listModule = [
      moduleKey.BANK_ACCOUNT,
      moduleKey.EMAIL_TEMPLATE,
      moduleKey.EMAIL_SETTING,
      moduleKey.USER_CATEGORY,
      moduleKey.USER,
      moduleKey.SPEAKER,
      moduleKey.VENUE,
      moduleKey.EVENT_CATEGORY,
      moduleKey.EVENT_DATA,
      moduleKey.EVENT_ACTIVITY,
      moduleKey.EVENT_ACTIVITY,
      moduleKey.REPORT,
    ];
    const access = getAccessRight(moduleKey.BANK_ACCOUNT);
    const hasAccessBankAccount = access?.view ?? false;
    if (hasAccessBankAccount) {
      return navigate('/configuration/bank-accounts');
    } else {
      listModule?.forEach((item) => {
        const access = getAccessRight(item);
        const hasAccessView = access?.view ? true : false;
        if (hasAccessView) {
          if (item === moduleKey.BANK_ACCOUNT) {
            return navigate(WebUrlData.bank_accounts);
          } else if (item === moduleKey.EMAIL_TEMPLATE) {
            return navigate(WebUrlData.email_template);
          } else if (item === moduleKey.EMAIL_SETTING) {
            return navigate(WebUrlData.email_setting);
          } else if (item === moduleKey.USER_CATEGORY) {
            return navigate(WebUrlData.user_categories);
          } else if (item === moduleKey.USER) {
            return navigate(WebUrlData.users);
          } else if (item === moduleKey.SPEAKER) {
            return navigate(WebUrlData.speaker);
          } else if (item === moduleKey.VENUE) {
            return navigate(WebUrlData.venue);
          } else if (item === moduleKey.EVENT_CATEGORY) {
            return navigate(WebUrlData.even_category);
          } else if (item === moduleKey.EVENT_DATA) {
            return navigate(WebUrlData.event_data);
          } else if (item === moduleKey.EVENT_ACTIVITY) {
            return navigate(WebUrlData.event_activity);
          } else if (item === moduleKey.REPORT) {
            return navigate(WebUrlData.report);
          }
        }
      });
    }
  };

  function makeAvatarMenu() {
    if (!account) {
      return (
        <Menu style={{ width: 200 }}>
          <Menu.Item icon={<SignIn />} onClick={() => navigate(WebUrlData.login)}>
            Login
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item icon={<AiOutlineAppstoreAdd />} onClick={() => navigateByUserAccess()}>
          Manage Event
        </Menu.Item>
        <Menu.Item icon={<SignOut />} onClick={() => handleLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="sticky-header app-header">
      <Drawer
        closable={false}
        open={openDrawerMenu}
        width={250}
        placement="left"
        onClose={() => setOpenDrawerMenu(false)}
        bodyStyle={{ padding: 0 }}
      >
        <div
          className="sticky-header"
          style={{
            background: '#fff',
            borderBottom: '1px solid #f0f0f0',
            paddingTop: 20,
            paddingBottom: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link to="/">
            <Image preview={false} height={30} src={LogoBlue} />
          </Link>
        </div>
        <div style={{ marginBottom: 10 }}></div>
        <Menu theme="light" mode="inline" items={MenuItems} selectedKeys={activeMenu} />
      </Drawer>

      <Header className="header">
        <div className="mobile-menu">
          <Button
            type="text"
            onClick={() => setOpenDrawerMenu(true)}
            icon={
              <AntdIconWrapper>
                <BiMenuAltLeft style={{ fontSize: 22, color: '#fff' }} />
              </AntdIconWrapper>
            }
          />
        </div>

        <div className="header-logo">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setActiveMenu(undefined);
              navigate('/');
            }}
          >
            <Image preview={false} height={30} src={Logo} />
          </div>
        </div>

        <div className="content-right">
          <Dropdown overlay={makeAvatarMenu()} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
            <div className="account-content">
              {account && <Avatar className="custom-avatar">{username?.slice(0, 3)}</Avatar>}
              {!account && (
                <AntdIconWrapper>
                  <VscAccount style={{ fontSize: 25 }} />
                </AntdIconWrapper>
              )}
              <span style={{ marginLeft: 5, fontSize: '14px' }}>{username ? username : `My Account`}</span>
            </div>
          </Dropdown>
        </div>
      </Header>
      <div className="desktop-menu">
        <Menu
          theme="light"
          mode="horizontal"
          items={MenuItems}
          selectedKeys={activeMenu}
          onSelect={(selected) => {
            setActiveMenu([selected.key]);
          }}
        />
      </div>
    </div>
  );
}
