import { ApiUrlData } from '@base-configs';
import {
  IndexPageWrapper,
  notificationFailed,
  notificationSuccess,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { Col, Form, Modal, Row, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormPayment from '../components/form-payment';
import InvoiceInformation from '../components/invoice-information';
import { DetailPayment } from '../components/detail-payment';
import { typeOf } from 'mathjs';

export default function IndexPage() {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const { dataSource } = usePageProvider();
  const { webUrl } = usePageProvider();
  const splitWebUrl = webUrl.split('/');
  const type = splitWebUrl[2];
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dateNow = moment().valueOf();

  async function handleAfterGetData(data) {
    try {
      form.setFieldsValue({
        detail_data: data,
        id: data?.id,
        destination_bank: data?.destination_bank,
        bank_account: data?.bank_account,
        payment_date: data?.payment_date ? moment(Number(data?.payment_date)) : null,
        payment_type: data?.payment_type,
        payer_name: data?.payer_name,
        no_payer_account: data?.no_payer_account,
        image_url: data?.image_url,
      });
      const expiredDate = typeOf(data?.expired_date) === 'string' ? parseInt(data?.expired_date) : data?.expired_date;
      const isExpired = expiredDate > dateNow ? false : true;
      if (isExpired) {
        notificationFailed(['Payment has expired!']);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }

      if (['closed'].includes(data?.status)) {
        notificationFailed(['Payment has expired!']);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getPaymentInformation(token) {
    setLoading(true);
    try {
      await dataSource.handleGetData(token, {
        onSuccess({ response }) {
          handleAfterGetData(response);
        },
        onFailed({ message }) {
          notificationFailed([message as string]);
        },
      });
    } catch (error) {
      notificationFailed([error as string]);
    } finally {
      setLoading(false);
    }
  }

  async function handleAfterSaveData(response) {
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'PATCH',
        url: `${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.payment}/${response?.id}/confirm-request`,
      },
      async onSuccess({ response }) {
        notificationSuccess(['Payment has been confirmed.']);
        navigate('/');
      },
      async onFailed({ message }) {
        notificationFailed([message as string]);
      },
    });
  }

  async function onSavePaymentConfirmation(payload) {
    setLoading(true);
    try {
      const requestPayload = form.getFieldsValue();
      const id = requestPayload.id;
      const imageUrls = payload?.image_url?.filter((item) => !!item?.image_url && !!item?.uid) ?? [];
      const newImageUrls = imageUrls?.map((item) => {
        return {
          ...item,
          document_id: item?.uid,
        };
      });
      const transformedData = {
        destination_bank: payload?.destination_bank?.name,
        bank_account: payload?.bank_account?.account_no,
        payment_date: payload?.payment_date ? moment(payload?.payment_date).valueOf() : null,
        payment_type: payload?.payment_type,
        payer_name: payload?.payer_name,
        no_payer_account: payload?.no_payer_account,
        image_url: newImageUrls,
      };
      // return;
      if (type === 'payment-confirmation') {
        await dataSource.handleUpdate(id, transformedData, {
          async onSuccess({ response }) {
            await handleAfterSaveData(response);
          },
          async onFailed({ message }) {
            notificationFailed([message as string]);
          },
        });
      }
      if (type === 'payment-cancellation') {
        await dataSource.handleCustomRequest({
          paramRequest: {
            method: 'PATCH',
            url: `${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.payment}/${payload?.id}/confirm-cancel`,
          },
          async onSuccess() {
            notificationSuccess(['Your payment has successfully canceled!']);
            setTimeout(() => {
              navigate('/');
            }, 2000);
          },
          async onFailed({ message }) {
            notificationFailed([message as string]);
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      getPaymentInformation(decodeToken);
    }
  }, [token]);

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        showBack: false,
        showNewData: false,
        showSearch: false,
        showFilter: false,
        pageTitle: type === 'payment-confirmation' ? 'Payment Confirmation' : 'Payment Cancellation',
      }}
    >
      <Spin spinning={loading}>
        <Form form={form} name="form-login" layout={'vertical'} onFinish={onSavePaymentConfirmation}>
          <Form.Item name="company" hidden noStyle />
          <Form.Item name="detail_data" hidden noStyle />
          <Form.Item name="id" hidden noStyle />
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              const status = values?.detail_data?.status;
              const isCancel = status === 'cancel';
              return (
                <React.Fragment>
                  <Modal
                    title="This payment has been cancelled"
                    open={isCancel}
                    closable={false}
                    cancelButtonProps={{
                      hidden: true,
                    }}
                    onOk={() => navigate('/')}
                  >
                    Payment with invoice ID {values?.detail_data?.code} has been cancelled, you cannot continue this
                    transaction.
                  </Modal>
                  <Row gutter={[10, 10]} style={{ justifyContent: 'center' }}>
                    <Col style={{ maxWidth: '1200px' }}>
                      <Row gutter={[10, 10]} style={{ justifyContent: 'center' }}>
                        <Col md={12} sm={24} xs={24}>
                          <InvoiceInformation />
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldsValue }) => {
                              const values = getFieldsValue();
                              const detailData = values?.detail_data;
                              const expiredDate =
                                typeOf(detailData?.expired_date) === 'string'
                                  ? parseInt(detailData?.expired_date)
                                  : detailData?.expired_date;
                              const isExpired = expiredDate > dateNow ? false : true;

                              if (['done', 'closed', 'cancel'].includes(detailData?.status) || isExpired) {
                                return <DetailPayment />;
                              } else {
                                return (
                                  <FormPayment
                                    form={form}
                                    onFinish={onSavePaymentConfirmation}
                                    dataGeneral={detailData}
                                  />
                                );
                              }
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            }}
          </Form.Item>
        </Form>
      </Spin>
    </IndexPageWrapper>
  );
}
