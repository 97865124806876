import { omit, pick, uniqBy } from 'lodash';
import { convertDateTimeEpoch, toEpoch, toTimeOnly } from './helpers';

export const transformerCreateVenue = (payload: any): any => {
  if (!payload) return null;
  const cleanVal = pick(payload, ['id', 'name', 'address', 'address_description']);
  return cleanVal;
};

export const transformerCreateInformation = (payload: any): any => {
  if (!payload) return null;
  const mainBanner = payload?.main_banner ?? [];
  const cleanVal = {
    ...omit(payload, 'main_banner', 'department_class_id'),
    banner_in_homepage: mainBanner?.includes('Homepage') ? true : false,
    banner_in_event_category: mainBanner?.includes('Event Category') ? true : false,
    venue: transformerCreateVenue(payload?.venue),
    document_url: payload?.banner_url,
  };
  return cleanVal;
};

export const transformerCreateParticipants = (payload: any): any => {
  if (!payload) return null;
  const cleanVal = {
    ...payload,
    created_at: undefined,
    updated_at: undefined,
    event_id: undefined,
    public_event: payload?.public_event === 'true' ? true : false,
  };
  return cleanVal;
};

const transformerScheduleItem = ({
  item,
  keyNo,
  itemChildren,
  keyChildren,
  keyTotalChildren,
  type,
}: {
  item: any;
  keyNo: string;
  itemChildren?: any[];
  keyChildren?: string;
  keyTotalChildren?: string;
  type?: string;
}): any => {
  const newItemChildren = !!itemChildren && !!itemChildren?.length ? itemChildren : [];
  const newTotalChildren = newItemChildren?.length;
  return {
    id: item?.id,
    [keyNo]: item?.no,
    name: item?.name,
    description: item?.description,
    [keyTotalChildren]: newTotalChildren,
    ...(type === 'day' ? { event_date: item?.date ? toEpoch(item?.date) : null } : {}),
    start_time: item?.time ? toTimeOnly(item?.time[0]) : null,
    end_time: item?.time ? toTimeOnly(item?.time[1]) : null,
    speakers: item?.speakers ?? [],
    ...(newItemChildren && keyChildren ? { [keyChildren]: newItemChildren } : {}),
  };
};

export const transformerCreateSchedule = (payload: any): any => {
  if (!payload) return null;
  const days = payload?.days ?? [];
  const cleanVal = {
    id: payload?.id,
    description: payload?.description,
    day_held: days?.length,
    showing_schedule: {
      show_agenda: payload?.showing_schedule?.show_agenda ?? false,
      show_day: payload?.showing_schedule?.show_day ?? false,
      show_session: payload?.showing_schedule?.show_session ?? false,
      show_workshop: payload?.showing_schedule?.show_workshop ?? false,
      show_workshop_agenda: payload?.showing_schedule?.show_workshop_agenda ?? false,
    },
    days: days?.map((item) => {
      const sessions = item?.sessions ?? [];
      const newSessions = sessions?.map((itemSession) => {
        const agendas = itemSession?.agendas ?? [];
        const newAgendas = agendas?.map((itemAgenda) => {
          const workshops = itemAgenda?.workshops ?? [];
          const newWorkshops = workshops?.map((itemWorkshop) => {
            const workshopAgendas = itemWorkshop?.workshop_agendas ?? [];
            const newWorkshopAgendas = workshopAgendas?.map((itemWorkshopAgenda) => {
              return transformerScheduleItem({
                item: itemWorkshopAgenda,
                keyNo: 'no_workshop_agenda',
              });
            });
            return transformerScheduleItem({
              item: itemWorkshop,
              keyNo: 'no_workshop',
              keyTotalChildren: 'no_of_workshop_agenda',
              itemChildren: newWorkshopAgendas,
              keyChildren: 'workshop_agendas',
            });
          });
          return transformerScheduleItem({
            item: itemAgenda,
            keyNo: 'no_agenda',
            keyTotalChildren: 'no_of_workshop',
            itemChildren: newWorkshops,
            keyChildren: 'workshops',
          });
        });
        return transformerScheduleItem({
          item: itemSession,
          keyNo: 'no_session',
          keyTotalChildren: 'no_of_agenda',
          itemChildren: newAgendas,
          keyChildren: 'agendas',
        });
      });

      const sessionMoreThanOne = !!sessions && !!sessions?.length && sessions?.length > 1 ? true : false;
      if (sessionMoreThanOne) {
        return transformerScheduleItem({
          item,
          keyNo: 'no_day',
          keyTotalChildren: 'no_of_session',
          itemChildren: newSessions,
          keyChildren: 'sessions',
          type: 'day',
        });
      } else {
        return {
          id: item?.id,
          no_day: item?.no,
          name: item?.name,
          description: item?.description,
          no_of_session: 1,
          event_date: item?.date ? toEpoch(item?.date) : null,
          start_time: item?.time ? toTimeOnly(item?.time[0]) : null,
          end_time: item?.time ? toTimeOnly(item?.time[1]) : null,
          speakers: item?.speakers ?? [],
          // sessions: [
          //   {
          //     no_session: 1,
          //     name: item?.name,
          //     description: item?.description,
          //     no_of_agenda: 0,
          //     start_time: item?.time ? toTimeOnly(item?.time[0]) : null,
          //     end_time: item?.time ? toTimeOnly(item?.time[1]) : null,
          //     speakers: item?.speakers ?? [],
          //   },
          // ],
          sessions: newSessions,
        };
      }
    }),
  };
  return cleanVal;
};

const transformerRegistrationDetail = ({
  item,
  keyNo,
  itemChildren,
  keyChildren,
}: {
  item: any;
  keyNo: string;
  itemChildren?: any[];
  keyChildren?: string;
}): any => {
  const openDate = item?.registration_open;
  const closeDate = item?.registration_close;
  return {
    id: item?.id,
    [keyNo]: item?.no,
    description: item?.name,
    date: item?.date ? toEpoch(item?.date) : null,
    start_time: item?.time ? toTimeOnly(item?.time[0]) : null,
    end_time: item?.time ? toTimeOnly(item?.time[1]) : null,
    ...convertDateTimeEpoch({ openDate, closeDate }),
    max_registrants: item?.max_registrants,
    event_link: item?.event_link,
    event_code: item?.event_code,
    event_password: item?.event_password,
    ...(itemChildren && keyChildren ? { [keyChildren]: itemChildren } : {}),
  };
};

export const transformerCreateRegistration = (payload: any): any => {
  const registConfig = payload?.registration_config?.toLowerCase();
  const registrationDetails = payload?.registration_details ?? [];
  const registInformation = payload?.registration_information;
  const openDate = registInformation?.registration_open;
  const closeDate = registInformation?.registration_close;

  const newRegistrationDetails = registrationDetails?.map((item) => {
    const sessions = item?.sessions ?? [];
    const newSessions = sessions?.map((itemSession) => {
      const agendas = itemSession?.agendas ?? [];
      const newAgendas = agendas?.map((itemAgenda) => {
        const workshops = itemAgenda?.workshops ?? [];
        const newWorkshops = workshops?.map((itemWorkshop) => {
          const workshopAgendas = itemWorkshop?.workshops_agendas ?? [];
          const newWorkshopAgendas = workshopAgendas?.map((itemWorkshopAgenda) => {
            return transformerRegistrationDetail({
              item: itemWorkshopAgenda,
              keyNo: 'agenda',
            });
          });
          return transformerRegistrationDetail({
            item: itemWorkshop,
            itemChildren: newWorkshopAgendas,
            keyChildren: 'workshop_agendas',
            keyNo: 'workshop',
          });
        });
        return transformerRegistrationDetail({
          item: itemAgenda,
          itemChildren: newWorkshops,
          keyChildren: 'workshops',
          keyNo: 'agenda',
        });
      });
      return transformerRegistrationDetail({
        item: itemSession,
        itemChildren: newAgendas,
        keyChildren: 'agendas',
        keyNo: 'session',
      });
    });
    return transformerRegistrationDetail({ item, itemChildren: newSessions, keyChildren: 'sessions', keyNo: 'day' });
  });
  const cleanVal = {
    ...payload,
    created_at: undefined,
    updated_at: undefined,
    max_registrant: undefined,
    id: payload?.id,
    description: payload?.description ?? payload?.registration_description,
    registration_information: {
      id: payload?.registration_information?.id,
      ...convertDateTimeEpoch({ openDate, closeDate }),
      max_registrants: registInformation?.max_registrants,
      event_link: registInformation?.event_link,
      event_code: registInformation?.event_code,
      event_password: registInformation?.event_password,
    },
    registration_details: !['event'].includes(registConfig) ? newRegistrationDetails : [],
  };
  return cleanVal;
};

export const transformerCreatePaymentPricings = (payload: any, registConfig?: string, eventType?: string): any => {
  let priceConfig = null;
  if (registConfig?.toLowerCase().includes('event')) priceConfig = 'Price per Event';
  else if (registConfig?.toLowerCase().includes('day')) priceConfig = 'Price per Day';
  else if (registConfig?.toLowerCase().includes('session')) priceConfig = 'Price per Session';
  const paymentMainPricings = payload?.payment_pricing_datas ?? [];
  const paymentSubPricings = payload?.payment_pricing_subs ?? [];
  const accountNumbers = payload?.account_numbers ?? [];
  const accounts = [];
  const banks = [];
  accountNumbers?.forEach((item) => {
    if (item?.bank && item?.account) {
      banks?.push(item?.bank);
      accounts?.push(item?.account);
    }
  });

  const paymentPricings = (values: any[]): any[] => {
    if (!!values && !!values?.length) {
      return values?.map((item) => {
        const daySessionWorkshops = item?.day_session_workshops ?? [];
        return {
          ...item,
          event_link: !!eventType && eventType.toLowerCase() === 'online' ? item?.event_link : undefined,
          event_code: !!eventType && eventType.toLowerCase() === 'online' ? item?.event_code : undefined,
          event_password: !!eventType && eventType.toLowerCase() === 'online' ? item?.event_password : undefined,
          day_session_workshops: undefined,
          day_session: daySessionWorkshops?.map((itemDay) => {
            const workshops = itemDay?.workshop ?? [];
            return {
              day: itemDay?.day?.no,
              session: itemDay?.session?.no,
              workshop: workshops?.map((itemWorkshop) => itemWorkshop?.name),
            };
          }),
        };
      });
    }
    return [];
  };

  const cleanVal = {
    id: payload?.id,
    price_information: payload?.price_information,
    payment_information: payload?.payment_information,
    payment_pricing_datas: paymentPricings(paymentMainPricings),
    payment_pricing_subs: paymentPricings(paymentSubPricings),
    accounts: accounts,
    // banks: banks,
    //filter unique bank by id
    banks: uniqBy(banks, function (e) {
      return e.id;
    }),
    // price_configuration: payload?.price_configuration,
    price_configuration: priceConfig,
    pricing_sub_required: payload?.pricing_sub_required === 'true' ? true : false,
  };
  return cleanVal;
};

export const transformerCreateAttendanceOfficer = (payload: any): any => {
  const officerDatas = payload?.officer_datas ?? [];
  const cleanVal = {
    id: payload?.id,
    attendance_configuration: payload?.attendance_configuration,
    use_qr: payload?.use_qr,
    officer_datas: officerDatas?.map((item) => {
      const daySessionWorkshops =
        item?.day_session_workshops?.map((data) => {
          const workshops = data?.workshop ?? [];
          return {
            day: data?.day?.no,
            session: data?.session?.no,
            workshop: workshops?.map((itemWorkshop) => itemWorkshop?.name),
            is_sub_event: false,
          };
        }) ?? [];

      const subDaySessionWorkshops =
        item?.sub_day_session_workshops?.map((data) => {
          const workshops = data?.workshop ?? [];
          return {
            day: data?.day?.no,
            session: data?.session?.no,
            workshop: workshops?.map((itemWorkshop) => itemWorkshop?.name),
            is_sub_event: true,
          };
        }) ?? [];

      const allDaySessionWorkshops = [...daySessionWorkshops, ...subDaySessionWorkshops];
      return {
        id: item?.id,
        officer: item?.officer,
        day_session_agenda_workshops: allDaySessionWorkshops,
      };
    }),
  };
  return cleanVal;
};
