import { useEffect } from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Button, Drawer, Form } from 'antd';
import { DrawerScheduleFormProps } from './drawer-schedule.entity';
import { v4 as uuidV4 } from 'uuid';
import { capitalize } from 'lodash';
import { ApiUrlData } from '@base-configs';

const createTitle = (action: string, type: string): string => {
  const title = action ? capitalize(action) : 'Create';
  if (type === 'day') {
    return `${title} Day`;
  } else if (type === 'session') {
    return `${title} Session`;
  } else if (type === 'agenda') {
    return `${title} Agenda`;
  } else if (type === 'workshop') {
    return `${title} Workshop`;
  } else if (type === 'workshop_agenda') {
    return `${title} Workshop Agenda`;
  } else {
    return `${title} Data`;
  }
};

export function DrawerScheduleForm(props: DrawerScheduleFormProps) {
  const { type, action, data, onChangeDrawer, visible, onClose, eventType, registConfig } = props;
  const [form] = Form.useForm();
  const drawerTitle = createTitle(action, type);

  const workshops = !!data?.agendas && !!data?.agendas?.length ? data?.agendas : [];
  const hasWorkshop = !!workshops?.length ? true : false;

  useEffect(() => {
    if (data) form.setFieldsValue(data);
  }, [data, visible]);

  const onFinish = (values: any) => {
    const payload = {
      ...values,
      key: data?.key ?? data?.id ?? uuidV4(),
    };
    onChangeDrawer(action, type, payload);
    form.resetFields();
    onClose(undefined);
  };

  async function onReset() {
    form.resetFields();
  }

  const isRequiredRegistDate = (type: string, registConfig: string) => {
    if (type === 'day') {
      return registConfig?.toLowerCase().includes('day') ? true : false;
    } else if (type === 'session') {
      return registConfig?.toLowerCase().includes('session') ? true : false;
    }
    return false;
  };

  const disabledRegistDate = (type: string, registConfig: string) => {
    if (registConfig?.toLowerCase().includes('event')) {
      return true;
    } else if (type === 'day') {
      return registConfig?.toLowerCase().includes('day') ? false : true;
    } else if (type === 'session') {
      return registConfig?.toLowerCase().includes('session') ? false : true;
    }
    return false;
  };

  const disabledMaxRegistrations = (type: string, registConfig: string, hasWorkshop?: boolean) => {
    if (registConfig?.toLowerCase().includes('event')) {
      return true;
    } else if (type === 'day') {
      return registConfig?.toLowerCase().includes('day') ? false : true;
    } else if (type === 'session') {
      if (registConfig?.toLowerCase().includes('session') && hasWorkshop) {
        return true;
      } else {
        return registConfig?.toLowerCase().includes('session') ? false : true;
      }
    }
    return false;
  };

  function FooterDrawer() {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <div>
          <Button
            style={{ marginRight: '5px' }}
            onClick={() => {
              onReset();
            }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  function disableDate(date: any, registOpen: moment.Moment): boolean {
    if (!registOpen) return false;
    else if (date < registOpen) return true;
    return false;
  }
  return (
    <Drawer
      width={600}
      title={drawerTitle}
      visible={visible}
      onClose={onClose}
      footer={<FooterDrawer />}
      footerStyle={{ height: '50px' }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ type }}>
        <Form.Item shouldUpdate noStyle>
          {(form) => {
            const registrationOpen = form.getFieldValue(['registration_open']);
            return (
              <FormBuilder
                columns={[
                  {
                    fields: [
                      {
                        customHidden: () => {
                          return true;
                        },
                        renderField() {
                          return (
                            <>
                              <Form.Item name="key" noStyle />
                              <Form.Item name={['schedule', 'day_key']} noStyle />
                              <Form.Item name={['schedule', 'session_key']} noStyle />
                              <Form.Item name={['schedule', 'agenda_key']} noStyle />
                              <Form.Item name={['schedule', 'workshop_key']} noStyle />
                            </>
                          );
                        },
                      },
                      {
                        gridColumn: { xl: 6, lg: 6, md: 6, sm: 24 },
                        label: capitalize(type),
                        name: ['no'],
                        fieldType: 'inputNumber',
                        inputNumberProps: {
                          min: 1,
                        },
                        customHidden: () => {
                          return true;
                        },
                      },
                      {
                        gridColumn: { xl: 24, lg: 24, md: 24, sm: 24 },
                        label: 'Name',
                        name: ['name'],
                        fieldType: 'inputText',
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: 'Name is required.',
                        //   },
                        // ],
                        inputTextProps: {
                          disabled: true,
                        },
                      },
                      {
                        gridColumn: {
                          xl: 8,
                          lg: 8,
                          md: 8,
                          sm: 24,
                        },
                        label: 'Registration Open',
                        name: ['registration_open'],
                        fieldType: 'datePicker',
                        rules: [
                          {
                            required: isRequiredRegistDate(type, registConfig),
                            message: 'Registration Open is required.',
                          },
                        ],
                        datePickerProps: {
                          style: { width: '100%' },
                          // showTime: true,
                          format: 'DD-MM-YYYY',
                          disabled: disabledRegistDate(type, registConfig),
                        },
                        customHidden() {
                          return ['workshop'].includes(type) ? true : false;
                        },
                      },
                      {
                        gridColumn: {
                          xl: 8,
                          lg: 8,
                          md: 8,
                          sm: 24,
                        },
                        label: 'Registration Close',
                        name: ['registration_close'],
                        fieldType: 'datePicker',
                        rules: [
                          {
                            required: isRequiredRegistDate(type, registConfig),
                            message: 'Registration Close is required.',
                          },
                        ],
                        datePickerProps: {
                          style: { width: '100%' },
                          // showTime: true,
                          format: 'DD-MM-YYYY',
                          disabled: disabledRegistDate(type, registConfig),
                          disabledDate(date) {
                            return disableDate(date, registrationOpen);
                          },
                        },
                        customHidden() {
                          return ['workshop'].includes(type) ? true : false;
                        },
                      },
                      {
                        gridColumn: {
                          xl: 8,
                          lg: 8,
                          md: 8,
                          sm: 24,
                        },
                        label: 'Max Registrant',
                        name: ['max_registrants'],
                        fieldType: 'inputNumber',
                        inputNumberProps: {
                          min: 1,
                          disabled: disabledMaxRegistrations(type, registConfig, hasWorkshop),
                        },
                      },
                      {
                        gridColumn: {
                          xl: 24,
                          lg: 24,
                          md: 24,
                          sm: 24,
                        },
                        label: 'Event Link',
                        name: ['event_link'],
                        fieldType: 'inputText',
                        customHidden() {
                          return eventType === 'online' ? false : true;
                        },
                        inputTextProps: {
                          disabled:
                            !!registConfig && registConfig?.toLowerCase()?.includes('session') && hasWorkshop
                              ? true
                              : false,
                        },
                      },
                      {
                        gridColumn: {
                          xl: 24,
                          lg: 24,
                          md: 24,
                          sm: 24,
                        },
                        label: 'Event Code',
                        name: ['event_code'],
                        fieldType: 'inputText',
                        customHidden() {
                          return eventType === 'online' ? false : true;
                        },
                        inputTextProps: {
                          disabled:
                            !!registConfig && registConfig?.toLowerCase()?.includes('session') && hasWorkshop
                              ? true
                              : false,
                        },
                      },
                      {
                        gridColumn: {
                          xl: 24,
                          lg: 24,
                          md: 24,
                          sm: 24,
                        },
                        label: 'Event Password',
                        name: ['event_password'],
                        fieldType: 'inputText',
                        customHidden() {
                          return eventType === 'online' ? false : true;
                        },
                        inputTextProps: {
                          disabled:
                            !!registConfig && registConfig?.toLowerCase()?.includes('session') && hasWorkshop
                              ? true
                              : false,
                        },
                      },
                    ],
                  },
                ]}
              />
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
}
