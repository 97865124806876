import { RenderCurrency } from '@eigen3m/react-base-frontend';
import { Fragment } from 'react';
import { CalculateDetail, CalculateDetailSplitPayment, TotalInvoice } from './components';
import { InvoiceJoinPayment } from './components/invoice-join-payment';

type CalculateResultProps = {
  priceType?: any;
  total_invoice?: any;
};

export function CalculateResult({ priceType }: CalculateResultProps): JSX.Element {
  const isJoinPayment = priceType?.is_join_payment;
  const invoiceItems = priceType?.items ?? [];

  const invoiceFreeItems = invoiceItems?.map((invoice) => {
    const pricingType = invoice?.pricing_type ?? [];
    const newPricingType = pricingType?.filter((item) => item?.price?.price === 0);
    return {
      ...invoice,
      pricing_type: newPricingType,
    };
  });
  const filteredInvoiceFreeItems = invoiceFreeItems?.filter((item) => !!item?.pricing_type?.length);

  return (
    <div className="calculate-invoice">
      <div className="calculate-detail">
        {isJoinPayment
          ? invoiceItems?.map((price, index) => {
              return (
                <div key={price?.day}>
                  <CalculateDetail key={price?.day} price={price} index={index} />
                </div>
              );
            })
          : invoiceItems.map((item, index) => {
              return <CalculateDetailSplitPayment key={item?.id} item={item} index={index} />;
            })}
      </div>
      <div style={{ display: isJoinPayment ? 'block' : 'none' }}>
        {isJoinPayment && (
          <>
            <hr style={{ width: '100%' }} />
            <div className="calculate-total" style={{ marginBottom: 20 }}>
              <TotalInvoice priceType={priceType} />
            </div>

            {!!filteredInvoiceFreeItems?.length && <h3 style={{ fontWeight: 'bold' }}>Free Event</h3>}
            {filteredInvoiceFreeItems.map((price, index) => {
              return (
                <div key={price?.day}>
                  <InvoiceJoinPayment key={price?.day} price={price} index={index} />
                </div>
              );
            })}
          </>
        )}
        <div style={{ marginBottom: 28 }}>
          <p>
            Link payment confirmation will be sent to{' '}
            <strong>Email Address Participant 1 {!!invoiceItems?.length && ': ' + invoiceItems[0]?.registran}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
