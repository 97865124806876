import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { EmailSettingEntity } from '../../domain/entities';
import { makeEmailSettingTransformer } from '../../domain/factories';
import { ApiUrlData, moduleKey, WebUrlData } from '@base-configs';
import { getAccessRight } from 'shared/helpers/user-category-transformer';
import { ForbiddenPage } from '@components/forbidden-page';

export default function FactoryPage() {
  const transformer = makeEmailSettingTransformer<EmailSettingEntity>();
  const InitialValue: InitialValuePageProviderEntity<EmailSettingEntity> = {
    transformer,
    moduleKey: moduleKey.EMAIL_SETTING,
    webUrl: WebUrlData.email_setting,
    apiUrl: ApiUrlData.general_config,
  };

  const access = getAccessRight(InitialValue?.moduleKey);
  if (!access.view) return <ForbiddenPage />;

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
