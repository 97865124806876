import { ApiUrlData } from '@base-configs';
import { FormBuilder, usePageProvider } from '@eigen3m/react-base-frontend';
import { calculateAge, disabledDateOfBirth } from '@helpers';
import { Button, Card, Form, Input, notification, Spin } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface FormConfiguration {
  registration_config: string;
  address_required: boolean;
  date_of_birth_required: boolean;
  email_address_required: boolean;
  gender_required: boolean;
  marital_status_required: boolean;
  mobile_phone_required: boolean;
  title_name_required: boolean;
  id: any;
}

interface Participant {
  age_min: number;
  age_max: number;
}
export function generateAge(date) {
  if (date) {
    const diff = moment(date)?.diff(moment(), 'years', true);
    const age = Math.abs(diff);
    return Math.floor(age);
  }
  return null;
}

export default function DrawerForm({ id, participants }: any): JSX.Element {
  const { dataSource } = usePageProvider();
  const [configuration, setConfiguration] = useState<FormConfiguration>(null);
  const [participant, setParticipant] = useState<Participant>(null);
  const [payment, setPayment] = useState<any>(null);
  const [loadingForm, setLoadingForm] = useState(false);

  //Set Default Date of Birth
  const ageMin = participants?.age_min;
  const ageMax = participants?.age_max;
  const defaultDateOfBirth = ageMin ?? ageMax ?? null;
  const startOfBirth = ageMin || ageMin ? moment().subtract(defaultDateOfBirth, 'years') : null;

  const handleGetDataRegistration = async (id: string) => {
    setLoadingForm(true);

    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/participants`,
      },
      onSuccess({ response }: any) {
        setParticipant(response);
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data participant error',
          description: response,
        });
      },
    });
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/registrations`,
      },
      onSuccess({ response }: any) {
        setConfiguration(response);
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data registration error',
          description: response,
        });
      },
    });

    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventData + `/${id}/payments`,
      },
      onSuccess({ response }: any) {
        setPayment(response);
      },
      onFailed(response: any) {
        notification.error({
          message: 'Get data payment pricings error',
          description: response,
        });
      },
    });

    setLoadingForm(false);
  };

  const hasSubEvent = !!payment?.payment_pricing_subs?.length ? true : false;

  useEffect(() => {
    if (id) {
      handleGetDataRegistration(id);
    }
  }, [id]);

  return (
    <Spin spinning={loadingForm}>
      <Form.Item name={['event', 'id']} initialValue={id} hidden noStyle />
      {configuration && (
        <Form.Item name="registration_config" initialValue={configuration?.registration_config} hidden noStyle />
      )}
      <FormBuilder
        columns={[
          {
            fields: [
              {
                renderField() {
                  return <Form.Item name={['id']} noStyle hidden />;
                },
                customHidden() {
                  return true;
                },
              },
              // {
              //   name: ['registran', 'title'],
              //   label: 'Title',
              //   fieldType: 'inputText',
              //   gridColumn: { span: 6 },
              //   rules: [{ required: configuration?.title_name_required, message: 'Title is required.' }],
              //   inputTextProps: {
              //     placeholder: 'Title',
              //   },
              // },
              {
                name: ['registran', 'name'],
                label: 'Full Name',
                fieldType: 'inputText',
                gridColumn: { span: 24 },
                rules: [{ required: configuration?.title_name_required, message: 'Full Name is required.' }],
                inputTextProps: {
                  placeholder: 'Full Name',
                },
              },
              {
                name: ['registran', 'address'],
                label: 'Address',
                fieldType: 'inputAddress',
                gridColumn: { span: 24 },
                rules: [{ required: configuration?.address_required, message: 'Address is required.' }],
                inputAddressProps: {
                  placeholder: 'Input Address',
                },
              },
              {
                name: ['registran', 'phone_number'],
                label: 'Mobile Phone',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                rules: [{ required: configuration?.mobile_phone_required, message: 'Mobile Phone is required.' }],
                inputTextProps: {
                  placeholder: 'Mobile Phone',
                },
              },
              {
                name: ['registran', 'email'],
                label: 'Email',
                fieldType: 'inputText',
                gridColumn: { span: 12 },
                rules: [
                  { required: configuration?.email_address_required, type: 'email', message: 'Email is required.' },
                ],
                inputTextProps: {
                  placeholder: 'Email Address',
                },
              },
              {
                name: ['registran', 'date_of_birth'],
                label: 'Date of Birth',
                fieldType: 'datePicker',
                gridColumn: { span: 6 },
                rules: [{ required: configuration?.date_of_birth_required, message: 'Date of Birth is required.' }],
                datePickerProps: {
                  disabledDate: (current) => {
                    const ageMin = participant?.age_min ?? null;
                    const ageMax = participant?.age_max ?? null;
                    return disabledDateOfBirth({ current, ageMin, ageMax });
                  },
                  defaultPickerValue: startOfBirth,
                },
              },
              {
                name: ['registran', 'age'],
                label: 'Age',
                fieldType: 'inputText',
                gridColumn: { span: 4 },
                renderField({ getFieldsValue }) {
                  const values = getFieldsValue();
                  const dateOfBirth = values?.registran?.date_of_birth
                    ? moment(values?.registran?.date_of_birth, 'YYYY-MM-DD')
                    : null;
                  const age = generateAge(dateOfBirth);
                  return <Form.Item label="Age">{age ? `${age} years old` : '-'}</Form.Item>;
                },
              },
              {
                name: ['registran', 'gender'],
                label: 'Gender',
                fieldType: 'selectPaginate',
                gridColumn: { span: 6 },
                rules: [{ required: configuration?.gender_required, message: 'Gender is required.' }],
                selectPaginateProps: {
                  dataSourceUrl: ApiUrlData.enum_gender,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  customLabel(row) {
                    return row;
                  },
                },
              },
              {
                name: ['registran', 'marital_status'],
                label: 'Marital Status',
                fieldType: 'selectPaginate',
                gridColumn: { span: 6 },
                rules: [{ required: configuration?.marital_status_required, message: 'Marital Status is required.' }],
                selectPaginateProps: {
                  dataSourceUrl: ApiUrlData.enum_marital_status,
                  classNamePrefix: 'react-select-custom-prefix-radius',
                  customLabel(row) {
                    return row;
                  },
                },
              },
              {
                gridColumn: { span: 24 },
                renderField({ getFieldValue, setFieldValue }) {
                  return (
                    <Card title="Main Event" style={{ marginBottom: '20px' }}>
                      <Form.List name={['registran', 'schedule']}>
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields?.map(({ key, name, ...restField }, index) => {
                                const day = getFieldValue(['registran', 'schedule', index, 'day']);
                                const session = getFieldValue(['registran', 'schedule', index, 'session']);
                                const dayId = day?.id;
                                const sessionId = session?.id;

                                //Reset form
                                if (!day) {
                                  setFieldValue(['registran', 'schedule', index, 'session'], null);
                                  setFieldValue(['registran', 'schedule', index, 'workshop'], null);
                                } else if (!session) {
                                  setFieldValue(['registran', 'schedule', index, 'workshop'], null);
                                }

                                const registConfig = !!configuration?.registration_config
                                  ? configuration?.registration_config?.toLowerCase()
                                  : null;

                                //Required Form
                                const isRequiredDay = [
                                  'registration per each day',
                                  'registration per each session',
                                ]?.includes(registConfig)
                                  ? true
                                  : false;
                                const isRequiredSession = registConfig?.includes('session') ? true : false;

                                //Disabled Form
                                const isDisabledSession = registConfig?.includes('session') ? false : true;
                                const isDisabledWorkshop = session?.has_workshop ? false : true;
                                return (
                                  <Form.Item key={key}>
                                    <FormBuilder
                                      columns={[
                                        {
                                          fields: [
                                            {
                                              ...restField,
                                              name: [name, 'registration_config'],
                                              gridColumn: { span: 24 },
                                              fieldType: 'inputText',
                                              customHidden() {
                                                return true;
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'day'],
                                              label: 'Day',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [{ required: isRequiredDay, message: 'Day is required.' }],
                                              selectPaginateProps: {
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${id}/list-day`,
                                                customLabel: (row) =>
                                                  `Day ${row?.no_day}${row?.name ? ': ' + row?.name : ''}`,
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'session'],
                                              label: 'Session',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [{ required: isRequiredSession, message: 'Session is required.' }],
                                              selectPaginateProps: {
                                                key: JSON.stringify(dayId),
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${dayId}/list-session`,
                                                customLabel: (row) =>
                                                  `Session ${row?.no_session}${row?.name ? ': ' + row?.name : ''}`,
                                                isDisabled: isDisabledSession,
                                                customFilterRequest() {
                                                  return {
                                                    event_id: id,
                                                  };
                                                },
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'workshop'],
                                              label: 'Workshop',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [
                                                { required: !isDisabledWorkshop, message: 'Workshop is required.' },
                                              ],
                                              selectPaginateProps: {
                                                key: JSON.stringify(sessionId),
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${sessionId}/list-workshop`,
                                                customLabel: (row) => `${row?.name}`,
                                                isDisabled: isDisabledWorkshop,
                                                customFilterRequest() {
                                                  return {
                                                    event_id: id,
                                                  };
                                                },
                                              },
                                            },
                                            {
                                              gridColumn: { span: 3 },
                                              renderField: () => {
                                                return (
                                                  <Form.Item label=" ">
                                                    <Button block onClick={() => remove(name)}>
                                                      -
                                                    </Button>
                                                  </Form.Item>
                                                );
                                              },
                                            },
                                          ],
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                );
                              })}
                              <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                  Add Schedule
                                </Button>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </Card>
                  );
                },
                customHidden: () => {
                  const registConfig = !!configuration?.registration_config
                    ? configuration?.registration_config?.toLowerCase()
                    : null;
                  return registConfig?.includes('event') ? true : false;
                },
              },
              {
                gridColumn: { span: 24 },
                renderField({ getFieldValue, setFieldValue }) {
                  return (
                    <Card title="Sub Event">
                      <Form.List name={['registran', 'sub_schedule']}>
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields?.map(({ key, name, ...restField }, index) => {
                                const day = getFieldValue(['registran', 'sub_schedule', index, 'day']);
                                const session = getFieldValue(['registran', 'sub_schedule', index, 'session']);
                                const dayId = day?.id;
                                const sessionId = session?.id;

                                //Reset form
                                if (!day) {
                                  setFieldValue(['registran', 'sub_schedule', index, 'session'], null);
                                  setFieldValue(['registran', 'sub_schedule', index, 'workshop'], null);
                                } else if (!session) {
                                  setFieldValue(['registran', 'sub_schedule', index, 'workshop'], null);
                                }

                                //Required Form
                                const isRequiredDay = false;
                                const isRequiredSession = day?.has_session ? true : false;
                                const isRequiredWorkshop = session?.has_workshop ? true : false;

                                //Disabled Form
                                const isDisabledSession = day?.has_session ? false : true;
                                const isDisabledWorkshop = session?.has_workshop ? false : true;
                                return (
                                  <Form.Item key={key}>
                                    <FormBuilder
                                      columns={[
                                        {
                                          fields: [
                                            {
                                              ...restField,
                                              name: [name, 'registration_config'],
                                              gridColumn: { span: 24 },
                                              fieldType: 'inputText',
                                              customHidden() {
                                                return true;
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'day'],
                                              label: 'Day',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [{ required: isRequiredDay, message: 'Day is required.' }],
                                              selectPaginateProps: {
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${id}/list-sub-day`,
                                                customLabel: (row) =>
                                                  `Day ${row?.no_day}${row?.name ? ': ' + row?.name : ''}`,
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'session'],
                                              label: 'Session',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [{ required: isRequiredSession, message: 'Session is required.' }],
                                              selectPaginateProps: {
                                                key: JSON.stringify(dayId),
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${dayId}/list-sub-session`,
                                                customLabel: (row) =>
                                                  `Session ${row?.no_session}${row?.name ? ': ' + row?.name : ''}`,
                                                isDisabled: isDisabledSession,
                                                customFilterRequest() {
                                                  return {
                                                    event_id: id,
                                                  };
                                                },
                                              },
                                            },
                                            {
                                              ...restField,
                                              name: [name, 'workshop'],
                                              label: 'Workshop',
                                              gridColumn: { span: 7 },
                                              fieldType: 'selectPaginate',
                                              rules: [
                                                { required: isRequiredWorkshop, message: 'Workshop is required.' },
                                              ],
                                              selectPaginateProps: {
                                                key: JSON.stringify(sessionId),
                                                placeholder: 'Choose',
                                                classNamePrefix: 'react-select-custom-prefix-radius',
                                                dataSourceUrl: ApiUrlData.eventData + `/${sessionId}/list-sub-workshop`,
                                                customLabel: (row) => `${row?.name}`,
                                                isDisabled: isDisabledWorkshop,
                                                customFilterRequest() {
                                                  return {
                                                    event_id: id,
                                                  };
                                                },
                                              },
                                            },
                                            {
                                              gridColumn: { span: 3 },
                                              renderField: () => {
                                                return (
                                                  <Form.Item label=" ">
                                                    <Button block onClick={() => remove(name)}>
                                                      -
                                                    </Button>
                                                  </Form.Item>
                                                );
                                              },
                                            },
                                          ],
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                );
                              })}
                              <Form.Item noStyle>
                                <Button type="dashed" onClick={() => add()} block>
                                  Add Sub Schedule
                                </Button>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </Card>
                  );
                },
                customHidden() {
                  return !hasSubEvent;
                },
              },
            ],
          },
        ]}
      />
    </Spin>
  );
}
