import { RouteEntity } from '@eigen3m/react-base-frontend';
import { WebUrlData } from '@base-configs';
import Events from '@pages/common/events/presentation/factories';
import Category from '@pages/common/category/presentation/factories';
import PaymentConfirmation from '@pages/event/payment-confirmation/presentation/factory';
export const PublicRoutes: RouteEntity[] = [
  {
    label: 'Home',
    path: WebUrlData.homepage,
    element: Events,
  },
  {
    label: 'Category',
    path: WebUrlData.category + '/:slug',
    element: Category,
  },
  {
    label: 'Payment Confirmation',
    path: WebUrlData.payment_confirmation,
    element: PaymentConfirmation,
  },
  {
    label: 'Payment Cancellation',
    path: WebUrlData.payment_cancellation,
    element: PaymentConfirmation,
  },
];
