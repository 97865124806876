import { ApiUrlData, subModuleKey } from '@base-configs';
import {
  ActionProps,
  IndexPageWrapper,
  SelectPaginate,
  SinglePage,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { OptionsYesOrNo } from '@helpers';
import { statusesAttendanceOptions } from '@pages/event/event-activity/presentation/helpers';
import { Form } from 'antd';
import { useState } from 'react';

import { officersAttendanceColumn } from '../../../../helpers/columns';
import DrawerForm from './drawer-form';
import { OfficerSummary } from './officer-summary';

export default function IndexTable(): JSX.Element {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');

  const { id } = usePageProvider();

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  return (
    <IndexPageWrapper
      showPageHeader={false}
      padding={[]}
      showPageHeaderSimple={true}
      pageToolbarProps={{
        showNewData: false,
        showImport: false,
        showPrint: true,
        showBack: false,
        showActivate: false,
        showDeactivate: false,
        showConfirmProcess: false,
      }}
      drawerImportProps={{
        customModuleKey: subModuleKey.event_activity.OFFICER_ATTENDANCE,
      }}
      drawerFilterProp={{
        width: 600,
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  label: 'Day',
                  name: 'day',
                  gridColumn: { xl: 8 },
                  fieldType: 'selectPaginate',
                  selectPaginateProps: {
                    dataSourceUrl: ApiUrlData.eventData + `/${id}/schedules/day`,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (row) => `Day ${row?.no_day}`,
                    isClearable: true,
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const day = form.getFieldValue('day');
                    const dayId = day?.id;
                    const hasSession = day?.has_session;
                    return (
                      <Form.Item name="session" label="Session">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${dayId}/schedules/session`}
                          customLabel={(row) => `Session ${row?.no_session}`}
                          filterRequest={{ event_id: id }}
                          isDisabled={!hasSession}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  gridColumn: { xl: 8 },
                  renderField(form) {
                    const session = form.getFieldValue('session');
                    const sessionId = session?.id;
                    const hasWorkshop = session?.has_workshop;
                    return (
                      <Form.Item name="workshop" label="Workshop">
                        <SelectPaginate
                          classNamePrefix="react-select-custom-prefix-radius"
                          dataSourceUrl={ApiUrlData.eventData + `/${sessionId}/schedules/workshop`}
                          customLabel={(row) => row?.name}
                          filterRequest={{ event_id: id }}
                          isDisabled={!hasWorkshop}
                          isClearable
                        />
                      </Form.Item>
                    );
                  },
                },
                // {
                //   label: 'Member',
                //   name: 'member',
                //   gridColumn: { xl: 8 },
                //   fieldType: 'select',
                //   selectProps: {
                //     options: OptionsYesOrNo,
                //     classNamePrefix: 'react-select-custom-prefix-radius',
                //     customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                //     isClearable: true,
                //   },
                // },
                {
                  label: 'Check In',
                  name: 'check_in',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    options: OptionsYesOrNo,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => OptionsYesOrNo?.find((item) => item.value === value).label,
                    isClearable: true,
                  },
                },
                {
                  label: 'Statuses',
                  name: 'statuses',
                  gridColumn: { xl: 8 },
                  fieldType: 'select',
                  selectProps: {
                    isMulti: true,
                    options: statusesAttendanceOptions,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel: (value) => statusesAttendanceOptions?.find((item) => item.value === value).label,
                    isClearable: true,
                  },
                },
              ],
            },
          ],
        },
      }}
    >
      <div className="no-print">
        <OfficerSummary />
      </div>
      <SinglePage
        drawerFormProps={{
          action: actionForm,
          visible: visibleDrawerForm,
          onClose: onCloseDrawerForm,
          onShow: onShowDrawerForm,
          forceRequest: true,
          children: <DrawerForm />,
        }}
        dataTableProps={{
          columns: officersAttendanceColumn,
          // defaultColumnIgnored: ['status'],
          pagination: false,
          defaultFilterRequestData: {
            // statuses: ['active'],
            event_ids: [id],
          },
          rowActionProps: {
            actionIgnored: ['detail', 'delete', 'confirm_process', 'activate', 'deactivate', 'duplicate'],
          },
        }}
        autoConfirmAfterAction={true}
      />
    </IndexPageWrapper>
  );
}
