import { Spin } from 'antd';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { useEffect, useState } from 'react';
import { emailData } from './helpers';

export default function PreviewEmailTemplate({ value, key }: { value: any; key?: string }): JSX.Element {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  function convertAllVariable(bodyHTML: any): any {
    setLoading(true);
    const template = Handlebars.compile(bodyHTML);
    const newTemplate = template({
      ...emailData,
      event_description: `
      <style type="text/css">
      .event-description p{
        margin-bottom: 20px;
        font-size: 14px; line-height: 140%;
        text-align:justify
      }
      </style>
      <div class="event-description">
      ${emailData?.event_description}
      </div>
      `,
      event_term_and_condition: `
      <style type="text/css">
      .event-terms p{
        margin-bottom: 20px;
        font-size: 14px; line-height: 140%;
        text-align:justify
      }
      </style>
      <div class="event-terms">
      ${emailData?.event_term_and_condition}
      </div>
      `,
      officer: `
          <ul style="padding-left:15px">${emailData?.officer
            ?.map((item) => {
              return `<li style="margin-bottom:20px">
              <div>
                Name : ${item?.officer?.name}</br>
                Email : ${item?.officer?.email}</br>
                Phone Number : ${item?.officer?.phone_number}
              </div>
              </li>`;
            })
            .join('')}</ul>`,
      officer_attandance: `
      <ul style="padding-left:15px">
      ${emailData?.officer_attandance
        ?.map((item) => {
          return `
        <li style="margin-bottom:20px">
          <span>Name : ${item?.officer?.name}</span> <br>
          <span>Email : ${item?.officer?.email}</span> <br>
          <span>Phone Number : ${item?.officer?.phone_number ?? '-'}</span> <br>
          <span>Qr Code : </span><br>
          <img width="200px" height="200px" src="${item.officer.qr_code}" /> <br>
          <span>Schedule : 
          <ul>
          ${item?.officer_per_schedule
            ?.map((officer_per_schedule) => {
              return `
              <li>
                ${officer_per_schedule?.schedule?.day ? 'Day ' + officer_per_schedule?.schedule?.day : 'All Event'}${
                officer_per_schedule?.schedule?.session ? ', Session ' + officer_per_schedule?.schedule?.session : ''
              }${
                officer_per_schedule?.schedule?.workshop
                  ? ', Workshop: ' + officer_per_schedule?.schedule?.workshop
                  : ''
              } 
              </li>
            `;
            })
            .join('')}
          </ul>
          </span>
        </li>`;
        })
        .join('')}
      </ul>`,
      invoice: `<ul style="list-style:none;padding-left:5px">
      ${emailData?.invoice
        ?.map((item) => {
          return `<li>
        ${item?.day ? 'Day ' + item?.day : 'All Event'}${item?.session ? ', Session ' + item?.session : ''}${
            item?.workshop ? ', Workshop: ' + item?.workshop : ''
          } 
        (${item.name})
        = 
        ${item.price?.price ? 'Rp. ' + new Intl.NumberFormat('id').format(item.price?.price) : 'Rp. 0'}
        </li>`;
        })
        .join('')}
      <hr/>
      <b>Total Invoice : Rp. 400.000</b> 
     </ul>`,
      schedules: `<ul style="padding-left:15px">
      ${emailData?.schedules
        ?.map((schedule) => {
          return `
        <li>
          ${
            schedule?.day
              ? `<b>Day ${schedule.day.no_day ?? schedule.day.name}</b>`
              : `<b>Event ${emailData?.event_name}</b>`
          }${
            schedule?.session
              ? `<b>, Session ${schedule.session.no_session ?? `Session ${schedule.session.name}`}</b>`
              : ''
          }${schedule?.workshop ? `<b>, Workshop: ${schedule.workshop.name ?? schedule.workshop.name}</b>` : ''} 
          <p>Date : ${schedule.open_date} - ${schedule.close_date}  ${
            schedule.open_time ? `at ${schedule.open_time} -` : ''
          } ${schedule.close_time ?? ''}</p>
        </li>`;
        })
        .join('')}
    </ul>`,
      schedule_online:
        emailData?.event_type !== 'Online'
          ? `<ul style="padding-left:15px">
          ${emailData?.schedules
            ?.map((schedule) => {
              return `
            <li>
              ${
                schedule?.day
                  ? `<b>Day ${schedule.day.no_day ?? schedule.day.name}</b>`
                  : `<b>Event ${emailData?.event_name}</b>`
              }${
                schedule?.session
                  ? `<b>, Session ${schedule.session.no_session ?? `Session ${schedule.session.name}`}</b>`
                  : ''
              }${schedule?.workshop ? `<b>, Workshop: ${schedule.workshop.name ?? schedule.workshop.name}</b>` : ''} 
              <p>Date : ${schedule.open_date} - ${schedule.close_date}  ${
                schedule.open_time ? `at ${schedule.open_time} -` : ''
              } ${schedule.close_time ?? ''}</p>
                <p>Link : ${schedule.event_link ?? '-'}</p>
                <p>Code : ${schedule.event_code ?? '-'}</p>
                <p>Password : ${schedule.event_password ?? '-'}</p>
            </li>`;
            })
            .join('')}
        </ul>`
          : '',
      participants: `${emailData?.participants
        ?.map((item) => {
          const registered_schedule = item.registered_schedule;
          return `
              <div>
                  <ul>
                    <li>${emailData?.registran?.title ?? '-'} ${emailData?.registran?.name ?? '-'}</br>
                    Email: ${emailData?.registran?.email ?? '-'}</br>
                    Phone: ${emailData?.registran?.phone_number ?? '-'}</br>
                    Schedule: ${registered_schedule?.day ? `Day ${registered_schedule?.day}` : ''}${
            registered_schedule?.session ? `, Session ${registered_schedule?.session}` : ''
          }${registered_schedule?.session ? `, Workshop: ${registered_schedule?.workshop}` : ''}</li>
                </ul>`;
        })
        .join('')}
              </div>`,
      participant_attandances: `${emailData?.participants
        ?.map((item) => {
          const registered_schedule = item.registered_schedule;
          return `
                      <div>
                          <ul>
                            <li>${emailData?.registran?.title ?? '-'} ${emailData?.registran?.name ?? '-'}</br>
                            Email: ${emailData?.registran?.email ?? '-'}</br>
                            Phone: ${emailData?.registran?.phone_number ?? '-'}</br>
                            Schedule: ${registered_schedule?.day ? `Day ${registered_schedule?.day}` : ''}${
            registered_schedule?.session ? `, Session ${registered_schedule?.session}` : ''
          }${registered_schedule?.session ? `, Workshop: ${registered_schedule?.workshop}` : ''}</br>
          <span>Qr Code: </span><br>
          <span><img width="200px" height="200px" src="${item.qr_code}" /></span></li>
        </ul>`;
        })
        .join('')}
                      </div>`,
      payment_pricings: `<ul style="padding-left:15px">
      ${emailData?.payment_pricings?.banks
        ?.map((bank) => {
          return `
          <li>
          <b>Bank : ${bank?.name ?? '-'}</b><br>
          <img width="100px" style="margin:10px 0;" src="${bank?.document_url ?? '-'}" />
          <br>
          <ul>
              ${emailData?.payment_pricings?.accounts
                ?.filter((item) => item?.bank_id === bank?.id)
                ?.map((item) => {
                  return `
                    <li>
                      <span>Account Number: ${item?.account_no}</span><br>
                      <span>On Behalf Of: ${item?.on_behalf_of}</span>
                    </li>
                `;
                })
                .join('')}
          </ul>
          </li>
         `;
        })
        .join('')}
   </ul>`,
    });
    setData(newTemplate);
    setLoading(false);
    return newTemplate;
  }

  useEffect(() => {
    convertAllVariable(value);
  }, [key, value]);

  return (
    <Spin spinning={loading} style={{ textAlign: 'center', minHeight: '30vh', overflow: 'hidden' }}>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Spin>
  );
}
