import { getAccessRight } from 'shared/helpers/user-category-transformer';

interface UseAccessRight {
  accessCancel: boolean;
  accessChangeStatus: boolean;
  accessConfirmProcess: boolean;
  accessCreate: boolean;
  accessEdit: boolean;
  accessDelete: boolean;
  accessExport: boolean;
  accessImport: boolean;
  accessPrint: boolean;
  accessReverse: boolean;
  accessView: boolean;
  accessViewLog: boolean;
  accessViewNotes: boolean;
}

export function useAccessRight({
  moduleKey,
  subModuleKey,
}: {
  moduleKey: string;
  subModuleKey?: string;
}): UseAccessRight {
  const accessRight = getAccessRight(moduleKey, subModuleKey);
  return {
    accessCancel: accessRight?.cancel,
    accessChangeStatus: accessRight?.change_status,
    accessConfirmProcess: accessRight?.confirm_process,
    accessCreate: accessRight?.create,
    accessEdit: accessRight?.edit,
    accessDelete: accessRight?.delete,
    accessExport: accessRight?.export,
    accessImport: accessRight?.import,
    accessPrint: accessRight?.print,
    accessReverse: accessRight?.reverse,
    accessView: accessRight?.view,
    accessViewLog: accessRight?.view_log,
    accessViewNotes: accessRight?.view_notes,
  };
}
