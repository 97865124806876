import { ColumnsType } from 'antd/lib/table';
import { IndexPageWrapper, AntdDataTable, usePageProvider, authHelper } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';
import { getAccessRight } from 'shared/helpers/user-category-transformer';

export default function IndexPage() {
  const { moduleKey } = usePageProvider();
  const accessRight = getAccessRight(moduleKey);

  let actionDisabled: any[] = [];
  const showDetail = accessRight?.view;
  const showUpdate = accessRight?.edit;
  const accessConfirmProcess = accessRight?.confirm_process;
  const accessDelete = accessRight?.delete;
  const accessChangeStatus = accessRight?.change_status;
  const accessPrint = accessRight?.print;
  const accessCreate = accessRight?.create;

  if (!showDetail) actionDisabled = [...actionDisabled, 'detail'];
  if (!showUpdate) actionDisabled = [...actionDisabled, 'update'];
  if (!accessCreate) actionDisabled = [...actionDisabled, 'duplicate'];
  if (!accessChangeStatus) actionDisabled = [...actionDisabled, 'activate', 'deactivate'];
  if (!accessDelete) actionDisabled = [...actionDisabled, 'delete'];

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: true,
    },
  ];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Report',
        showSearchBadge: true,
        showFilterBadge: true,
        disableNewData: !accessCreate,
        disableConfirmProcess: !accessConfirmProcess,
        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        disablePrint: !accessPrint,
        showFilter: false,
        showActivate: false,
        showDeactivate: false,
        showConfirmProcess: false,
        showBack: false,
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          defaultColumnIgnored={['status']}
          rowActionProps={{
            // actionDisabled: actionDisabled,
            actionIgnored: [...actionDisabled, 'deactivate'],
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
