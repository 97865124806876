import { useEffect, useState } from 'react';
import { mockCompanies } from '@states';
import {
  ActionProps,
  IndexPageWrapper,
  makeCommonDataSource,
  notificationFailed,
  notificationSuccess,
  SinglePage,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { useRecoilState } from 'recoil';
import { BankAccountColumns } from '../../helpers';
import BankForm from '../bank-form';
import BankDetail from '../bank-detail';
import { ApiUrlData, moduleKey } from '@base-configs';
import { v4 as uuidV4 } from 'uuid';
import { omit } from 'lodash';
import { useAccessRight } from 'shared/hooks';
import { config } from 'process';

export default function IndexPage({
  bankId,
  status,
  triggerRequest,
}: {
  bankId: string;
  status: any;
  triggerRequest: string;
}): JSX.Element {
  const { dataSource } = usePageProvider();
  const accountDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.accounts });

  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [companies] = useRecoilState(mockCompanies);
  let reloadFunction: any = undefined;
  const [firstRender, setFirstRender] = useState(true);

  const { accessCreate, accessEdit, accessChangeStatus, accessDelete, accessView } = useAccessRight({
    moduleKey: moduleKey.BANK_ACCOUNT,
  });
  let actionIgnored: any[] = ['confirm_process'];
  if (!accessCreate) actionIgnored = [...actionIgnored, 'duplicate'];
  if (!accessEdit) actionIgnored = [...actionIgnored, 'update'];
  if (!accessChangeStatus) actionIgnored = [...actionIgnored, 'activate', 'deactivate'];
  if (!accessView) actionIgnored = [...actionIgnored, 'detail'];
  if (!accessDelete) actionIgnored = [...actionIgnored, 'delete'];

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  async function handleConfirmProcess(payload, { onReloadTable }): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: ({ response }: any) => {
        setVisibleDrawerForm(false);
        if (onReloadTable) onReloadTable();
      },
      onFailed: ({ message }: any) => {
        console.log('failed');
      },
    });
  }

  async function functionCreate(payload: any, configs: any): Promise<void> {
    let httpDataSource = undefined;
    if (payload.field_type === 'bank_account') httpDataSource = accountDataSource;
    else if (payload.field_type === 'bank') httpDataSource = dataSource;
    const newPayload = omit(payload, ['field_type', 'key', 'id', 'data_id']);
    Object.assign(newPayload, { all_region: 'false' });

    await httpDataSource.handleCreate(newPayload, {
      onSuccess: ({ response }) => {
        handleConfirmProcess(response, configs);
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function functionUpdate(payload: any, configs: any): Promise<void> {
    let httpDataSource = undefined;
    if (payload.field_type === 'bank_account') httpDataSource = accountDataSource;
    else if (payload.field_type === 'bank') httpDataSource = dataSource;
    const newPayload = omit(payload, ['field_type', 'key', 'id', 'data_id']);
    Object.assign(newPayload, { all_region: 'false' });

    await dataSource.handleUpdate(payload?.data_id, newPayload, {
      onSuccess: ({ response }) => {
        handleConfirmProcess(response, configs);
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  useEffect(() => {
    if (!!triggerRequest && reloadFunction && !firstRender) {
      reloadFunction();
    } else {
      setFirstRender(false);
    }
  }, [triggerRequest]);

  return (
    <IndexPageWrapper showPageHeader={false} padding={[]}>
      <SinglePage
        getFunctionReloadTable={(fun) => {
          reloadFunction = fun;
        }}
        drawerFormProps={{
          forceRender: true,
          functionCreate,
          functionUpdate,
          title: 'Bank & Account',
          action: actionForm,
          visible: visibleDrawerForm,
          onClose: onCloseDrawerForm,
          children: <BankForm actionForm={actionForm} />,
          onShow: onShowDrawerForm,
          initialValues: {
            company: !!companies?.length ? companies[0] : null,
            field_type: 'bank',
            document_id: uuidV4(),
          },
          ignoreKeyDuplicate: ['account_no', 'document_url'],
        }}
        drawerDetailProps={{
          forceRender: true,
          title: 'Bank & Account',
          visible: visibleDrawerDetail,
          onClose: onCloseDrawerDetail,
          forceRequest: true,
          children: <BankDetail />,
          onShow: onShowDrawerDetail,
        }}
        dataTableProps={{
          rowSelection: null,
          columns: BankAccountColumns,
          defaultFilterRequestData: {
            bank_ids: [bankId],
          },
          rowActionProps: {
            actionIgnored,
          },
        }}
      />
    </IndexPageWrapper>
  );
}
