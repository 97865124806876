import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import { OfficerAttendanceEntity } from '../entities';
import moment from 'moment';
import { convertEpochToMoment } from '@pages/event/event-data/domain/transformer/helpers';

export class OfficerAttendanceTransformer<E extends BaseEntity = OfficerAttendanceEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    return {
      ...payload?.detail_data,
      check_in_date: payload?.check_in_date ? moment(payload?.check_in_date)?.valueOf() : null,
    };
  }

  transformerGetData(payload: E): E {
    const cleanVal = {
      ...payload,
      check_in_date: payload?.check_in_date ? convertEpochToMoment({ date: payload?.check_in_date }) : moment(),
    };

    return cleanVal;
  }

  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return payload;
    return payload.map((item) => {
      return {
        ...item,
      };
    });
  }
}
