import { Fragment, ReactNode, useState } from 'react';
import { dateFormatter, notificationFailed, usePageProvider } from '@eigen3m/react-base-frontend';
import { Button, Card, Checkbox, Col, Form, Modal, Radio, Row, Skeleton } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import * as lodash from 'lodash';

import { DataEvent } from '@pages/event/event-activity/presentation/helpers/data-event';
import { ApiUrlData } from '@base-configs';

import { RegistrationFormHeader, RegistrationFormInput } from './components';
import { CalculateResult } from '@components/calculate-result';
import { InitialValuesType } from './types';

import './index.less';
import { dummyRegsitration } from './dummy';
import { typeOf } from 'mathjs';

const initialValues: InitialValuesType = {
  is_join_payment: true,
  registrans: [
    {
      title: null,
      name: null,
      date_of_birth: null,
      age: null,
      marital_status: null,
      address: null,
      email: null,
      phone_number: null,
    },
  ],
  // ...dummyRegsitration,
};

function calculateAge(date) {
  if (date) {
    const diff = moment(date).diff(moment(), 'year', true);
    const age = Math.abs(diff);
    return Math.floor(age);
  }
  return null;
}

function modalFailed(message: string, errorResponse: any): void {
  return notificationFailed([`${message}, ${errorResponse}`]);
}

export default function RegistrationForm({
  payments,
  registration,
  participants,
  id,
  withOnlyAdmin = true,
}: DataEvent): JSX.Element {
  const [checked, setChecked] = useState<boolean>(true);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceResult, setInvoiceResult] = useState<any>(null);
  const [payloadGenerateInvoice, setPayloadGenerateInvoice] = useState<any>(null);
  const [form] = Form.useForm();

  const { dataSource } = usePageProvider();
  const priceConfig = payments?.price_configuration;
  const registrationScheduleStatus = registration?.status;
  const registrationConfig = registration?.registration_config;
  const isOnlyAdmin = !!withOnlyAdmin && registration?.only_admin;

  function modalInformation() {
    return Modal.info({
      title: priceConfig === 'Free Event' ? 'Thank You' : 'One More Step',
      content: ModalContent(),
      mask: true,
    });
  }

  function onFinishRegistration(item: any): void {
    Modal.confirm({
      title: 'Confirm Registration',
      content: 'You want to registration this event ?',
      onOk: () => onFinish(item),
    });
  }

  function ModalContent(): ReactNode {
    const dateNow = moment();
    const dueDate = dateNow.add('1', 'day').format('DD-MM-YYYY HH:mm:ss');

    if (priceConfig === 'Free Event') {
      return <div>Check your email to see event information</div>;
    }

    return (
      <div>
        Please make payment before {dueDate} and confirm your Payment through confirmation link which has been sent to
        your email, or your registration will be cancelled
      </div>
    );
  }

  function onValuesChange(item, values) {
    let newValues = null;
    if (item?.hasOwnProperty('is_join_payment')) {
      newValues = {
        ...values,
        is_join_payment: values?.is_join_payment,
      };
      calculateInvoices();
    }
    setInvoiceResult([]);
    setShowInvoice(false);
    return newValues;
  }

  async function calculateInvoices(): Promise<void> {
    const data = form.getFieldsValue();
    const newDataParticipant = {
      ...data,
      registrans: data?.registrans?.map((item) => {
        return {
          ...item,
          title: 'Mrs',
          name: !!item?.name ? item?.name : 'Anonymous',
        };
      }),
    };

    const registrans = newDataParticipant?.registrans;
    const newRegistrans = registrans?.map((item) => {
      const cleanSubSchedule = lodash.omit(item, ['sub_schedule']);
      const schedule = item?.schedule ?? [];
      const subSchedule = item?.sub_schedule ?? [];
      const dateOfBirth = !!cleanSubSchedule?.date_of_birth
        ? dateFormatter(cleanSubSchedule?.date_of_birth).toLocal('YYYY-MM-DD')
        : null;

      const newSchedule = schedule?.map((data) => {
        return {
          registration_config: registrationConfig,
          day: data?.day?.no_day ?? null,
          session: data?.session?.no_session ?? null,
          workshop: data?.workshop?.name ?? null,
        };
      });

      const newSubSchedule = subSchedule?.map((data) => {
        return {
          registration_config: registrationConfig,
          is_sub_event: true,
          day: data?.sub_day?.no_day ?? null,
          session: data?.sub_session?.no_session ?? null,
          workshop: data?.sub_workshop?.name ?? null,
        };
      });

      const scheduleWithSub = [...newSchedule, ...newSubSchedule];

      return {
        ...cleanSubSchedule,
        date_of_birth: dateOfBirth,
        schedule: subSchedule?.length ? scheduleWithSub : newSchedule,
        member: false,
        event: {
          id,
        },
      };
    });

    const registransPerEventWithSubEvent = newRegistrans?.filter((item) => !!item?.schedule?.length);
    const newRegistransPerEventOnly = registransPerEventWithSubEvent?.map((item) => {
      return {
        ...item,
        schedule: [],
      };
    });

    const newData = {
      ...data,
      registrans: [
        ...newRegistrans,
        ...(registrationConfig?.toLowerCase()?.includes('event') && !!newRegistransPerEventOnly?.length
          ? newRegistransPerEventOnly
          : []),
      ],
      event: {
        id,
      },
    };

    const registranHasSchedule = newData?.registrans?.every((item) => !!item?.schedule?.length);
    const isValidRegistrans =
      registrationConfig?.toLowerCase()?.includes('event') ||
      (!registrationConfig?.toLowerCase()?.includes('event') && registranHasSchedule);

    if (isValidRegistrans) {
      setLoading(true);
      if (newData) setPayloadGenerateInvoice(newData);
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.calculate_invoice,
          data: newData,
        },
        onSuccess: ({ response }: any): void => {
          setLoading(false);
          setInvoiceResult(response);
          setShowInvoice(true);
          form.setFieldsValue(newDataParticipant);
        },
        onFailed: ({ message }: any): void => {
          modalFailed('Error when Calculating Invoice', message);
          setLoading(false);
          setShowInvoice(false);
        },
      });
    } else {
      notificationFailed(['Please choose schedule before calculate invoice']);
    }
  }

  async function onFinish(item: any): Promise<void> {
    // const cleanData = lodash.omit(item, ['number_of_participant', 'registrans']);
    // const cleanInvoice = lodash.omit(invoiceResult, ['items', 'total_invoices']);
    // const registrans = item?.registrans;
    // const isJoinPayment = item?.is_join_payment;

    // const newRegistrans = registrans?.map((item, index) => {
    //   const cleanSubSchedule = lodash.omit(item, ['sub_schedule']);
    //   const dateOfBirth = !!cleanSubSchedule?.date_of_birth
    //     ? dateFormatter(cleanSubSchedule?.date_of_birth).toLocal('YYYY-MM-DD')
    //     : null;

    //   const formatInvoiceResult = invoiceResult?.items[index].pricing_type?.map((result) => {
    //     return result.hasOwnProperty('is_sub_event')
    //       ? {
    //           registration_config: null,
    //           is_sub_event: true,
    //           day: result?.day,
    //           session: result?.session,
    //           workshop: result?.workshop,
    //           price: {
    //             id: result?.price?.id,
    //             name: result?.price?.name,
    //             price: result?.price?.price,
    //             need_confirm: result?.price?.need_confirm,
    //           },
    //         }
    //       : {
    //           registration_config: registrationConfig,
    //           day: result?.day,
    //           session: result?.session,
    //           workshop: result?.workshop,
    //           price: {
    //             id: result?.price?.id,
    //             name: result?.price?.name,
    //             price: result?.price?.price,
    //             need_confirm: result?.price?.need_confirm,
    //           },
    //         };
    //   });

    //   return {
    //     ...cleanSubSchedule,
    //     title: !!item?.title ? item?.title : null,
    //     age: typeOf(item?.age) === 'number' && item?.age >= 0 ? item?.age : null,
    //     marital_status: !!item?.marital_status ? item?.marital_status : null,
    //     phone_number: !!item?.phone_number ? item?.phone_number : null,
    //     address: !!item?.address ? item?.address : null,
    //     date_of_birth: dateOfBirth,
    //     registration_date: moment().valueOf(),
    //     schedule: formatInvoiceResult,
    //     event: {
    //       id,
    //     },
    //   };
    // });

    // const formatInvoice = !isJoinPayment
    //   ? invoiceResult?.items?.map((invoice, index) => {
    //       return {
    //         event: { id },
    //         is_free: false,
    //         is_join_payment: cleanInvoice?.is_join_payment,
    //         need_confirm: false,
    //         registrans: [newRegistrans[index]],
    //         total_invoice: invoice?.total_invoice,
    //       };
    //     })
    //   : [
    //       {
    //         ...cleanInvoice,
    //         event: { id },
    //         is_free: priceConfig === 'Free Event' ? true : false,
    //         need_confirm: invoiceResult?.items[0]?.pricing_type[0]?.price?.need_confirm,
    //         registrans: newRegistrans,
    //         total_invoice: invoiceResult?.total_invoices,
    //       },
    //     ];

    // let formatInvoiceJoinPayment = [];
    // let formatInvoiceSplitPayment = [];
    // if (isJoinPayment) {
    //   /**
    //    * Format invoice join payment
    //    * - Invoice paid
    //    * - Invoice frre with confirm
    //    * - Invoice free with no confirm
    //    */

    //   //Invoice paid
    //   const formatInvoiceJoinPaid = formatInvoice?.map((invoice) => {
    //     const registrans = !!invoice?.registrans?.length ? invoice?.registrans : [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = !!registran?.schedule ? registran?.schedule : [];
    //       const newSchedule = schedules?.filter((schedule) => schedule?.price?.price > 0);
    //       return {
    //         ...registran,
    //         schedule: newSchedule,
    //       };
    //     });

    //     return {
    //       ...item,
    //       is_free: false,
    //       need_confirm: false,
    //       event: {
    //         id,
    //       },
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       total_invoice: invoice?.total_invoice,
    //     };
    //   });
    //   const filteredFormatInvoiceJoinPaid = formatInvoiceJoinPaid?.filter((item) => !!item?.registrans?.length);

    //   //Invoice free with confirm
    //   const formatInvoiceJoinFreeNeedConfirm = formatInvoice?.map((invoice) => {
    //     const registrans = !!invoice?.registrans?.length ? invoice?.registrans : [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = !!registran?.schedule ? registran?.schedule : [];
    //       const newSchedule = schedules?.filter(
    //         (schedule) => schedule?.price?.price === 0 && !!schedule?.price?.need_confirm,
    //       );
    //       return {
    //         ...registran,
    //         schedule: newSchedule,
    //       };
    //     });
    //     return {
    //       ...item,
    //       is_free: true,
    //       need_confirm: true,
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       event: {
    //         id,
    //       },
    //       total_invoice: 0,
    //     };
    //   });
    //   const filteredFormatInvoiceJoinFreeNeedConfirm = formatInvoiceJoinFreeNeedConfirm?.filter(
    //     (item) => !!item?.registrans?.length,
    //   );

    //   //Invoice free with no confirm
    //   const formatInvoiceJoinFreeNoConfirm = formatInvoice?.map((invoice) => {
    //     const registrans = !!invoice?.registrans?.length ? invoice?.registrans : [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = !!registran?.schedule ? registran?.schedule : [];
    //       const newSchedule = schedules?.filter(
    //         (schedule) =>
    //           schedule?.price?.price === 0 &&
    //           (!schedule?.price?.need_confirm || schedule?.price?.need_confirm === false),
    //       );
    //       return {
    //         ...registran,
    //         schedule: newSchedule,
    //       };
    //     });

    //     return {
    //       ...item,
    //       is_free: true,
    //       need_confirm: false,
    //       event: {
    //         id,
    //       },
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       total_invoice: 0,
    //     };
    //   });
    //   const filteredFormatInvoiceJoinFreeNoConfirm = formatInvoiceJoinFreeNoConfirm?.filter(
    //     (item) => !!item?.registrans?.length,
    //   );

    //   formatInvoiceJoinPayment = [
    //     ...(!!filteredFormatInvoiceJoinPaid?.length ? filteredFormatInvoiceJoinPaid : []),
    //     ...(!!filteredFormatInvoiceJoinFreeNeedConfirm?.length ? filteredFormatInvoiceJoinFreeNeedConfirm : []),
    //     ...(!!filteredFormatInvoiceJoinFreeNoConfirm?.length ? filteredFormatInvoiceJoinFreeNoConfirm : []),
    //   ];
    // } else {
    //   /**
    //    * Format invoice split payment
    //    * - Invoice paid
    //    * - Invoice frre with confirm
    //    * - Invoice free with no confirm
    //    */

    //   //Invoice Paid
    //   const formatInvoiceSplitPaid = formatInvoice?.map((invoice) => {
    //     const registrans = invoice?.registrans ?? [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = registran?.schedule ?? [];
    //       const freeConfirmSchedules = schedules?.filter(
    //         (schedule) => schedule?.price?.price !== 0 && !schedule?.price?.need_confirm,
    //       );
    //       return {
    //         ...registran,
    //         schedule: freeConfirmSchedules,
    //       };
    //     });

    //     return {
    //       ...invoice,
    //       is_free: false,
    //       need_confirm: false,
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       total_invoice: invoice?.total_invoice,
    //     };
    //   });

    //   const filteredFormatInvoiceSplitPaid = formatInvoiceSplitPaid?.filter((item) => !!item?.registrans?.length);

    //   //Invoice Free with confirm
    //   const formatInvoiceSplitFreeNeedConfirm = formatInvoice?.map((invoice) => {
    //     const registrans = invoice?.registrans ?? [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = registran?.schedule ?? [];
    //       const freeConfirmSchedules = schedules?.filter(
    //         (schedule) => schedule?.price?.price === 0 && !!schedule?.price?.need_confirm,
    //       );
    //       return {
    //         ...registran,
    //         schedule: freeConfirmSchedules,
    //       };
    //     });

    //     return {
    //       ...invoice,
    //       is_free: true,
    //       need_confirm: true,
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       total_invoice: 0,
    //     };
    //   });

    //   const filteredFormatInvoiceSplitNeedNoConfirm = formatInvoiceSplitFreeNeedConfirm?.filter(
    //     (item) => !!item?.registrans?.length,
    //   );

    //   //Invoice Free with no confirm
    //   const formatInvoiceSplitFreeNoConfirm = formatInvoice?.map((invoice) => {
    //     const registrans = invoice?.registrans ?? [];
    //     const newRegistrans = registrans?.map((registran) => {
    //       const schedules = registran?.schedule ?? [];
    //       const freeConfirmSchedules = schedules?.filter(
    //         (schedule) => schedule?.price?.price === 0 && !schedule?.price?.need_confirm,
    //       );
    //       return {
    //         ...registran,
    //         schedule: freeConfirmSchedules,
    //       };
    //     });

    //     return {
    //       ...invoice,
    //       is_free: true,
    //       need_confirm: false,
    //       registrans: newRegistrans?.filter((item) => !!item?.schedule?.length),
    //       total_invoice: 0,
    //     };
    //   });

    //   const filteredFormatInvoiceSplitFreeNoConfirm = formatInvoiceSplitFreeNoConfirm?.filter(
    //     (item) => !!item?.registrans?.length,
    //   );

    //   formatInvoiceSplitPayment = [
    //     ...(!!filteredFormatInvoiceSplitPaid?.length ? filteredFormatInvoiceSplitPaid : []),
    //     ...(!!filteredFormatInvoiceSplitNeedNoConfirm?.length ? filteredFormatInvoiceSplitNeedNoConfirm : []),
    //     ...(!!filteredFormatInvoiceSplitFreeNoConfirm?.length ? filteredFormatInvoiceSplitFreeNoConfirm : []),
    //   ];
    // }

    // const newData = {
    //   ...cleanData,
    //   event: {
    //     id,
    //   },
    //   registration_date: moment().valueOf(),
    //   invoices: isJoinPayment ? formatInvoiceJoinPayment : formatInvoiceSplitPayment,
    // };
    const newData = {
      event_id: id,
      registration_config: registrationConfig,
      registration: payloadGenerateInvoice,
      invoices: invoiceResult,
    };
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.registration + '/v2',
        data: newData,
      },
      onSuccess: (): void => {
        modalInformation();
        form.resetFields();
        setInvoiceResult([]);
        setShowInvoice(false);
      },
      onFailed: ({ message }: any): void => {
        modalFailed('Error when Registration', message);
      },
    });
  }

  function onCheckedChange(evt: CheckboxChangeEvent): void {
    calculateInvoices();
    setChecked(evt.target.checked);
  }

  return (
    <Form
      form={form}
      onFinish={onFinishRegistration}
      initialValues={initialValues}
      layout="vertical"
      onValuesChange={onValuesChange}
    >
      <Form.Item name="event" noStyle />
      <Form.Item name="is_join_payment" noStyle />
      <Form.Item name="registration_date" noStyle />

      <div className="event-form">
        {!['close', 'coming soon']?.includes(registrationScheduleStatus?.toLowerCase()) && (
          <RegistrationFormHeader registration={registration} withOnlyAdmin={withOnlyAdmin} />
        )}
        {typeOf(registrationScheduleStatus) === 'string' &&
        registrationScheduleStatus?.toLowerCase()?.includes('soon') ? (
          <Card style={{ textAlign: 'center' }}>
            <div style={{ margin: -12, fontSize: 14 }}>
              <strong>Registration Coming Soon</strong>
            </div>
          </Card>
        ) : typeOf(registrationScheduleStatus) === 'string' &&
          registrationScheduleStatus?.toLowerCase()?.includes('close') ? (
          <Card style={{ textAlign: 'center' }}>
            <div style={{ margin: -12, fontSize: 14 }}>
              <strong>Registration Has Been Closed</strong>
            </div>
          </Card>
        ) : (
          !isOnlyAdmin && (
            <RegistrationFormInput
              registration={registration}
              payments={payments}
              participants={participants}
              id={id}
            />
          )
        )}

        <div
          style={{
            display:
              isOnlyAdmin || ['close', 'coming soon']?.includes(registrationScheduleStatus?.toLowerCase())
                ? 'none'
                : 'flex',
            justifyContent: 'flex-end',
            marginBottom: 30,
            marginTop: 30,
          }}
        >
          <Form.Item>
            <Button loading={loading} onClick={calculateInvoices}>
              Calculate Invoices
            </Button>
          </Form.Item>
        </div>

        {showInvoice && (
          <Fragment>
            <Row gutter={16} style={{ marginBottom: 8 }}>
              <Col>
                <h3>Total Invoice</h3>
              </Col>
              {invoiceResult?.items?.length > 1 && (
                <Col>
                  <Form.Item name="is_join_payment" style={{ marginRight: 15 }} noStyle>
                    <Radio.Group>
                      <Radio value={true}>Join Payment</Radio>
                      <Radio value={false}>Split Payment</Radio>
                    </Radio.Group>
                    {/* <Checkbox value={checked} onChange={onCheckedChange}>
                      Join Payment
                    </Checkbox> */}
                  </Form.Item>
                </Col>
              )}
            </Row>
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <Fragment>
                <CalculateResult priceType={invoiceResult} />
                <Form.Item noStyle>
                  <Button type="primary" onClick={() => form.submit()}>
                    Join
                  </Button>
                </Form.Item>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Form>
  );
}
