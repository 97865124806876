import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { makeCommonDataSource, notificationFailed } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@main/configs/api-url';
import { v4 as uuidV4 } from 'uuid';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ASSET_URL = process.env.REACT_APP_BASE_ASSET_URL;

export function UploadImageProof({ value = [], onChange }: any) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.payment_upload });

  const [fileList, setFileList] = useState<any[]>([]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadFile = async (options) => {
    const { onError, file } = options;
    const formData = new FormData();
    formData.append('document_url', file);
    formData.append('document_id', uuidV4());
    try {
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.payment_upload,
          data: formData,
        },
        onSuccess({ response }: any) {
          const newValue = !!value?.length ? value : [];
          if (response) {
            setFileList([
              ...newValue,
              {
                uid: uuidV4(),
                name: 'image',
                status: 'done',
                image_url: response,
                url: `${ASSET_URL}${response}`,
              },
            ]);
          }
        },
        onFailed({ message }) {
          notificationFailed([message as string]);
        },
      });
    } catch (err) {
      onError({ err });
    }
  };

  useEffect(() => {
    onChange(fileList);
  }, [fileList]);

  useEffect(() => {
    if (!!value?.length) setFileList(value);
  }, [value]);

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={(opt) => uploadFile(opt)}
      >
        {value?.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title="Payment Proof" footer={null} onCancel={handleCancel}>
        <img alt="payment-proof" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}
