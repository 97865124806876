import React, { useEffect, useState } from 'react';
import { usePageProvider } from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@base-configs';
import { Col, Layout, Row, Skeleton, Spin, Form, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { EventActivitiesDetail } from '@components/event-activities-detail';
import RegistrationForm from '@components/registration-form';
const { Content } = Layout;
const assetUrl = process.env.REACT_APP_BASE_ASSET_URL;

export default function DetailPage() {
  const { dataSource } = usePageProvider();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();

  const handleGetData = async (id) => {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_SERVICE_URL.includes('gateway')
          ? process.env.REACT_APP_BASE_SERVICE_URL.replace('gateway', 'api')
          : process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.eventActivities + `/${id}`,
      },
      onSuccess({ response }) {
        setData(response);
      },
      onFailed(response: any) {
        console.log(response);
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    handleGetData(id);
  }, [id]);

  const bannerUrl = data?.information?.banner_url ?? data?.information?.document_url;
  const heroImageUrl = bannerUrl ? assetUrl + bannerUrl : '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <Spin spinning={loading}>
    <Layout
    // style={{ marginTop: '60px' }}
    >
      <Content
        className="content-detail-width"
        style={{
          // width: '1200px',
          margin: '0 auto',
          // padding: '40px 0',
        }}
      >
        <div id="event-detail">
          <div className="hero-image" style={{ overflow: 'hidden', width: '100%' }}>
            {loading ? (
              <Skeleton.Image className="hero-image" style={{ width: '1200px' }} active />
            ) : (
              <img
                title={data?.information?.name}
                src={heroImageUrl}
                style={{ width: '100%', height: '450px', borderRadius: '8px 8px 0 0', objectFit: 'cover' }}
              />
            )}

            {/* <div className="title">
                <h1>
                  <strong>{data?.information?.name}</strong>
                </h1>
              </div> */}
          </div>
          <Spin spinning={loading}>
            <div className="event-detail-body" style={{ borderTop: '1px solid #f0f0f0' }}>
              <Row gutter={[32, 8]}>
                <Col xl={12} lg={12} md={24} sm={24} style={{ borderRight: '1px solid #fafafa' }}>
                  <div>
                    <div style={{ fontSize: 30 }}>
                      <strong>{data?.information?.name}</strong>
                    </div>
                    <div style={{ marginBottom: 20 }}></div>
                  </div>
                  <EventActivitiesDetail value={data} />
                </Col>
                <Col xl={12} lg={12} md={24} sm={24}>
                  {!!data && (
                    <RegistrationForm
                      registration={data?.registrations}
                      payments={data?.payment_pricings}
                      id={id}
                      participants={data?.participants}
                      withOnlyAdmin
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    </Layout>
    // </Spin>
  );
}
