import React, { useEffect, useState } from 'react';
import { ActionProps, FormBuilder, Select } from '@eigen3m/react-base-frontend';
import { columnsDaySessionWorkshop, columnsOfficer } from '../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import { ButtonNewData } from '@components/button';
import { DrawerForm } from '@components/drawer';
import { DataTable } from '@components/table';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Table } from 'antd';
import { makeLabelValue } from '../form-payment-and-pricing/helpers';
import { ApiUrlData } from '@base-configs';
interface Props {
  value?: any;
  onChange?: (value: any) => any;
  optionsDayMain?: any[];
  optionsDaySub?: any[];
  attendanceConfig?: string;
}

export function FormOfficer(props: Props) {
  const { value, onChange, optionsDayMain, optionsDaySub, attendanceConfig } = props;
  const [action, setAction] = useState<ActionProps>('create');
  const [visibleOfficer, setVisibleOfficer] = useState(false);
  const [dataOfficer, setDataOfficer] = useState([]);
  const [dataFormOfficer, setDataFormOfficer] = useState(null);

  useEffect(() => {
    let newValue = value ?? [];
    if (!!newValue?.length) {
      newValue = newValue?.map((item) => {
        const newItem = {
          key: item?.key ?? item?.id ?? item.uuid,
          ...item,
        };
        return newItem;
      });
    }
    setDataOfficer(newValue);
  }, [value]);

  function onChangeValues(value) {
    if (onChange) {
      onChange(value);
    }
  }

  const onShowOfficer = () => {
    setAction('create');
    setDataFormOfficer(null);
    setVisibleOfficer(true);
  };

  const onCloseOfficer = () => {
    setVisibleOfficer(false);
    setDataFormOfficer(null);
  };

  const onChangeOfficer = (payload: any) => {
    let newData = dataOfficer;
    const daySessionWorkshops = payload?.day_session_workshops ?? [];
    const subDaySessionWorkshops = payload?.sub_day_session_workshops ?? [];
    const newPayload = {
      ...payload,
      key: payload?.id ?? payload?.uuid ?? payload?.key ?? uuidv4(),
      day_session_workshops: daySessionWorkshops?.filter((item) => !!item?.day),
      sub_day_session_workshops: subDaySessionWorkshops?.filter((item) => !!item?.day),
    };
    if (action === 'create') {
      newData = [...dataOfficer, newPayload];
    } else {
      newData = dataOfficer?.map((item) => {
        if (item?.key === newPayload?.key) {
          return {
            ...item,
            ...newPayload,
          };
        }
        return item;
      });
    }
    onChangeValues(newData);
  };

  const handleClickDelete = (row) => {
    const newData = dataOfficer?.filter((item) => item?.key !== row?.key);
    setDataOfficer(newData);
    onChangeValues(newData);
  };
  const handleClickUpdate = (row) => {
    const hasScheduleItem = !!row?.day_session_workshops?.length ? true : false;
    const isEacDayOrSession = ['each day', 'each session'].includes(attendanceConfig?.toLowerCase()) ? true : false;
    setAction('update');
    setVisibleOfficer(true);
    setDataFormOfficer({
      ...row,
      day_session_workshops: isEacDayOrSession
        ? hasScheduleItem
          ? row?.day_session_workshops
          : [{ day: null, session: null, workshop: [] }]
        : [],
      sub_day_session_workshops:
        row?.sub_day_session_workshops?.length >= 1
          ? row?.sub_day_session_workshops
          : [{ day: null, session: null, workshop: [] }],
    });
  };

  return (
    <React.Fragment>
      <ButtonNewData onClick={onShowOfficer} style={{ marginBottom: '10px' }} />
      <DrawerForm
        width={600}
        action={action}
        value={dataFormOfficer}
        visible={visibleOfficer}
        onClose={onCloseOfficer}
        onChange={onChangeOfficer}
      >
        <FormBuilder
          className="mt-0"
          columns={[
            {
              fields: [
                {
                  label: 'Name',
                  name: 'officer',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 24 },
                  rules: [{ required: true, message: 'Name is required.' }],
                  selectPaginateProps: {
                    customFilterRequest: () => {
                      return {
                        statuses: ['active'],
                        is_speaker: false,
                        is_user_exist: true,
                      };
                    },
                    dataSourceUrl: ApiUrlData?.contact,
                    classNamePrefix: 'react-select-custom-prefix-radius',
                    customLabel(row) {
                      return row?.name;
                    },
                    keySearch: 'name',
                  },
                },
                {
                  gridColumn: { span: 24 },
                  renderField(form) {
                    return (
                      <Form.Item>
                        <Card
                          style={{ marginBottom: 20, backgroundColor: '#f9f9f9' }}
                          hidden={attendanceConfig?.toLowerCase()?.includes('event')}
                          title="Choose Main Event"
                          headStyle={{ padding: '0 15px' }}
                          bodyStyle={{ padding: '15px' }}
                        >
                          <Form.List
                            name="day_session_workshops"
                            initialValue={[
                              {
                                day: null,
                                session: null,
                              },
                            ]}
                          >
                            {(fields, { add, remove }) => {
                              return fields.map((field, index) => {
                                const newOptionsDay = makeLabelValue({
                                  values: optionsDayMain,
                                  keyLabel: 'no',
                                  typeLabel: 'Day',
                                });
                                const isRequiredDay =
                                  !!attendanceConfig &&
                                  ['each day', 'each session'].includes(attendanceConfig?.toLowerCase()) &&
                                  !!optionsDayMain?.length
                                    ? true
                                    : false;

                                const day = form.getFieldValue(['day_session_workshops', index, 'day']);
                                const optionsSession = day?.sessions ?? [];
                                const newOptionsSession = makeLabelValue({
                                  values: optionsSession,
                                  keyLabel: 'no',
                                  typeLabel: 'Session',
                                });
                                const isDisabledSession =
                                  !!attendanceConfig && attendanceConfig?.toLowerCase() === 'each day'
                                    ? true
                                    : !!optionsSession?.length
                                    ? false
                                    : true;

                                const isRequiredSession =
                                  !!attendanceConfig &&
                                  attendanceConfig?.toLowerCase() === 'each session' &&
                                  !!optionsSession?.length
                                    ? true
                                    : false;

                                const session = form.getFieldValue(['day_session_workshops', index, 'session']);
                                const optionsWorkshop = session?.workshops ?? [];
                                const newOptionsWorkshop = optionsWorkshop?.map((item) => {
                                  return {
                                    label: item?.name,
                                    value: item,
                                  };
                                });
                                const isDisabledWorkshop = !!optionsWorkshop?.length ? false : true;
                                const isRequiredWorkshop =
                                  !!attendanceConfig &&
                                  attendanceConfig?.toLowerCase() === 'each session' &&
                                  !!optionsWorkshop?.length
                                    ? true
                                    : false;
                                return (
                                  <div
                                    key={field.key}
                                    className="item-day-session"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: fields.length > 1 ? 'calc(100% - 80px)' : 'calc(100% - 45px)',
                                      }}
                                    >
                                      <Form.Item
                                        label="Day"
                                        name={[field.name, 'day']}
                                        style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                        rules={[{ required: isRequiredDay, message: 'Day is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsDay}
                                          customLabel={(value) => {
                                            const noDay = value?.no;
                                            return `Day ${noDay}`;
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Session"
                                        name={[field.name, 'session']}
                                        style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                        rules={[{ required: isRequiredSession, message: 'Session is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsSession}
                                          isDisabled={isDisabledSession}
                                          customLabel={(value) => {
                                            const noSession = value?.no;
                                            return `Session ${noSession}`;
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Workshop"
                                        name={[field.name, 'workshop']}
                                        style={{ width: 'calc(50% - 5px)' }}
                                        rules={[{ required: isRequiredWorkshop, message: 'Session is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsWorkshop}
                                          isDisabled={isDisabledWorkshop}
                                          customLabel={(value) => {
                                            return value?.name;
                                          }}
                                          isMulti
                                        />
                                      </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', width: fields.length > 1 ? '70px' : '35px' }}>
                                      <Form.Item label="&#32;">
                                        {fields.length > 1 && (
                                          <Button
                                            icon={<MinusOutlined />}
                                            onClick={() => remove(field.name)}
                                            style={{ width: '30px', marginRight: fields.length > 1 ? '10px' : '0px' }}
                                          />
                                        )}
                                        {fields.length - 1 === index && (
                                          <Button
                                            icon={<PlusOutlined />}
                                            onClick={() => add()}
                                            style={{ width: '30px' }}
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                  </div>
                                );
                              });
                            }}
                          </Form.List>
                        </Card>
                        <Card
                          style={{ backgroundColor: '#f9f9f9' }}
                          hidden={optionsDaySub?.length >= 1 ? false : true}
                          title="Choose Sub Event"
                          headStyle={{ padding: '0 15px' }}
                          bodyStyle={{ padding: '15px' }}
                        >
                          <Form.List
                            name="sub_day_session_workshops"
                            initialValue={[
                              {
                                day: null,
                                session: null,
                              },
                            ]}
                          >
                            {(fields, { add, remove }) => {
                              return fields.map((field, index) => {
                                const newOptionsDay = makeLabelValue({
                                  values: optionsDaySub,
                                  keyLabel: 'no',
                                  typeLabel: 'Day',
                                });
                                const isRequiredDay = optionsDaySub?.length >= 1 ? true : false;

                                const day = form.getFieldValue(['sub_day_session_workshops', index, 'day']);
                                const optionsSession = day?.sessions ?? [];
                                const newOptionsSession = makeLabelValue({
                                  values: optionsSession,
                                  keyLabel: 'no',
                                  typeLabel: 'Session',
                                });
                                const isDisabledSession = optionsSession?.length >= 1 ? false : true;
                                const isRequiredSession = optionsSession?.length >= 1 ? true : false;

                                const session = form.getFieldValue(['sub_day_session_workshops', index, 'session']);
                                const optionsWorkshop = session?.workshops ?? [];
                                const newOptionsWorkshop = optionsWorkshop?.map((item) => {
                                  return {
                                    label: item?.name,
                                    value: item,
                                  };
                                });
                                const isDisabledWorkshop = optionsWorkshop?.length >= 1 ? false : true;
                                const isRequiredWorkshop = optionsWorkshop?.length >= 1 ? true : false;
                                return (
                                  <div
                                    key={field.key}
                                    className="item-day-session"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: fields.length > 1 ? 'calc(100% - 80px)' : 'calc(100% - 45px)',
                                      }}
                                    >
                                      <Form.Item
                                        label="Day"
                                        name={[field.name, 'day']}
                                        style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                        rules={[{ required: isRequiredDay, message: 'Day is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsDay}
                                          customLabel={(value) => {
                                            const noDay = value?.no;
                                            return `Day ${noDay}`;
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Session"
                                        name={[field.name, 'session']}
                                        style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                                        rules={[{ required: isRequiredSession, message: 'Session is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsSession}
                                          isDisabled={isDisabledSession}
                                          customLabel={(value) => {
                                            const noSession = value?.no;
                                            return `Session ${noSession}`;
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Workshop"
                                        name={[field.name, 'workshop']}
                                        style={{ width: 'calc(50% - 5px)' }}
                                        rules={[{ required: isRequiredWorkshop, message: 'Session is required.' }]}
                                      >
                                        <Select
                                          classNamePrefix="react-select-custom-prefix-radius"
                                          options={newOptionsWorkshop}
                                          isDisabled={isDisabledWorkshop}
                                          customLabel={(value) => {
                                            return value?.name;
                                          }}
                                          isMulti
                                        />
                                      </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', width: fields.length > 1 ? '70px' : '35px' }}>
                                      <Form.Item label="&#32;">
                                        {fields.length > 1 && (
                                          <Button
                                            icon={<MinusOutlined />}
                                            onClick={() => remove(field.name)}
                                            style={{ width: '30px', marginRight: fields.length > 1 ? '10px' : '0px' }}
                                          />
                                        )}
                                        {fields.length - 1 === index && (
                                          <Button
                                            icon={<PlusOutlined />}
                                            onClick={() => add()}
                                            style={{ width: '30px' }}
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                  </div>
                                );
                              });
                            }}
                          </Form.List>
                        </Card>
                      </Form.Item>
                    );
                  },
                  // customHidden() {
                  //   const isHidden =
                  //     !!attendanceConfig && ['once per event'].includes(attendanceConfig?.toLowerCase()) ? true : false;
                  //   return isHidden;
                  // },
                },
              ],
            },
          ]}
        />
      </DrawerForm>
      <DataTable
        columns={columnsOfficer}
        dataSource={dataOfficer}
        buttonActionProps={{
          width: 70,
          actionIgnored: ['detail', 'create'],
          handleClickDelete(row) {
            handleClickDelete(row);
          },
          handleClickUpdate(row) {
            handleClickUpdate(row);
          },
        }}
        size="small"
        bordered
        expandable={{
          rowExpandable: (record) =>
            record?.day_session_workshops?.length >= 1 || record?.sub_day_session_workshops?.length >= 1 ? true : false,
          expandedRowRender: (record) => {
            const daySessionWorkshop = record?.day_session_workshops ?? [];
            const subDaySessionWorkshop = record?.sub_day_session_workshops ?? [];
            const allDaySessionWorkshop = [...daySessionWorkshop, ...subDaySessionWorkshop];
            return (
              <Table
                columns={columnsDaySessionWorkshop}
                dataSource={allDaySessionWorkshop}
                pagination={false}
                size="small"
                bordered
              />
            );
          },
        }}
      />
    </React.Fragment>
  );
}
