export function currencyFormat(num: any) {
  return 'IDR ' + num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function reduceText(itemText: any, length?: number) {
  const maxLengthOfLink = !!length ? length : 75;
  const itemTextArray = itemText.split(' ');
  let reducedFlag = -1;

  return itemTextArray.reduce((accumulator, currentVal) => {
    if (accumulator.length + currentVal.length > maxLengthOfLink) {
      reducedFlag++;
      return reducedFlag ? accumulator : `${accumulator}...`;
    } else {
      return `${accumulator} ${currentVal}`;
    }
  });
}

export const data = [
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
  {
    id: 1,
    information: {
      name: 'Card 1',
      banner_url: 'wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png',
      event_type: 'On site',
      description: 'Lorem fdskjfksdjf fsdjfksdf sdjfksdjf fksdjfs sdjklfjsdkljfsdf sjfkf',
      venue: {
        name: 'Sabuga',
      },
      event_category: {
        name: 'Stand Up',
      },
    },
    date_start: null,
    date_end: null,
    lowest_price: 10000,
    highest_price: 10000,
    age_max: 19,
    age_min: 15,
  },
];
