import moment from 'moment';
import parse from 'html-react-parser';
import { sortBy } from 'lodash';
export function ScheduleList({ value }: any) {
  const data =
    !!value?.days && !!value?.days?.length
      ? sortBy(value?.days, [
          function (o) {
            return o.no;
          },
        ])
      : [];
  const description = value?.description ? parse(value?.description) : null;
  return (
    <>
      {description && <p>{description}</p>}
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {data?.map((item, key) => {
          const time = item?.time ?? [];
          const startTime = !!time?.length ? moment(time[0]).format('HH:mm') : '-';
          const endTime = !!time?.length ? moment(time[1]).format('HH:mm') : '-';

          const sessions = item?.sessions ?? [];
          return (
            <li key={key}>
              <div style={{ display: 'block' }}>
                <strong>
                  Day {item?.no}: {item?.name && item?.name + ' - '} {moment(item?.date).format('DD MMM YYYY')},{' '}
                  {startTime} - {endTime}
                </strong>
                {!!item?.description && <p>{item?.description}</p>}
              </div>
              {!!sessions?.length && (
                <ul style={{ listStyleType: 'none', marginBottom: '10px' }}>
                  {sessions?.map((itemSession, keySession) => {
                    const time = itemSession?.time ?? [];
                    const startTime = !!time?.length ? moment(time[0]).format('HH:mm') : '-';
                    const endTime = !!time?.length ? moment(time[1]).format('HH:mm') : '-';

                    const workshops =
                      !!itemSession?.agendas && !!itemSession?.agendas?.length
                        ? !!itemSession?.agendas[0]?.workshops && !!itemSession?.agendas[0]?.workshops?.length
                          ? itemSession?.agendas[0]?.workshops
                          : []
                        : [];
                    return (
                      <li key={keySession}>
                        <div style={{ display: 'block' }}>
                          <strong>
                            Session {itemSession?.no}: {itemSession?.name} - {startTime} - {endTime}
                          </strong>
                          {!!itemSession?.description && <p>{itemSession?.description}</p>}
                        </div>
                        {!!workshops?.length && (
                          <ul style={{ marginBottom: '10px', marginLeft: 0 }}>
                            {workshops?.map((itemWorkshop, keyWorkshop) => {
                              const time = itemWorkshop?.time ?? [];
                              return (
                                <li key={keyWorkshop}>
                                  <div style={{ display: 'block' }}>
                                    {itemWorkshop?.name}
                                    {!!itemWorkshop?.description && <p>{itemWorkshop?.description}</p>}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}
