import { FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { mockCompanies } from '@states';
import { Card, Form } from 'antd';
import { useRecoilState } from 'recoil';
import { FormTabs } from '../components/form/form-tabs';
import { DataTabKey } from '../helpers';

export default function FormPage() {
  const { isCreate, isUpdate, isDuplicate } = usePageProvider();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';
  const [companies] = useRecoilState(mockCompanies);

  function onChangeForm(item, values) {
    let newValues = null;
    if (Object.keys(item).includes('company')) {
    } else {
      const subKey: any = Object.keys(item)[0];
      if (Object.values(DataTabKey).includes(subKey)) {
        if (Object.keys(item[subKey]).includes('gender')) {
          const participant = values?.participants;
          const registrations = values?.registrations;
          const hasGender = !!participant?.gender;
          newValues = {
            ...values,
            registrations: {
              ...registrations,
              gender_required: hasGender,
            },
          };
        } else if (Object.keys(item[subKey]).includes('marital_status')) {
          const participant = values?.participants;
          const registrations = values?.registrations;
          const hasMaritalStatus = !!participant?.marital_status;
          newValues = {
            ...values,
            registrations: {
              ...registrations,
              marital_status_required: hasMaritalStatus,
            },
          };
        } else if (Object.keys(item[subKey]).includes('registration_config')) {
          const subValues = values[subKey];
          const paymentPricings = values?.payment_pricings;
          const attendanceOfficers = values?.attendance_officers;
          const registConfig = subValues?.registration_config?.toLowerCase();

          //Set Price Config
          let priceConfig = null;
          if (!!registConfig) {
            if (registConfig?.includes('event')) {
              priceConfig = 'Price per Event';
            } else if (registConfig?.includes('day')) {
              priceConfig = 'Price per Day';
            } else if (registConfig?.includes('session')) {
              priceConfig = 'Price per Session';
            }
          }

          //Set Attendance Officers Config
          let attendanceOfficerConfig = null;
          if (!!registConfig) {
            if (registConfig?.includes('event')) {
              attendanceOfficerConfig = 'Once per Event';
            } else if (registConfig?.includes('day')) {
              attendanceOfficerConfig = 'Each Day';
            } else if (registConfig?.includes('session')) {
              attendanceOfficerConfig = 'Each Session';
            }
          }

          newValues = {
            ...values,
            registrations: {
              ...subValues,
              registration_details: [],
              registration_open: null,
              registration_close: null,
              max_registrants: null,
            },
            payment_pricings: {
              ...paymentPricings,
              price_configuration: priceConfig,
              pricing_sub_required: 'false',
              payment_pricing_datas: [],
              payment_pricing_subs: [],
            },
            attendance_officers: {
              ...attendanceOfficers,
              attendance_configuration: attendanceOfficerConfig,
            },
          };
        } else if (Object.keys(item[subKey]).includes('age_min') || Object.keys(item[subKey]).includes('age_max')) {
          const participant = values?.participants;
          const registrations = values?.registrations;
          const hasAge = !!participant?.age_min || !!participant?.age_max;

          newValues = {
            ...values,
            registrations: {
              ...registrations,
              date_of_birth_required: hasAge,
            },
          };
        } else if (Object.keys(item[subKey]).includes('price_configuration')) {
          const paymentPricings = values?.payment_pricings;
          newValues = {
            ...values,
            payment_pricings: {
              ...paymentPricings,
              payment_pricing_datas: [],
              payment_pricing_subs: [],
            },
          };
        } else if (Object.keys(item[subKey]).includes('pricing_sub_required')) {
          const paymentPricings = values?.payment_pricings;
          newValues = {
            ...values,
            payment_pricings: {
              ...paymentPricings,
              payment_pricing_subs: [],
            },
          };
        } else if (Object.keys(item[subKey]).includes('attendance_configuration')) {
          const attendanceOfficers = values?.attendance_officers;
          const attendanceConfig = attendanceOfficers?.attendance_configuration;
          let useQr = null;
          if (attendanceConfig?.toLowerCase()?.includes('none')) {
            useQr = 'false';
          } else {
            useQr = 'true';
          }
          newValues = {
            ...values,
            attendance_officers: {
              ...attendanceOfficers,
              use_qr: useQr,
              officer_datas: [],
            },
          };
        }
        // else if (Object.keys(item[subKey]).includes('use_qr')) {
        //   const attendanceOfficers = values?.attendance_officers;
        //   const useQr = attendanceOfficers?.use_qr;
        //   newValues = {
        //     ...values,
        //     attendance_officers: {
        //       ...attendanceOfficers,
        //       officer_datas: useQr === 'false' ? [] : attendanceOfficers?.officer_datas,
        //     },
        //   };
        // }
        else if (Object.keys(item[subKey]).includes('departments')) {
          const participants = values?.participants;
          newValues = {
            ...values,
            participants: {
              ...participants,
              ministries: null,
            },
          };
        }
      }
    }
    return newValues;
  }

  return (
    <FormPageWrapper
      pageToolbarProps={{
        pageTitle: `${title} Event Data`,
        showDataTypeSelector: false,
        showTagStatus: isCreate || isDuplicate ? false : true,
        // showBack: false,
      }}
      onChangeFormValues={onChangeForm}
      ignoreKeyDuplicate={['status']}
      formInstance={{
        initialValues: {
          company: !!companies?.length ? companies[0] : null,
          participants: {
            public_event: 'true',
          },
          registrations: {
            email_address_required: true,
            // mobile_phone_required: true,
          },
          attendance_officers: {
            use_qr: 'true',
          },
        },
      }}
      autoConfirmAfterAction={isUpdate ? true : false}
    >
      <Form.Item name="company" hidden noStyle />
      <Card>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldValue }) => {
            return <FormTabs setFieldValue={setFieldValue} getFieldValue={getFieldValue} />;
          }}
        </Form.Item>
      </Card>
    </FormPageWrapper>
  );
}
