import { IndexPageWrapper, AntdDataTable, usePageProvider } from '@eigen3m/react-base-frontend';
import { Filter } from '@pages/event/event-data/presentation/components/filter';
import { Card } from 'antd';
import { getAccessRight } from 'shared/helpers/user-category-transformer';

import { columns } from '../helpers';

export default function IndexPage() {
  const { moduleKey } = usePageProvider();
  const accessRight = getAccessRight(moduleKey);
  let actionDisabled: any[] = [];
  const showDetail = accessRight?.view;
  const showUpdate = accessRight?.edit;
  const accessConfirmProcess = accessRight?.confirm_process;
  const accessDelete = accessRight?.delete;
  const accessChangeStatus = accessRight?.change_status;
  const accessPrint = accessRight?.print;

  if (!showDetail) actionDisabled = [...actionDisabled, 'detail'];
  if (!showUpdate) actionDisabled = [...actionDisabled, 'update'];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Event Activity',
        showNewData: false,
        disableConfirmProcess: !accessConfirmProcess,
        disableDelete: !accessDelete,
        disableActivate: !accessChangeStatus,
        disableDeactivate: !accessChangeStatus,
        disablePrint: !accessPrint,
        showPrint: true,
        showBack: true,
      }}
      drawerFilterProp={{
        width: 600,
        formBuilderProps: {
          columns: Filter,
        },
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          rowActionProps={{
            actionIgnored: ['delete', 'duplicate', 'activate', 'deactivate', 'confirm_process', 'detail'],
            actionDisabled: actionDisabled,
          }}
          defaultFilterRequestData={{
            statuses: ['active', 'inactive', 'closed', 'in_process'],
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
